import React from 'react';

import {
    Grid,
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {}

class ErrorMsg extends React.Component {
    render() {
        const { title, message } = this.props;
        return (
            <Grid container className="coulumn-err">
                <strong>{title}</strong>
                <p>{message}</p>
            </Grid>
        );
    }
}

export default withStyles(style)(ErrorMsg);

