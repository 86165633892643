const data = [
    {
        key: '男性', 
        value: '10'
    },
    {
        key: '女性', 
        value: '20'
    },
]

export default data;