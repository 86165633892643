import React from 'react';
import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import Validation from '../../model/common/Validation';
import Message from '../../constants/Message';
import ErrorMsg from '../../component/common/ErrorMsg';
import BackDrop from '../../component/common/BackDrop';

import model from '../../model/common/PasswdReset';
import util from '../../model/common/CustomUtil';

import {
    Grid,
    Container,
    Button,
    Typography,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import { inject } from 'mobx-react';

// override style
const style = {}

@inject('stores')
class PasswordForgot extends React.Component {
    state = {
        errorView: false,
        backDropView: false,
    }

    pwReset = this.props.stores.passwordForgot;

    setFormData = (e) => {
        if (!Validation.empty(e.target.value)) {
            this.props.formData.email = e.target.value;
        }
    }

    hideErrorBox = () => {
        this.setState({ errorView: false });
    }

    showErrorBox = () => {
        this.setState({ errorView: true });
    }

    nextStep = () => {
        const email = this.props.formData.email;
        if (Validation.email(email)) {
            this.setState({ errorView: true });
        } else {
            this.setState({
                backDropView : true
            })

            model.step1(email).then((res) => {
                if (res.data.status === "success") {
                    res.data.email = email;
                    this.pwReset.setData(res.data);
                    const enc = util.encrypt(JSON.stringify(res.data));
                    util.setSessionItem('pwReset', enc);
                    window.location.href = '/passwordForgot2';
                } else {
                    this.showErrorBox();
                    this.setState({
                        backDropView : false
                    })
                }
            });
        }
    }

    render() {
        return (
            <>
                <Grid style={{ position: 'relative' }}>
                    <CommNav />
                    <Grid className="content">
                        <Container>
                            <Grid container direction="row" justifyContent="center" alignItems="center">

                                <Grid item xs={12}>
                                    <center>
                                        <Typography variant="h2" className="content_title">パスワードを忘れた場合</Typography>
                                        <Typography vartiant="p" className="content_desc">
                                            会員情報に登録した、会員ID（メールアドレス）を記入してください。<br />
                                            ご登録のメールアドレス宛に、パスワード再設定に関する情報を送信します。
                                        </Typography>
                                    </center>
                                </Grid>

                                {
                                    this.state.errorView &&
                                    <ErrorMsg message={Message.validation.passwordForgot} />
                                }

                                <Grid item xs={12} className="column-shadow">
                                    <Grid className="form" container direction="row">
                                        <Grid item md={4} className="form_title_cell mb_none">ID(メールアドレス)</Grid>
                                        <Grid item md={8} className="form_input_cell mb_none">
                                            <input className="cellwidth10 post-data" type="email" name="email" placeholder="メールアドレス(半角英数字・記号)" onFocus={this.hideErrorBox} onBlur={this.setFormData} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} >
                                    <center>
                                        <Button className="ybtn" onClick={this.nextStep}>送信</Button>
                                    </center>
                                </Grid>

                            </Grid>
                        </Container>
                    </Grid>
                    <CommFooter />
                </Grid>
                <BackDrop open={this.state.backDropView} />
            </>
        );
    }
}

PasswordForgot.defaultProps = {
    formData: {}
};

export default withStyles(style)(PasswordForgot);
