const data = {
    name: '東京都',
    key: 'tokyo',
    value: 'KAN10',
    area: [
        {
            key: '東京23区',
            value: 'TO000'
        },
        {
            key: '品川区',
            value: 'TO010'
        },
        {
            key: '渋谷区',
            value: 'TO020'
        },
        {
            key: '新宿区',
            value: 'TO030'
        },
        {
            key: '千代田区',
            value: 'TO040'
        },
        {
            key: '中央区',
            value: 'TO050'
        },
        {
            key: '港区',
            value: 'TO060'
        },
        {
            key: '江東区',
            value: 'TO070'
        },
        {
            key: '豊島区',
            value: 'TO080'
        },
        {
            key: '江戸川区',
            value: 'TO090'
        },
        {
            key: 'その他',
            value: 'TO999'
        }
    ]
}

export default data;