import RecruitmentKind from "../../constants/RecruitmentKind";
import RecruitmentType from "../../constants/RecruitmentType";
import WorkHistory from "../../constants/WorkHistory";
import NowWorkKind from "../../constants/NowWorkKind";
import EmpStatus from "../../constants/EmpStatus";
import NeedWorkLocation from "../../constants/NeedWorkLocation";
import Language from "../../constants/Language";
import { school, generationStatus } from "../../constants/Education";
import Hitch from "../../constants/Hitch";
import Gender from "../../constants/Gender";
import UploadFileType from "../../constants/UploadFileType";
import AgendaRecruitmentStatus from "../../constants/AgendaRecruitmentStatus";
import LocationsList from "../../constants/LocationsList";
import CityList from "../../constants/CityList";

import moment from 'moment-timezone';
import lodash from 'lodash';

class CustomUtil {

    crypto = require("crypto-js");

    // axios에 이용할 폼테이터로 변환시켜 반환
    getAxiosFormData = (data) => {
        let formData = new FormData();

        for (const val in data) {
            formData.append(val, data[val]);
        }

        return formData;
    }

    // axios에 이용할 헤더
    getAxiosHeaderData = (type = null) => {
        const header = {
            'content-type': 'multipart/form-data',
        }

        return header;
    }

    // sesstionデータの取得
    getSessionItem = (key) => {
        return sessionStorage.getItem(key);
    }

    // sesstionデータの設定
    setSessionItem = (key, value) => {
        sessionStorage.setItem(key, value);
    }

    // Sessionの特定キーを更新かつ特定キーの値を返却する
    updateSessionItemAndGetValue = (key, dataKey) => {
        const sessionData = JSON.parse(this.decrypt(this.getSessionItem(key)));
        const data = sessionData[dataKey];
        sessionData[dataKey] = '';
        this.setSessionItem(key, this.encrypt(JSON.stringify(sessionData)));

        return data;
    }

    // sesstionデータの削除
    delSessionItem = (key) => {
        sessionStorage.removeItem(key);
    }

    // DBから取得されたHTML Rowデータの返却
    getHtmlContents = (str) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: str }}></div>
        );
    }

    // 暗号化
    encrypt = (text) => {
        return this.crypto.AES.encrypt(text, process.env.REACT_APP_CRYPTO_KEY).toString();
    }

    // 複合化
    decrypt = (text) => {
        return this.crypto.AES.decrypt(text, process.env.REACT_APP_CRYPTO_KEY).toString(this.crypto.enc.Utf8);
    }

    // 特定部分にページをスライドさせる
    scrollTarget = (id) => {
        if (id) {
            document.getElementById(id).scrollIntoView();
        } else {
            window.scrollTo({top: 0});
        }
    }

    getNeedWorkGu = (key) => {
        const data = {
            'TO000': '東京23区',
            'TO010': '品川区',
            'TO020': '渋谷区',
            'TO030': '新宿区',
            'TO040': '千代田区',
            'TO050': '中央区',
            'TO060': '港区',
            'TO070': '江東区',
            'TO080': '豊島区',
            'TO090': '江戸川区',
            'TO999': 'その他',
            'SA000': '埼玉県',
            "SA999": "その他",
            'KA000': '横浜市',
            'KA010': '川崎市',
            "KA999": "その他",
            'CB000': '千葉県',
            "CB999": "その他",
            'FU000': '福岡県',
            "FU999": "その他",
        }

        return key ? data[key] : data;
    }

    // 배열에서 중복된 항목을 제거한다
    unique = (arr) => {
        return arr.filter((v, i, a) => a.indexOf(v) === i);
    }

    // 배열에서 특정 항목을 삭제한다
    deleteSearchTagItem = (arr, target) => {
        return arr.filter((v) => v.value !== target);
    }

    deleteSearchRequirement = (arr, target) => {
        return arr.filter((v) => v !== target);
    }

    // 접속 서버정보
    getApiServerUrl = () => {
        let server = '-99';
        const { hostname, protocol } = window.location;

        if(process.env.REACT_APP_MODE === 'prod'){
            const host = hostname.replaceAll('www.', '');
            server = `${protocol}//api.${host}`;
        }else{
            server = 'http://153.122.62.9:11000';
        }

        return server;
    }

    // ファイルアップロードタイプ
    getFileName = (val) => {
        return this.parsingTextProcess(UploadFileType, val)
    }

    // 性別
    getGender = (val) => {
        return this.parsingTextProcess(Gender, val)
    }

    // こだわり
    getHitch = (val) => {
        return this.explodeDataAndMakeString(Hitch, val);
    }

    // 最終学歴状態
    getGenerationStatus = (val) => {
        return this.parsingTextProcess(generationStatus, val)
    }

    // 最終学歴
    getLastSchool = (val) => {
        return this.parsingTextProcess(school, val)
    }

    // 言語
    getLanguage = (val) => {
        return this.explodeDataAndMakeString(Language, val);
    }

    // 勤務希望地
    getNeedWorkLocation = (val) => {
        return this.explodeDataAndMakeString(NeedWorkLocation, val);
    }

    getNeedWorkLocationCity = (location, station) => {
        const result = [];

        const locationSplit = location.split(',');
        const stationSplit = station.split(',');

        for(const index in locationSplit){
            const locationData = this.getLocation(locationSplit[index]);
            const stationData =  stationSplit[index];

            result.push(
                `${stationData}(${locationData.key})`
            )
        }
        return result.join('、');
    }

    // 雇用形態
    getEmpStatus = (val) => {
        return this.explodeDataAndMakeString(EmpStatus, val);
    }

    // 案件状態
    getAgendaStatus = (val) => {
        return this.parsingTextProcess(RecruitmentKind, val)
    }

    // 募集職種
    getRecruitmentType = (val) => {
        return this.explodeDataAndMakeString(RecruitmentType, val);
    }

    // 経歴
    getWorkHistory = (val) => {
        return this.parsingTextProcess(WorkHistory, val)
    }

    // 今の職業
    getNowWorkKind = (val) => {
        return this.parsingTextProcess(NowWorkKind, val);
    }

    // 案件募集状態
    getAgendaRecruitmentStatus = (val) => {
        return this.parsingTextProcess(AgendaRecruitmentStatus, val);
    }

    // 도시 정보를 반환한다 
    getCity = (location, city) => {
        return CityList[location].find(item => item.value === city);
    }

     // 지역 정보를 반환한다 
     getLocation = (location) => {
        return LocationsList.find(item => item.value === location);
    }

    // 근무지
    getWorkLocations = (location, city, station) => {
        const result = [];

        const locationSplit = location.split(',');
        const citySplit = city.split(',');
        const stationSplit = station.split(',');

        for(const index in locationSplit){
            const locationData = this.getLocation(locationSplit[index]);
            const cityData = this.getCity(locationSplit[index], citySplit[index]);
            const statiosnData = stationSplit[index];

            result.push(
                `${locationData.key}・${cityData.key}・${statiosnData}`
            )
        }
        return result;
    }

    // 유저의 나이를 구한다
    getUserAge = (birthDay) => {
        const nowDate = moment();
        const userBirthDay = moment(birthDay);
        const diff = nowDate.diff(userBirthDay, 'years');
        return diff;
    }

    // 날자를 특정 포멧으로 반환한다
    getDateTime = (date, format) => {
        return moment(date).format(format);
    }

    // オブジェクトから櫃追うなデータを探す
    parsingTextProcess = (reference, val) => {
        const data = reference.find(obj => {
            return obj.value === val
        });

        return data ? data.key : '';
    }

    // DBの文字列分解　→　データ検索　→　文字列返却
    explodeDataAndMakeString = (reference, str) => {
        const data = [];
        str = this.explodeData(',', str);
       
        for(const index in str){
            data.push(this.parsingTextProcess(reference, str[index]))
        }

        return data.join(',');
    }

    explodeData = (seq, data) => {
        return data.split(seq);
    }

    // 신착 안건인지 판단한다 ( 7일 이내는 신착 처리 )
    checkNewArrival = (updateDate)=> {
        try{
            const nowTime = moment();
            const updatedTime = moment(updateDate);
            const diff = nowTime.diff(updatedTime, 'days');
            return diff <= 31
        }catch(err){
            return false;
        }
    }

    // 문자열을 정해진 길이만큼 자른다
    sliceString = (str, length) => {
        return str.length < length ? str : `${str.substr(0, length)}...`;
    }
    
    // 모바일 판정
    checkMobile = () => {
        const expReg = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i;
        return expReg.test(navigator.userAgent);
    }

    // 신착 뱃지 아이콘을 반환한다
    getNewArrivalBadgeIcon = (data) => {
        try{
            const isNewArrival = this.checkNewArrival(data.update_at);
            const badges = [];
            if(isNewArrival){
                badges.push({
                    isNewArrival: isNewArrival, 
                    text: '新着'
                });
            }
            return badges;
        }catch(err){
            return [];
        }
    }

    // 배열의 딥카피
    deepCopy = (obj) => {
        return lodash.cloneDeep(obj);
    }
}

export default new CustomUtil();
