import React from 'react';
import { inject, observer } from 'mobx-react';
import Language from '../../constants/Language';
import { school, grades, generationStatus } from '../../constants/Education';
import Gender from '../../constants/Gender';
import NeedWorkLocation from '../../constants/NeedWorkLocation';
import NowWorkKind from '../../constants/NowWorkKind';
import WorkHistory from '../../constants/WorkHistory';

import { withStyles } from '@mui/styles';

import Message from '../../constants/Message';
import {
    Grid,
    Typography,
    FormControlLabel,
    RadioGroup,
    Radio,
    Checkbox,
} from '@mui/material';
import TextBox from '../common/TextBox';
import Validation from '../../model/common/Validation';
import CustomUtil from '../../model/common/CustomUtil';

// override style
const style = {
    root: {
        '& .w30': {
            width: '30%'
        },
        '& .w45': {
            width: '49%'
        },
        '& .w100': {
            width: '100%'
        }
    }
};

@inject('stores')
@observer
class FormDetlUserInfo extends React.Component {
    constructor(props){
        super(props);
        this.stores = props.stores;
    }

    renderValidationError = (key, ...args) => {
        let validation = false;
        const msg = Message.userRegist[key];

        if (Validation.empty(args)) {
            validation = this.stores.validation.getData(key) || false;
        } else {
            const check1 = this.stores.validation.getData(args[0]);
            const check2 = this.stores.validation.getData(args[1]);

            validation = check1 || check2;
        }

        return (
            <>
                {
                    validation &&
                    <Grid container>
                        <Typography color={'red'}>{msg}</Typography>
                    </Grid>
                }
            </>
        )
    }

    renderInputComponent = (key, type, label, className) => {
        const value = this.stores.form.getData(key) || '';
        const validation = this.stores.validation.getData(key)

        const config = {
            type: type,
            error: validation,
            id: key,
            name: key,
            label: label,
            defaultValue: value,
            onChange: this.handleDataProcess,
        }

        return (
            <TextBox {...config} className={className} />
        )
    }

    renderSelectComponent = (key, listData, className) => {
        const value = this.stores.form.getData(key) || '';
        const validation = this.stores.validation.getData(key)

        const config = {
            type: 'select',
            value: value,
            listData: listData,
            id: key,
            name: key,
            error: validation,
            onChange: this.handleDataProcess,
        }

        return (
            <TextBox {...config} className={className} />
        )
    }

    handleDataProcess = (e) => {
        const type = e.target.type;
        const key = e.target.name;
        let value = '';

        if(type === 'radio'){
            value = e.target.defaultValue;
        }else if(type === 'checkbox'){
            value = CustomUtil.deepCopy(this.stores.form.getData(key));
            if(e.target.checked){
                value.push(e.target.defaultValue);
            }else{
                value = value.filter(item => item !== e.target.defaultValue)
            }
        }else{
            value = e.target.value;
        }

        this.stores.form.setData(key, value)
    }

    renderRequireMsg = () => {
        return (
            <Typography variant="span" className="mandatory">{'*必須'}</Typography>
        )
    }

    renderGenderRadio = () => {
        const value = this.stores.form.getData('gender');
        return (
            <RadioGroup row aria-label="gender" name="gender" defaultValue="top" value={value} >
                {
                    Gender.map((item, index) => (
                        <FormControlLabel value={item.value} control={<Radio color="primary" onClick={this.handleDataProcess} />} label={item.key} key={index} />
                    ))
                }
            </RadioGroup>
        )
    }

    renderCheckBoxComponent = (key, listData) => {
        const language = this.stores.form.getData(key);
        return listData.map((item) => {
            const checked = language.indexOf(item.value.toString()) > -1 ? true : false;
            return (
                <Grid item md={4} key={item.key}>
                    <FormControlLabel
                        key={item.key}
                        value={item.value}
                        id={key}
                        name={key}
                        control={<Checkbox color="primary" onChange={this.handleDataProcess} checked={checked}/>}
                        label={item.key}
                        labelPlacement="end"
                    />
                </Grid>
            )
        })
    }

    render() {
        const { classes } = this.props;
        const validation = this.stores.validation.getData();
        return (
            <Grid item classes={classes}>
                <Grid item>
                    <Typography variant="h2" className="content_title mt_80">{'プロフィール作成'}</Typography>
                    <Typography vartiant="p" className="content_desc">
                        {'下記項目を合わせてご入力いただくと、ご案内がスムーズになります。'}
                    </Typography>
                </Grid>
                <Grid item className="column-shadow">
                    <Grid container direction="row" className="form">
                        <Grid item md={4} className="form_title_cell">
                            {'性別'} {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell radio">
                            {this.renderGenderRadio()}
                            {this.renderValidationError('gender')}
                        </Grid>

                        <Grid item md={4} className="form_title_cell">
                            {'生年月日'} {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell radio">
                            {this.renderInputComponent('birthday', 'date', '', 'w100')}
                            {this.renderValidationError('birthday')}
                        </Grid>

                        <Grid item md={4} className="form_title_cell">
                            {'電話番号'} {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell radio">
                            {this.renderInputComponent('phone', 'tel', '08011112222', 'w100')}
                            {validation.phoneNotmatch ? this.renderValidationError('phoneNotmatch') : this.renderValidationError('phone')}
                        </Grid>

                        <Grid item md={4} className="form_title_cell ai_top">
                            {'最終学歴'}
                            {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell">
                            {this.renderSelectComponent('last_education', school, 'w30')}
                            {this.renderSelectComponent('last_education_grade', grades, 'w30')}
                            {this.renderSelectComponent('last_education_status', generationStatus, 'w30')}
                            {this.renderValidationError('educationFail')}
                        </Grid>

                        <Grid item md={4} className="form_title_cell">
                            {'学校名'} {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell radio">
                            {this.renderInputComponent('last_education_name', 'text', '学校名', 'w100')}
                            {this.renderValidationError('last_education_name')}
                        </Grid>
                        
                        <Grid item md={4} className="form_title_cell">
                            {'国籍'} {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell radio">
                            {this.renderInputComponent('nationality', 'text', '国籍', 'w100')}
                            {this.renderValidationError('nationality')}
                        </Grid>

                        <Grid item md={4} className="form_title_cell">
                            {'住所'} {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell radio">
                            {this.renderInputComponent('now_address', 'text', '住所', 'w100')}
                            {this.renderValidationError('now_address')}
                        </Grid>

                        <Grid item md={4} className="form_title_cell">
                            {'最寄駅'} {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell radio">
                            {this.renderInputComponent('near_station', 'text', '最寄駅', 'w100')}
                            {this.renderValidationError('near_station')}
                        </Grid>

                        <Grid item md={4} className="form_title_cell">
                            {'勤務希望地'} {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell radio">
                            {this.renderSelectComponent('need_work_location', NeedWorkLocation, 'w100')}
                            {this.renderValidationError('need_work_location')}
                        </Grid>

                        <Grid item md={4} className="form_title_cell">
                            {'今の雇用形態'} {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell radio">
                            {this.renderSelectComponent('now_work_kind', NowWorkKind, 'w100')}
                            {this.renderValidationError('now_work_kind')}
                        </Grid>

                        <Grid item md={4} className="form_title_cell">
                            {'IT経歴年数'} {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell radio">
                            {this.renderSelectComponent('work_history', WorkHistory, 'w100')}
                            {this.renderValidationError('work_history')}
                        </Grid>

                        <Grid item md={4} className="form_title_cell">
                            {'開発言語'} {this.renderRequireMsg()}
                        </Grid>
                        <Grid item md={8} className="form_input_cell radio">
                            {this.renderCheckBoxComponent('use_language', Language)}
                            {this.renderValidationError('use_language')}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(style)(FormDetlUserInfo);
