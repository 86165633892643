const data = {
    // 東京
    'KAN10': [
        {
            value: 'TO000',
            key: '東京23区',
        },
        {
            value: 'TO010',
            key: '品川区',
        },
        {
            value: 'TO020',
            key: '渋谷区',
        },
        {
            value: 'TO030',
            key: '新宿区',
        },
        {
            value: 'TO040',
            key: '千代田区',
        },
        {
            value: 'TO050',
            key: '中央区',
        },
        {
            value: 'TO060',
            key: '港区',
        },
        {
            value: 'TO070',
            key: '江東区',
        },
        {
            value: 'TO080',
            key: '豊島区',
        },
        {
            value: 'TO090',
            key: '江戸川区',
        },
    ],
    // 神奈川
    'KAN20': [
        {
            value: 'KA000',
            key: '横浜市',
        },
        {
            value: 'KA010',
            key: '川崎市',
        },
    ],
    // 埼玉
    'KAN30': [
        {
            value: 'SA000',
            key: '埼玉市',
        },
    ],
    // 千葉
    'KAN40': [
        {
            value: 'CB000',
            key: '千葉市',
        },
    ],
    // 福岡
    'KYU10': [
        {
            value: 'FU000',
            key: '福岡市',
        },
    ]
}

export default data;