import axios from "axios"
import moment from 'moment'
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";

class UserList {
    getList = async (page, searchData) => {
        const baseData = {
            mode : 'manager',
            nowPage : page
        }

        const data = Object.assign(baseData, searchData);
        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.getUserList, params, headers);
    }
    
    dataProcess = (obj) => {
        const nowDate = moment();
        for (const [index, value] of Object.entries(obj)) {
            const phone = value.phone.includes('-') ? value.phone : value.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
            const targetDate = moment(value.birthday);
            const yearDiff = nowDate.diff(targetDate, 'years');

            obj[index].gender = CustomUtil.getGender(value.gender);
            obj[index].work_history = CustomUtil.getWorkHistory(value.work_history);
            obj[index].age = yearDiff
            obj[index].use_language = CustomUtil.getLanguage(value.use_language);
            obj[index].phone = phone;
            obj[index].join_at = moment(obj[index].join_at).format('YYYY/MM/DD');
        }

        return obj;
    }
}

export default new UserList();