import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";

class AgendaRegister {
    submit = (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();
        return axios.post(ApiUrl.itemCreate, params, headers)
    }

    getAssignList = () => {
        const params = CustomUtil.getAxiosFormData({});
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.assignList, params, headers).then(res => {
            const list = []
            for(const item of res.data){
                list.push({
                    key : item.name, 
                    value : item.idx
                })
            }

            return list;
        })
    }
}

export default new AgendaRegister();