import React from 'react';
import { inject, observer } from 'mobx-react';
import model from '../../model/user/UserFaq';

import {
    Grid,
    Typography,
    Pagination,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    Paper,
    TableBody,
    TableCell,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import Validation from '../../model/common/Validation';
import CustomModal from '../../component/common/CustomModal';
import CommNav from '../../component/common/CommNav';
import CommFooter from '../../component/common/CommFooter';
import UserFaqReplyForm from './UserFaqReplyForm';
import UserFaqSearchBox from '../../component/manager/UserFaqSearchBox';

// override style
const style = {
    root : {
        '& .contents-frame': {
            padding: '20px 0',
            '& .list-table' : {
                borderRadius: '4px !important',
                width: '90%',
                '& td': {
                    padding: 0
                },
                '& .contents': {
                    overflow: 'hidden',
                    width: '70vw',
                    textOverflow: 'ellipsis'
                }, 
                '& .item-rows': {
                    cursor: 'pointer'
                },
                '& .item-rows:hover': {
                    backgroundColor: 'rgba(0, 22, 255, 0.3)'
                }
            }
        },
    }
}

@inject('stores')
@observer
class UserFaqList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            modalView: false,
            currentPage : 1
        }

        this.stores = props.stores;
    }

    componentDidMount = () => {
        this.handleGetListData(1)
    }

    handleGetListData = async(pageNum) => {
        const searchData = this.stores.search.getAllData();

        const params = {
            nowPage: pageNum,
            mode: 'user'
        }

        if(!Validation.empty(searchData.email)){
            params.email = searchData.email;
        }

        if(!Validation.empty(searchData.name)){
            params.name = searchData.name;
        }

        const result = await model.getList(params);

        this.setState({
            data: result
        })
    }

    handleShowReplyForm = (faqIdx) => {
        this.stores.form.setData('faqIdx', faqIdx);
        this.setState({
            modalView: true
        })
    }

    handleCloseModal = () => {
        this.setState({
            modalView: false
        })
    }

    handleMovePage = (e, pageNum) => {
        this.setState({
            currentPage : pageNum
        })

        this.handleGetListData(pageNum);
    }

    render() {
        const { data, currentPage, modalView } = this.state;
        const { classes } = this.props;
        if(!data){
            return <></>
        }

        return (
            <Grid classes={classes}>
                <CommNav />
                <Grid className="content">
                    <Grid container justifyContent="center">
                        <Typography variant='h5'>{'ユーザーお問い合わせ一覧'}</Typography>
                    </Grid>

                    <UserFaqSearchBox handleGetListData={this.handleGetListData}/>

                    <Grid container justifyContent="center" className={'contents-frame'}>
                        <TableContainer component={Paper} className={'list-table'}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>{'No.'}</TableCell>
                                        <TableCell align='center'>{'お問い合わせ内容'}</TableCell>
                                        <TableCell align='center'>{'質問日'}</TableCell>
                                        <TableCell align='center'>{'回答'}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.list.map(item => {
                                            const status = item.reply_date ? '完' : '';
                                            return (
                                                <TableRow key={item.idx} className={'item-rows'} onClick={() => this.handleShowReplyForm(item.idx)}>
                                                    <TableCell align='center'>{item.idx}</TableCell>
                                                    <TableCell><p className={'contents'}>{item.contents}</p></TableCell>
                                                    <TableCell align='center'>{item.write_date}</TableCell>
                                                    <TableCell align='center'>{status}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Pagination page={currentPage} count={data.paging.maxPage} variant="outlined" color="primary" onChange={this.handleMovePage} />
                    </Grid>
                </Grid>
                <CommFooter />
                <CustomModal maxWidth={'md'} open={modalView} onClose={this.handleCloseModal} Component={UserFaqReplyForm} />
            </Grid>
        );
    }
}

export default withStyles(style)(UserFaqList);
