import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";

class UserFaq {
    getList = async (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        const res = await axios.post(ApiUrl.faqList, params, headers).then(res => {
            return res.data
        });

        return this.dataProcess(res);
    }

    getDetlData = async (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.faqDetl, params, headers).then(res => {
            return res.data
        });
    }

    add = async (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.faqAdd, params, headers).then(res => {
            return res.data
        });
    }

    delete = async (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.faqDelete, params, headers).then(res => {
            return res.data
        });
    }

    update = async (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.faqUpdate, params, headers).then(res => {
            return res.data
        });
    }

    dataProcess = (obj) => {
        for (const index in obj.list) {
            obj.list[index].reply = obj.list[index].reply ? '〇' : ''
            obj.list[index].write_date = CustomUtil.getDateTime(obj.list[index].write_date, 'YYYY/MM/DD');
        }
        return obj;
    }
}

export default new UserFaq();