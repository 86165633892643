const data = {
    selectedTab: '',
    keyword: '',
    tags: {
        key: [],
        value: [],
    },
    location: {
        area: [],
        city: []
    },
    language: [],
    position: [],
    dataList: [],
    empStatus: [],
    stations: [],
    cityList: [],
    stationList:[],
}

export default data;