import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';

// override style
const style = {}

class DataTable extends React.Component {
    renderAgendaListRow = (data, kyes) => {
        const { showJoinList, showModalFnc } = this.props;
        const renderTableCel = (data, key) => {
            if (key === 'join_list') {
                data[key] = <Button variant={'outlined'} onClick={() => { showJoinList(data) }}>{'応募者'}</Button>
                return (
                    <TableCell key={key} align="center">{data[key]}</TableCell>
                )
            } else {
                return (
                    <TableCell key={key} align="center" onClick={() => { showModalFnc(data) }} >{data[key] || ''}</TableCell>
                )
            }
        }
        return (
            <>
                {
                    kyes.map((keyItem) => (
                        renderTableCel(data, keyItem)
                    ))
                }
            </>
        )
    }

    renderDefaultRow = (data, kyes) => {
        const { showModalFnc } = this.props;
        return (
            <>
                {
                    kyes.map((kye) => (
                        <TableCell key={kye} align="center" onClick={() => { showModalFnc(data) }}>{data[kye] || ''}</TableCell>
                    ))
                }
            </>
        )
    }

    render() {
        const { data, header, keys, viewMode } = this.props;
        return (
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                header.map((header) => (
                                    <TableCell key={header} align="center">{header}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.list.map((item) => (
                                <TableRow hover key={item.idx} style={item.style}>
                                    {
                                        viewMode === 'agendaList' &&
                                        this.renderAgendaListRow(item, keys)
                                    }

                                    {
                                        viewMode === undefined &&
                                        this.renderDefaultRow(item, keys)
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default withStyles(style)(DataTable);