import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "./CustomUtil";

class PasswdReset {
    step1 = async (email) => {
        const data = {
            email: email
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.pwReset1, params, headers);
    }

    step2 = async ({email, token}) => {
        const data = {
            email: email,
            token: token
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.pwReset2, params, headers);
    }

    step3 = async ({email, token, pw, pw_confirm}) => {
        const data = {
            email: email,
            checkKey: token, 
            pw: pw, 
            pw_confirm: pw_confirm
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.pwReset3, params, headers);
    }
}

export default new PasswdReset();