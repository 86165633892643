import React from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    Button,
    Grid,
} from '@mui/material';
import { withStyles } from "@mui/styles";

// override style
const styles = {
    paper: {
        minWidth: '90%',
        minHeight: '90%'
    },
    root : {
    },
};

class CustomModal extends React.Component {
    render() {
        const { message, onClose, open, Component, maxWidth, nextPageFnc, pageView, classes, confirm, getListFnc, handleKeywordChange, handleReset } = this.props;
        const paperStyle = pageView ? classes : {} ;
        return (
            <div classes={classes} >
                <Dialog
                    classes={paperStyle}
                    maxWidth={maxWidth}
                    fullWidth={true}
                    style={{ whiteSpace: "pre-wrap" }}
                    scroll={'paper'}
                    onClose={onClose}
                    aria-labelledby="simple-dialog-title"
                    open={open}
                >
                    {
                        message &&
                        <DialogTitle id="simple-dialog-title">{message}</DialogTitle>
                    }
                    {
                        Component &&
                        <Component 
                            onClose={onClose} 
                            nextPageFnc={nextPageFnc} 
                            getListFnc={getListFnc} 
                            handleKeywordChange={handleKeywordChange}
                            handleReset={handleReset}
                        />
                    }
                    {
                        confirm &&
                        <>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {confirm.title}
                                </DialogContentText>
                            </DialogContent>  
                            <DialogActions>
                                <Grid container justifyContent={'center'}>
                                {
                                    confirm.ok &&
                                    <Button variant={'outlined'} onClick={confirm.ok.fnc} color="primary">{confirm.ok.msg}</Button>
                                }

                                {
                                    confirm.cancel &&
                                    <Button variant={'outlined'} onClick={confirm.cancel.fnc} color="primary" autoFocus>{confirm.cancel.msg}</Button>
                                }
                                </Grid>
                            </DialogActions>
                        </>
                    }
                </Dialog>
            </div>
        )
    }
}

export default withStyles(styles)(CustomModal);