import CustomUtil from '../model/common/CustomUtil';

// サーバAPIアドレス
const server = CustomUtil.getApiServerUrl();

const data = {
    'login': server + '/user/login',    // ログイン
    'logout': server + '/user/logout',  // ログアウト
    'checkAuthToken': server + '/user/checkAuthToken',  // 認証Tokenの確認
    'itemList': server + '/search/getAgendaList',   // 案件リスト
    'itemDetl': server + '/search/detl',    // 案件詳細
    'applicantList': server + '/search/applicantList',    // 応募者一覧
    'getNearestStation': server + '/search/getNearestStation', // 最寄駅
    'logincheck': server + '/user/logincheck',  // ログインチェック
    'agendaHit': server + '/agenda/agendaHit',  // 案件Hit
    'itemCreate': server + '/agenda/insert',    // 管理者案件登録
    'assignList' : server + '/agenda/assignList', // 管理者アサインリスト
    'itemModify': server + '/agenda/update',   // 管理者案件修正
    'itemDelete': server + '/agenda/delete',   // 管理者案件削除
    'itemRestore': server + '/agenda/restore', // 管理者案件復旧
    'userMailSend': server + '/agenda/sendmembermail', // 管理者会員メール送信
    'getUserList': server + '/search/getUserList', // 管理者会員リスト
    'setGrant': server + '/user/toggleAdminGrant', // 管理者会員 管理権限設定
    'regist': server + '/user/regist',  // 会員加入
    'modify': server + '/user/modify', // 会員修正
    'exitMember': server + '/user/delete',  // 会員退会
    'userVan': server + '/user/adminVan',  // 管理者会員停止
    'restore': server + '/user/restore',    // 会員復帰
    'pwReset1': server + '/user/pwreset1',    // 会員パスワードリセット Step 1
    'pwReset2': server + '/user/pwreset2',    // 会員パスワードリセット Step 2
    'pwReset3': server + '/user/pwreset3',    // 会員パスワードリセット Step 3
    'faqList': server + '/user/faqList',    // Mypageお問い合わせ select
    'faqDetl': server + '/user/faqDetl',    // Mypageお問い合わせ select detl
    'faqAdd': server + '/user/faqAdd',    // Mypageお問い合わせ insert
    'faqUpdate': server + '/user/faqUpdate',    // Mypageお問い合わせ update
    'faqDelete': server + '/user/delete',    // Mypageお問い合わせ delete
    'faqAddComment': server + '/user/faqAddComment',    // Mypageお問い合わせ 관리자 답변
    'inquery': server + '/user/inquery',    // お問い合わせ
    'detl': server + '/user/detl',  // 会員詳細
    'joinagenda': server + '/user/joinagenda',  // 会員案件応募
    'entryList': server + '/search/userEntryList',  // 会員エントリーリスト, 
    'fileDown': server + '/file/download',  //file down
    'fileDelete': server + '/file/delete',  //file delete
    'changeRecruitmentType': {
        10: server + '/agenda/agendaResume',  // 案件募集中
        20: server + '/agenda/agendaRecruitmentCompleted',  // 案件充足
        30: server + '/agenda/agendaPause',  // 案件募集保留
    },
    'addEvaluationData' : server + '/user/addEvaluationData',  // ユーザー評価入力
    'delEvaluationData' : server + '/user/delEvaluationData',  // ユーザー評価削除
    'getEvaluationData' : server + '/search/evaluationDataList',  // ユーザー評価取得
    'applicantConfirmProcess' : server + '/agenda/applicantConfirmProcess',  // 案件支援者の確定
    'joinedApplicant' : server + '/search/getJoinedAgendaList',  // 応募した案件のList
}

export default data;