import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";
import Validation from "../common/Validation";

class Search {
    getList = async (page, searchData) => {
        const data = {
            mode: 'user',
            nowPage: page || 1,
        }
    
        if(!Validation.empty(searchData.keyword)){
            data.keyword = searchData.keyword;
        }

        if(!Validation.empty(searchData.location.area)){
            data.location = this.mappingData(searchData.location.area);
        }

        if(!Validation.empty(searchData.location.city)){
            data.city = this.mappingData(searchData.location.city);
        }

        if(!Validation.empty(searchData.empStatus)){
            data.empStatus = this.mappingData(searchData.empStatus);
        }

        if(!Validation.empty(searchData.position)){
            data.recruitmentType = this.mappingData(searchData.position);
        }

        if(!Validation.empty(searchData.language)){
            data.language = this.mappingData(searchData.language);
        }

        if(!Validation.empty(searchData.stations)){
            data.nearest_station = this.mappingData(searchData.stations);
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.itemList, params, headers);
    }

    getStationList = async (formData) => {

        if(Validation.empty(formData)){
            return null;
        }

        const data = {
            areas: [...formData].join(',')
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.getNearestStation, params, headers).then(res => {
            return res.data
        });
    }

    getJoinedAgendas = (email) => {
        const data = {
            email : email
        }
        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();
        return axios.post(ApiUrl.joinedApplicant, params, headers).then(res => res.data);
    }

    mappingData = (item) => {
        const result = [];
        for(const val of item){
            result.push(val)
        }

        return result;
    }

    dataProcess = (obj) => {
        for (const index in obj) {
            obj[index].language = CustomUtil.getLanguage(obj[index].language);
            obj[index].recruitment_type = CustomUtil.getRecruitmentType(obj[index].recruitment_type);
            obj[index].emp_status = CustomUtil.getEmpStatus(obj[index].emp_status);
            obj[index].location = CustomUtil.getNeedWorkLocationCity(obj[index].location, obj[index].nearest_station);
            obj[index].city = CustomUtil.getNeedWorkGu(obj[index].city)
            obj[index].hitch = CustomUtil.getHitch(obj[index].hitch);
        }
        
        return obj;
    }
}

export default new Search();