import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";

class AgendaDetl {
    getDetlData = async (agendaIdx) => {
        const data = {
            mode : 'manager',
            agenda_idx : agendaIdx
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        const result = await axios.post(ApiUrl.itemDetl, params, headers).then(res => { return res.data});
        return this.dataProcess(result);
    }

    getApplicantData = (formData) => {
        const data = {
            agenda_idx: formData.agendaIdx, 
            pageNum: formData.pageNum
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.applicantList, params, headers).then(res => {
            return this.applicantDataProcess(res.data);
        })
    }

    confirmProcess = (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.applicantConfirmProcess, params, headers);
    }

    deleteData = async (Idx) => {
        const data = {
            mode : 'manager',
            idx : Idx
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.itemDelete, params, headers);
    }

    restoreData = async (Idx) => {
        const data = {
            mode : 'manager',
            idx : Idx
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.itemRestore, params, headers);
    }

    changeRecruitmentType = (formData) => {
        const apiUrl = ApiUrl.changeRecruitmentType[formData.recruitmentType];
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();
        return axios.post(apiUrl, params, headers);
    }

    dataProcess = (obj) => {
        obj.recruitment_type = CustomUtil.getRecruitmentType(obj.recruitment_type);
        obj.emp_status = CustomUtil.getEmpStatus(obj.emp_status);
        obj.language = CustomUtil.getLanguage(obj.language);
        obj.hitch = CustomUtil.getHitch(obj.hitch);
        obj.statusKey = obj.status
        obj.status = CustomUtil.getAgendaRecruitmentStatus(obj.status)
        obj.workLocations = CustomUtil.getWorkLocations(obj.location, obj.city, obj.nearest_station)
        obj.job_start_date = CustomUtil.getDateTime(obj.job_start_date, 'YYYY/MM/DD')
        return obj;
    }

    applicantDataProcess = (obj) => {
        for(const index in obj.list){
            obj.list[index].join_at = CustomUtil.getDateTime(obj.list[index].join_at, 'YYYY/MM/DD');
            obj.list[index].birthday = CustomUtil.getUserAge(obj.list[index].birthday);
            obj.list[index].work_history = CustomUtil.getWorkHistory(obj.list[index].work_history);
            obj.list[index].gender = CustomUtil.getGender(obj.list[index].gender);
        }

        return obj;
    }
}

export default new AgendaDetl();