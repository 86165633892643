import React from 'react';
import { inject, observer } from 'mobx-react';
import model from '../../model/user/UserFaq';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import {
    Grid,
    Typography,
    Button,
    TextField,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import Validation from '../../model/common/Validation';

// override style
const style = {
    root: {
        padding: '1rem',
        '& .reply-icon': {
            fontSize: '45px',
            transform: 'scaleX(-1)',
            margin: '10px'
        },
        '& .input-box-frame': {
            marginTop: '10px',
            marginBottom: '10px',
            '& .input-box': {
                resize: 'none',
                width: '100%',
                height: '310px',
            },
            '& .admin-message-box': {
                resize: 'none',
                width: '90%',
                height: '300px'
            },
        },
        '& .delt-box-frame': {
            '& .contents': {
                padding: '1rem',
                margin: '10px 0',
                border: '1px solid rgba(128, 128, 128, 0.4)',
                overflowWrap: 'anywhere',
                maxHeight: '250px',
                overflow: 'auto'
            }
        },
        '& .button-area': {
            '& button': {
                marginLeft: '5px'
            }
        },
        '@media(max-width: 900px)': {
            '& .input-box-frame': {
                '& .admin-message-box': {
                    width: '85% !important',
                },
            },
        }
    }
}

@inject('stores')
@observer
class UserFaqReplyForm extends React.Component {
    constructor(props) {
        super(props);
        this.stores = props.stores;
        this.reply = '';
        this.state = {
            data : null
        }
    }

    componentDidMount = async() => {
        const faqIdx = this.stores.form.getData('faqIdx');
        const result = await model.getDetlData({
            idx : faqIdx
        });

        this.setState({
            data : result
        });

        this.reply = result.reply
    }

    handleSetFormData = (e) => {
        this.reply = e.target.value
    }

    handleReply = () => {
        const loginUser = this.stores.login.getData();
        const faqIdx = this.stores.form.getData('faqIdx');
        if (Validation.empty(this.reply)) {
            alert('回答を作成してください');
            return;
        }

        const params = {
            adminIdx: loginUser.idx,
            faqIdx: faqIdx,
            reply: this.reply,
            mode: 'admin'
        }

        model.update(params).then(res => {
            alert(res.message)
            window.location.reload(true)
        })
    }

    render() {
        const { classes, onClose } = this.props;
        const { data } = this.state;
        if(!data){
            return <></>
        }

        return (
            <Grid classes={classes}>
                <Grid container justifyContent={'center'}>
                    <Typography variant='h5'>{'お問い合わせの回答'}</Typography>
                </Grid>

                <Grid container>
                <Typography variant='h7'>{`作成者：`}</Typography>
                    <a href={`mailto:${data.email}`} target="_top">
                        <Typography variant='h7'>{`${data.name}(${data.name_kana})`}</Typography>
                    </a>
                </Grid>

                <Grid container justifyContent={'center'} className={'delt-box-frame'}>
                    <Grid item md={12} xs={12} className={'contents'}>{data.contents}</Grid>
                </Grid>
                <Grid container justifyContent={'right'} className={'input-box-frame'}>
                    <KeyboardReturnIcon className={'reply-icon'} />
                    <Grid item md={11}>
                        <TextField
                            fullWidth
                            multiline
                            rows={12}
                            className={'input-box'}
                            onInput={this.handleSetFormData}
                            placeholder={'対応内容を入力してください。'}
                            defaultValue={data.reply || ''}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent={'right'} className={'button-area'}>
                    <Button variant='outlined' onClick={onClose}>{'キャンセル'}</Button>
                    <Button variant='outlined' onClick={this.handleReply}>{'回答'}</Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(UserFaqReplyForm);
