import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
    Grid,
    Container,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { withStyles } from '@mui/styles';
import { inject } from 'mobx-react';

const style = {
    root:{

    }

}

@inject('stores')
class FAQList extends React.Component {
    constructor(props) {
        super(props);
        this.stores = props.stores;
        this.state = {
            tabs: this.handleInitTabPanelStatus()
        }
    }

    componentDidMount = () => {
        const { params } = this.props;
        const { tabs } = this.state;
        let index = null;

        if (params.tabkey === 'passwd') {
            index = 5;
        }

        if (params.tabkey === 'van') {
            index = 7;
        }

        if (index) {
            tabs[index] = true;
            this.setState({
                tabs: tabs
            })
        }
    }

    handleInitTabPanelStatus = () => {
        const data = Array.from({ length: 17 }, () => false);
        return data;
    }

    handleChangeState = (index) => {
        const { tabs } = this.state;
        tabs[index] = !tabs[index];
        this.setState({
            tabs: tabs
        });
    }

    render() {
        const server = this.stores.server.getData();
        const { tabs } = this.state;
        return (
            <>
                <Container xs={12}>
                    <Typography className="faq_title">サービス説明</Typography>
                    <Grid item xs={12}>
                        <Accordion expanded={tabs[0]} onClick={() => { this.handleChangeState(0) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">MINONAVI（ミノナビ）とは？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    MINONAVI（ミノナビ）とは、株式会社MINOSYSが運営するITエンジニア専用の案件・求人情報サイトです。<br />
                                    ご応募から案件の紹介、専任担当によるサポートまですべて無料でご利用いただけます。<br />
                                    フリーランスから正社員までご希望の働き方が選べられます。<br />
                                    案件情報のご提案・ご相談をご希望の方は、「<Link to="/regist">無料の新規会員登録</Link>」からお進みください。<br />
                                    応募者とクライアントの間に立ち、双方のマッチングをサポートします。<br />
                                    ご登録頂いた方の技術やご要望に合わせて最適な案件を提案致します。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Container>

                <Container xs={12}>
                    <Typography className="faq_title">全般的な利用方法・会員登録について</Typography>
                    <Grid item xs={12}>
                        <Accordion expanded={tabs[1]} onClick={() => { this.handleChangeState(1) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">サービス利用料金はかかりますか？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    利用料金は一切かかりません。全て無料です。<br />
                                    案件情報の閲覧、会員登録、カウンセリング、商談、参画、その後のフォローまで全て無料でご利用いただけます。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[2]} onClick={() => { this.handleChangeState(2) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">会員登録とは？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    株式会社MINOSYSが提供する求人紹介サイトへ申し込みをすることです。<br />
                                    登録ユーザー様限定の求人案件も多数用意しております。<br />
                                    案件情報のご提案・ご相談をご希望の方は、「<Link to="/regist">無料の新規会員登録</Link>」からお進みください。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[3]} onClick={() => { this.handleChangeState(3) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">登録情報の変更はできますか？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    ログイン後、マイページの「<Link to="/mypage">会員情報修正</Link>」メニューから各情報の変更ができます。<br />
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[4]} onClick={() => { this.handleChangeState(4) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">現在就業中でも登録してもいいですか？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    大丈夫です。 退職予定日が決定していればそれを参考に案件を調整致します。<br />
                                    退職予定日が決定していない方も、まずは登録いただきご状況をお聞かせください。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[5]} onClick={() => { this.handleChangeState(5) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">ID、パスワードを入力してもログインできない？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    ①ID、パスワードをコピーペーストした際、前後にスペースが入ってしまうことが原因<br />
                                    スペースが入らないようにコピーペーストするか、ウインドウに手打ち（直接入力）すると解決できます。<br />
                                    ②CapsLockがOnになっていることが原因<br />
                                    意図せずに常に大文字を入力する設定になってしまっていることがあります。<br />
                                    ③インターネットブラウザのCookieという機能の影響<br />
                                    当サイトでは、会員登録時と、専用ページにログインする際に、Cookieという技術を利用しています。<br />
                                    お使いのブラウザがCookieを利用しない設定になっていたり、 Cookieを受け付けない選択をされた場合には、<br />
                                    ログインが正しくできなかったり、ページが正しく表示されない場合があります。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[6]} onClick={() => { this.handleChangeState(6) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">退会方法は？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    MINONAVIからの退会（個人情報の抹消）をご希望でしたら、ログイン後、
                                    マイページの「<Link to="/mypage">会員情報修正</Link>」メニューから退会の手続きを行っていただきますようお願いいたします。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[7]} onClick={() => { this.handleChangeState(7) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">会員資格一時停止・取り消しの条件は？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    以下の場合、会員資格の一時停止・取り消しを予告なく行うことがあります。<br />
                                    <br />
                                    ・会員登録時に虚偽の申告をした<br />
                                    ・IDやパスワードを不正使用した<br />
                                    ・自らのIDやパスワードをほかの会員など第三者に使用させた<br />
                                    ・いかなる手段を用いてもまったく連絡が取れなくなった<br />
                                    ・当社の運営を悪意を持って妨害した<br />
                                    ・その他、会員として不適当であると当社が判断する行為をした<br />
                                    ・ご本人から退会希望のご連絡があった<br />
                                    <br />

                                    ※会員資格の一時停止・取り消しによりお客様に生じた不利益について当社は責任を負わないものとします。<br />
                                    ※会員資格一時停止の解除につきましては、「会員資格一時停止・取り消しの条件」を解決したと判断した場合、<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;当社からご本人様に直接メールでご案内致します。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[8]} onClick={() => { this.handleChangeState(8) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">メールサービスの停止方法は？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    各地域ごとの「注目求人紹介」など「メールマガジンの配信サービス停止」をご希望される方は、
                                    「<Link to="/inquiry">お問い合わせ</Link>」よりご連絡ください。追って、弊社担当者よりご連絡を差し上げます。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Container>

                <Container xs={12}>
                    <Typography className="faq_title">案件と支払いについて</Typography>
                    <Grid item xs={12}>
                        <Accordion expanded={tabs[9]} onClick={() => { this.handleChangeState(9) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">案件単価とは？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    ひと月でお渡しできる目安の報酬額を指します。<br />
                                    ご経験によって前後いたしますこと、ご了承ください。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[10]} onClick={() => { this.handleChangeState(10) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">標準的な支払いサイトはどれくらいですか？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    月末締め翌月25日支払いの25日サイトが弊社の一般的な支払いサイトになります。<br />
                                    案件によって異なる場合もございます。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[11]} onClick={() => { this.handleChangeState(11) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">客先常駐案件とはなんでしょうか？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    フリーランスや正社員として、クライアント企業に週5日で常駐業務を行う案件です。<br />
                                    また、契約期間も数ヶ月ごとに更新できるため、自身のキャリアプランに合わせて案件を経験し、キャリアアップしていくことも可能です。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[12]} onClick={() => { this.handleChangeState(12) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">案件の掲載を依頼できますか？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    案件情報の掲載をご希望の際は、「<Link to="/inquiry">お問い合わせ</Link>」よりご連絡ください。追って、弊社担当者よりご連絡を差し上げます。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[13]} onClick={() => { this.handleChangeState(13) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">案件はどうやって検索できますか？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    MINONAVIでは、「スキル」「職種」「地域」「最寄駅」「雇用形態」から案件情報を検索することができます。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Container>

                <Container xs={12}>
                    <Typography className="faq_title">その他</Typography>
                    <Grid item xs={12}>
                        <Accordion expanded={tabs[14]} onClick={() => { this.handleChangeState(14) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">Cookie(クッキー)の使用しますか？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    MINONAVIでは、ユーザーのプライバシーの保護や利便性の向上等のためにCookie（クッキー）を使用します。<br />
                                    ただし、Cookieには個人を特定する情報は含まれません。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[15]} onClick={() => { this.handleChangeState(15) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">システム上のエラー、トラブル等のご質問はどうしますか？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    MINONAVIに登録できない、画面が表示されないといったトラブルについては、
                                    恐れ入りますがエラー内容をお書き添えいただき、ミノナビ事務局（{`support@${server.host}`}）までご連絡ください。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={tabs[16]} onClick={() => { this.handleChangeState(16) }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className="question">案件の掲載を依頼したい？</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p">
                                    案件情報の掲載をご希望の際は、「<Link to="/inquiry">お問い合わせ</Link>」よりご連絡ください。<br />
                                    追って、弊社担当者よりご連絡を差し上げます。
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Container>
            </>
        )
    }
}

export default withStyles(style)((props) => <FAQList {...props} params={useParams()} />);