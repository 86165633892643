import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "./CustomUtil";

class Mail {
    managerToUserMailSend = (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.userMailSend, params, headers);
    }
    
    userToManagerMailSend = (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.inquery, params, headers);
    }
}

export default new Mail();