// 経歴
const data = [
    {
        key: '無し',
        value: '10'
    },
    {
        key: '1 ~ 3年',
        value: '20'
    },
    {
        key: '3 ~ 5年',
        value: '30'
    },
    {
        key: '5 ~ 10年',
        value: '40'
    },
    {
        key: '10 ~ 15年',
        value: '50'
    },
    {
        key: '15年以上',
        value: '60'
    },
]

export default data;