import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "./CustomUtil";
import Validation from "./Validation";

class Auth {
    logIn = ({ email, pw }) => {
        const data = {
            email: email,
            pw: pw
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.login, params, headers);
    }

    logOut = async ({ email }) => {
        const data = {
            email: email
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.logout, params, headers).then((res) => {
            CustomUtil.delSessionItem('session');
            return res;
        });
    }

    checkAdmin = () => {
        let check = false;
        try {
            const enc = CustomUtil.getSessionItem('session');
            const login = JSON.parse(CustomUtil.decrypt(enc));

            if (Validation.empty(login)) {
                throw new Error();
            }

            if (login.is_admin !== 'Y') {
                throw new Error();
            }
        } catch (e) {
            check = true;
        }

        return check;
    }

    checkMember = (referer) => {
        let check = false;
        try {
            const session = CustomUtil.getSessionItem('session');
            if (Validation.empty(session)) {
                throw new Error('session is null');
            }

            const login = JSON.parse(CustomUtil.decrypt(session));

            if (Validation.empty(login)) {
                throw new Error('login is null');
            }
        } catch (e) {
            this.setReferer(referer)
            check = true;
        }

        return check;
    }

    doAuthTokenLogin = (authKey) => {
        const data = {
            authToken: authKey
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.checkAuthToken, params, headers);
    }

    setReferer = (referer) => {
        if(referer){
            const server = CustomUtil.getSessionItem('server');
            const serverData = JSON.parse(CustomUtil.decrypt(server));
            serverData.referer = referer;
            const enc = CustomUtil.encrypt(JSON.stringify(serverData));
            CustomUtil.setSessionItem('server', enc);
        }
    }
}

export default new Auth();