import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";

class AgendaList {
    getList = (page, searchData) => {
        const data = {
            mode: 'manager',
            nowPage: page,
            ...searchData
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.itemList, params, headers)
    }

    dataProcess = (obj) => {
        for (const [index, value] of Object.entries(obj)) {
            obj[index].style = value.del_flag === 'Y' ? { backgroundColor: 'rgba(0,0,0,0.3)' } : null;
            obj[index].status = CustomUtil.getAgendaStatus(value.status);
            obj[index].emp_status = CustomUtil.getEmpStatus(value.emp_status);
            obj[index].location = CustomUtil.getNeedWorkLocation(value.location);
            obj[index].language = CustomUtil.getLanguage(value.language);
            obj[index].recruitment_type = CustomUtil.getRecruitmentType(value.recruitment_type);
        }

        return obj;
    }
}

export default new AgendaList();