const data = {
    name_sei: '',
    name_mei: '',
    name_kana_sei: '',
    name_kana_mei: '',
    email: '',
    pw: '',
    pw_confirm: '',
    gender: '',
    last_education: '',
    last_education_grade: '',
    last_education_status: '',
    birthday: '',
    phone: '',
    last_education_name: '',
    nationality: '',
    need_work_location: '',
    near_station:'',
    now_address: '',
    now_work_kind: '',
    work_history: '',
    use_language: [],
    resume: '',
    career: '',
    profile: '',
    portfolio: '',
}

export default data;