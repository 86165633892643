import React from 'react';
import auth from '../../model/common/Auth';

import IMGblackmenu from '../../asset/img/black_menu.svg';
import IMGblacklogin from '../../asset/img/black_login.svg';
import IMGblacklogout from '../../asset/img/black_logout.svg';
import IMGwhitelogoside from '../../asset/img/white_logo_side.svg';
import IMGwhitelogin from '../../asset/img/white_login.svg';
import IMGwhiteregist from '../../asset/img/white_regist.svg';
import IMGwhitelogout from '../../asset/img/white_logout.svg';
import IMGwhiteedit from '../../asset/img/white_edit.svg';

import {
    Link,
    MenuList,
    MenuItem,
    Grid,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@mui/styles';

// override style
const style = {}

@inject('stores')
@observer
class SlideNaviMenu extends React.Component {
    state = {
        menuView: false
    };

    login = this.props.stores.login;

    toggleDrawer = (bool) => {
        this.setState({
            menuView: bool
        });
    }

    moveUrl = (url) => {
        window.location.href = url
    }

    doLogout = () => {
        auth.logOut(this.login).then((res) => {
            this.login.init();
            this.moveUrl('/');
        });
    }

    renderSliderNaviSubMenu = () => {
        if (this.login.getData('is_admin') === 'Y') {
            return (
                <Grid className="nav">
                    <MenuList>
                        <MenuItem onClick={() => { this.moveUrl('/mg/agendaList') }}>
                            <ListItemText primary='案件管理' />
                        </MenuItem>

                        <MenuItem onClick={() => { this.moveUrl('/mg/userList') }}>
                            <ListItemText primary='会員管理' />
                        </MenuItem>
                    </MenuList>
                </Grid>
            );
        } else {
            return (
                <Grid className="nav">
                    <MenuList>
                        <MenuItem onClick={() => { this.moveUrl('/search') }}>
                            <ListItemText primary='案件を探す' />
                        </MenuItem>
                        <MenuItem onClick={() => { this.moveUrl('/siteinfo') }}>
                            <ListItemText primary='サービス紹介' />
                        </MenuItem>
                        <MenuItem onClick={() => { this.moveUrl('/faq') }}>
                            <ListItemText primary='よくある質問' />
                        </MenuItem>
                    </MenuList>
                </Grid>
            );
        }
    }

    beforeLoginSliderNaviComponent = () => {
        return (
            <>
                <Grid className="sidebar">
                    <Grid className="side_logo">
                        <img src={IMGwhitelogoside} alt="MINONAVI" />
                    </Grid>

                    <Grid className="side_menu">
                        <MenuList>
                            <MenuItem onClick={() => { this.moveUrl('/login') }}>
                                <ListItemIcon className="circle">
                                    <img src={IMGwhitelogin} alt="ログイン" />
                                </ListItemIcon>
                                <ListItemText primary='ログイン' />
                            </MenuItem>
                            <MenuItem onClick={() => { this.moveUrl('/regist') }}>
                                <ListItemIcon className="circle">
                                    <img src={IMGwhiteregist} alt="会員登録" />
                                </ListItemIcon>
                                <ListItemText primary='会員登録' />
                            </MenuItem>
                        </MenuList>
                    </Grid>

                    <Grid className="nav">
                        <MenuList>
                            <MenuItem onClick={() => { this.moveUrl('/search') }}>
                                <ListItemText primary='案件を探す' />
                            </MenuItem>
                            <MenuItem onClick={() => { this.moveUrl('/siteinfo') }}>
                                <ListItemText primary='サービス紹介' />
                            </MenuItem>
                            <MenuItem onClick={() => { this.moveUrl('/faq') }}>
                                <ListItemText primary='よくある質問' />
                            </MenuItem>
                        </MenuList>
                    </Grid>
                </Grid>
            </>
        );
    }

    afterLoginSliderNaviComponent = () => {
        const isAdmin = this.login.getData('is_admin');
        const mypageUrl = isAdmin === 'Y' ? '/mg/mypage' : '/mypage';
        
        return (
            <>
                <Grid className="sidebar">
                    <Grid className="side_logo">
                        <img src={IMGwhitelogoside} alt="MINONAVI" />
                    </Grid>

                    <Grid className="side_menu">
                        <MenuList>
                            <MenuItem onClick={this.doLogout}>
                                <ListItemIcon className="circle">
                                    <img src={IMGwhitelogout} alt="ログアウト" />
                                </ListItemIcon>
                                <ListItemText primary='ログアウト' />
                            </MenuItem>
                            <MenuItem onClick={() => { this.moveUrl(mypageUrl) }}>
                                <ListItemIcon className="circle">
                                    <img src={IMGwhiteedit} alt="マイページ" />
                                </ListItemIcon>
                                <ListItemText primary='マイページ' />
                            </MenuItem>
                        </MenuList>
                    </Grid>

                    {this.renderSliderNaviSubMenu()}
                </Grid>
            </>
        );
    }

    afterLoginHeaderImageNavi = () => {
        return (
            <Link onClick={this.doLogout}>
                <img src={IMGblacklogout} alt="Logout" />
            </Link>
        );
    }

    beforeLoginHeaderImageNavi = () => {
        return (
            <Link onClick={() => { this.moveUrl('/login') }}>
                <img id="login" src={IMGblacklogin} alt="Login" />
            </Link>
        );
    }

    render() {
        return (
            <Grid id="menu">
                {
                    this.login.data === null ? this.beforeLoginHeaderImageNavi() : this.afterLoginHeaderImageNavi()
                }

                <Link className="openbtn" onClick={() => { this.toggleDrawer(true) }}>
                    <img id="hamberger" src={IMGblackmenu} alt="Menu" />
                </Link>

                <Drawer anchor='right' open={this.state.menuView} onClose={() => { this.toggleDrawer(false) }} >
                    {
                        this.login.data === null ? this.beforeLoginSliderNaviComponent() : this.afterLoginSliderNaviComponent()
                    }
                </Drawer>
            </Grid>
        );
    }
}

export default withStyles(style)(SlideNaviMenu);