const data = {
    name: '千葉県',
    key: 'chiba',
    value: 'KAN40',
    area: [
        {
            key: '千葉県',
            value: 'CB000'
        }, {
            key: "その他",
            value: "CB999"
        }
    ]
}

export default data;