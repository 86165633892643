import React from 'react';
import { inject, observer } from "mobx-react";
import IMGwhiteclose from '../../asset/img/white_close.svg';
import IMGwhitesearch from '../../asset/img/white_search.svg';
import CustomUtil from '../../model/common/CustomUtil';

import {
    Grid,
    Button,
    TextField,
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
        paddingTop: '20px',
        '& .search_wrap': {
            width: '90%',
            '& .search-items': {
                margin: '0 5px'
            }
        }
    }
}

@inject('stores')
@observer
class UserFaqSearchBox extends React.Component {
    constructor(props) {
        super(props);

        const initData = CustomUtil.deepCopy(props.formData);

        this.stores = props.stores;
        this.stores.search.resetData(initData)
    }

    handleSearchbox = (e) => {
        this.stores.search.setData(e.target.name, e.target.value);
    }

    handleResetSearch = () => {
        const { handleGetListData, formData } = this.props;
        const initData = CustomUtil.deepCopy(formData);
        this.stores.search.resetData(initData);
        handleGetListData(1)
    }

    handleSearch = () => {
        const { handleGetListData } = this.props;
        handleGetListData(1);
    }

    render() {
        const { classes } = this.props;
        const email = this.stores.search.getData('email');
        const name = this.stores.search.getData('name');

        return (
            <Grid container justifyContent={'center'} classes={classes}>
                <Grid container justifyContent={'center'} className="search_wrap">
                    <Grid container justifyContent={'center'}>
                        <TextField id="outlined-basic" label={'氏名'} variant="outlined" name={'name'} onChange={this.handleSearchbox} className={'search-items'} value={name}/>
                        <TextField id="outlined-basic" label={'email'} variant="outlined" name={'email'} onChange={this.handleSearchbox} className={'search-items'} value={email}/>
                    </Grid>
                    <Grid className="search_btn">
                        <Button className="list_btn" onClick={this.handleSearch}><img src={IMGwhitesearch} alt="" />{'条件検索'}</Button>
                        <Button className="list_btn" onClick={this.handleResetSearch}><img src={IMGwhiteclose} alt="" />{'条件リセット'}</Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

UserFaqSearchBox.defaultProps = {
    formData : {
        email: '',
        name: '',
    }
}

export default withStyles(style)(UserFaqSearchBox);
