import React from 'react';
import CommNav from '../../component/common/CommNav';
import CommFooter from '../../component/common/CommFooter';

import {
    Box,
    Container,
    Grid,
    Typography
} from '@mui/material';

import img404 from '../../asset/img/undraw_page_not_found_su7k.svg';

import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
        backgroundColor: '#fff',
        '& .box': {
            marginTop: '4rem',
            '& .main-text' : {
                fontSize: '5rem'
            },
            '@media (max-width:767px)' : {
                '& .main-text': {
                    fontSize : '2rem'
                }
            }
        }
    }
}

class NotFoundPage extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid classes={classes}>
                <CommNav />
                <Box className={'box'}>
                    <Container maxWidth="md">
                        <Typography
                            align="center"
                            color="textPrimary"
                            className={'main-text'}
                        >
                            404: The page you are looking for isn’t here
                        </Typography>
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="subtitle2"
                        >
                            You either tried some shady route or you came here by mistake.
                            Whichever it is, try using the navigation
                        </Typography>
                        <Box sx={{ textAlign: 'center' }}>
                            <img
                                alt="Under development"
                                src={img404}
                                style={{
                                    marginTop: 50,
                                    display: 'inline-block',
                                    maxWidth: '100%',
                                    width: 560
                                }}
                            />
                        </Box>
                    </Container>
                </Box>
                <CommFooter />
            </Grid>
        );
    }
}

export default withStyles(style)(NotFoundPage);
