import React from 'react';
import Editor from '../../component/common/Editor';
import model from '../../model/common/Mail';

import {
    Grid,
    Typography,
    Button,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import Validation from '../../model/common/Validation';

// override style
const style = {
    root : {
        paddingBottom: 0,
    }
}

class MailForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email : this.props.email, 
            subject : '', 
            contents : ''
        }

        this.editor = null
    }

    setFormData = (e) => {

        const values = [];

        if(e.editor){
            if(!this.editor){
                this.editor = e.editor;
            }

            values[e.editor.name] = e.editor.getData();
        }else{
            values[e.target.name] = e.target.value;
        }

        this.setState({
            ...values
        });
    }

    sendMail = async() => {
        const { backDropSwitch } = this.props;
        const formData = this.state;

        let checkEmpty= false;

        for(const key of Object.keys(formData)){
            if(Validation.empty(formData[key])){
                checkEmpty = true;
                break;
            }
        }

        if(checkEmpty){
            alert('メール内容を記入してください。')
            return;
        }

        backDropSwitch(true)
        const res = await model.managerToUserMailSend(formData);
        alert(res.data.message);

        if(res.status === 200){
            this.editor.setData('');
            this.setState({
                subject : '', 
                contents : ''
            })
        }

        backDropSwitch(false);
    }

    // TODO::: 템플레이트 선택 가능하게 바꾸기

    render() {
        const { classes } = this.props;
        const { subject } = this.state;
        return (
            <Grid className={`content mt_none`} classes={classes}>
                <Grid container direction="row" justifyContent="center" alignItems="center" className="form">
                    <Grid>
                        <Typography variant="h6">{'メール作成'}</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Grid item className="form_title_cell mb_none">{'件名'}</Grid>
                        <Grid item className="form_input_cell">
                            <input className="cellwidth10" type="text" name={'subject'} onChange={this.setFormData} value={subject}/>
                        </Grid>
                        <Grid>
                            <Editor name={'contents'} onChange={this.setFormData}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="mt_30" justifyContent={'center'}>
                    <Button variant="contained" color="primary" onClick={this.sendMail}>{'送信'}</Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(MailForm);
