import React from 'react';
import { inject, observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { withStyles } from '@mui/styles';
import model from '../../model/common/Auth';
import util from '../../model/common/CustomUtil';

import {
    Button
} from '@mui/material';

// override style
const style = {}

@inject('stores')
@observer
class TestLogin extends React.Component {
    login = this.props.stores.login;

    testLogin = (mode) => {

        const formData = {
            email: mode === 'user' ? 'vkcel800@gmail.com' : 'vkcel400@gmail.com',
            pw: 'a123456'
        };

        model.logIn(formData).then((res) => {
            if (res.data.status === 'success') {
                const enc = util.encrypt(JSON.stringify(res.data.data));
                util.setSessionItem('session', enc);

                runInAction(() => {
                    this.login.data = res.data.data;
                });

                if (res.data.data.is_admin === "Y") {
                    window.location.href = '/mg/agendaList';
                } else {
                    const referer = util.updateSessionItemAndGetValue('server', 'referer');
                    window.location.href = referer !== '' ? referer : '/';
                }
            } else {
                alert('err');
            }
        })
    }

    render() {
        return (
            <>
                <Button variant='outlined' onClick={() => { this.testLogin('manager') }}>관리자로그인</Button>
                <Button variant='outlined' onClick={() => { this.testLogin('user') }}>일반유저로그인</Button>
            </>
        )
    }
}

export default withStyles(style)(TestLogin);
