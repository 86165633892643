import React from 'react';
import { inject } from 'mobx-react';
import model from '../../model/user/EntryDetl';
import util from '../../model/common/CustomUtil';

import AgendaDetlPC from '../../component/user/AgendaDetlPC';
import AgendaDetlSP from '../../component/user/AgendaDetlSP';
// import RecommendAgenda from '../../component/user/RecommendAgenda'

import {
    Grid,
} from '@mui/material';

import NoImage from '../../asset/img/no_image.png';

import { withStyles } from '@mui/styles';
import BackDrop from '../../component/common/BackDrop';
import CustomUtil from '../../model/common/CustomUtil';

// override style
const style = {
    root:{
        '& .reset-height': {
            minHeight : 'auto'
        },
    },
}

@inject('stores')
class EntryDetl extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            backDropView: false,
            agendaComplete: false,
        }
    
        this.store = props.stores.form;
        this.login = props.stores.login;
    }

    componentDidMount = () => {
        this.getData();
    }

    getData = async() => {
        const agendaIdx = this.store.getData('agendaIndex');
        const email = this.login.getData('email');

        const detlData = await model.getData(agendaIdx)
        const joinCheck = await model.agendaJoinChecked(email, agendaIdx);
        
        this.setState({
            data: model.dataProcess(detlData),
            joinCheck: joinCheck
        });
    }

    getManagerFileLink = () => {
        const { data } = this.state;
        let img = NoImage

        if(data){
            if(data.assign.file){
                const server = util.getApiServerUrl();
                img = `${server}/uploads/${data.assign.file}`;
            }
        }

        return img;
    }
    
    render() {
        const { data } = this.state;

        if(!data){
            return <></>
        }

        const { classes, onClose } = this.props;
        data.managerImage = this.getManagerFileLink();
        data.joinCheck = this.state.joinCheck;
        const isMobile = CustomUtil.checkMobile();
        const Component = isMobile ? AgendaDetlSP : AgendaDetlPC;

        return (
            <>
                {
                    <Grid classes={classes} className="content mt_none bg_gray">
                        <div className={'btn_close close-button'} onClick={onClose}>&times;</div>
                        
                        <Component data={data} />

                        {/* <RecommendAgenda data={data}/> */}
                    </Grid>
                }

                <BackDrop open={this.state.backDropView} />
            </>
        );
    }
}

export default withStyles(style)(EntryDetl);