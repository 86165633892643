import React from 'react';
import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import DataListTable from '../../component/common/DataListTable';
import model from '../../model/manager/AgendaList';
import AgendaDetl from './AgendaDetl';
import AgendaModify from './AgendaModify';
import AgendaRegister from './AgendaRegister';
import AgendaListSesrchBox from '../../component/manager/AgendaListSesrchBox';
import Auth from '../../model/common/Auth';
import { inject, observer } from 'mobx-react';
import { runInAction } from 'mobx';
import Validation from '../../model/common/Validation';
import CustomUtil from "../../model/common/CustomUtil";

import {
    Grid,
    Typography,
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {
    root:{
        '& .content': {
            '& th': {
                fontWeight: 'bold',
                backgroundColor: 'rgba(123, 123, 123, 0.1)'
            },
            '& td': {
                minWidth: '80px'
            },
            '& td:nth-child(1)': {
                minWidth: '120px'
            },
            '& td:nth-child(3), td:nth-child(5), td:nth-child(7)': {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '80px'
            },
            '& td:nth-child(2), td:nth-child(4)':{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '250px'
            }
        }
    }
}

@inject('stores')
@observer
class AgendaList extends React.Component {
    state = {
        header: ['案件番号', 'タイトル', '募集職種', '言語', '勤務地', '最寄駅', '契約形態', '担当', '状態'],
        keys: ['agenda_number', 'title', 'recruitment_type', 'language', 'location', 'nearest_station', 'emp_status', 'name', 'status'],
        data: null,
        pageNum: 0,
        component: {
            add: AgendaRegister,
            detl: AgendaDetl,
            modify: AgendaModify,
        }
    }

    store = this.props.stores.form;
    search = this.props.stores.search;
    resetSearch = true;

    paging = (event, pageNum) => {
        if (this.state.pageNum === pageNum) {
            return;
        }

        this.getDataList(pageNum);
    }

    getDataList = (pageNum) => {
        const params = this.search.getAllData();
        model.getList(pageNum, params).then(res => {
            res.data.list = model.dataProcess(res.data.list);
            this.setState({
                data: res.data,
                pageNum: pageNum
            });
        });
    }

    componentDidMount = () => {
        if (Auth.checkAdmin()) {
            window.location.href = '/';
            return;
        }

        if (Validation.empty(this.search.data)) {
            runInAction(() => {
                this.search.data = {
                    ...this.props.formData
                }
            })
        }
        this.paging(null, 1);
    }

    componentWillUnmount = () => {
        this.store.setData('modalView', false);
        CustomUtil.delSessionItem('search');
        this.search.resetData(null);
    }

    showModal = (data) => {
        this.store.setData('modalNextPage', 'detl');
        this.store.setData('modalView', true);
        this.store.setData('agendaIndex', data.idx);
    }

    closeModal = () => {
        this.store.setData('modalView', false);
    }

    nextPage = (page) => {
        this.store.setData('modalNextPage', page);
    }

    showRegister = () => {
        this.nextPage('add');
        this.store.setData('modalView', true);
    }
    
    handleTopBoxSearchButton = () => {
        this.getDataList(1)
    }

    render() {
        const { header, keys, data } = this.state;
        const { classes } = this.props;
        if (Auth.checkAdmin()) {
            window.location.href = '/';
            return (<></>);
        }

        if(!data){
            return <></>
        }

        const component = this.state.component[this.store.getData('modalNextPage')];
        CustomUtil.scrollTarget();
        return (
            <Grid container justifyContent={'center'} classes={classes}>
                <CommNav />
                <Grid className="content">
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item>
                            <Grid item>
                                <center>
                                    <Typography variant="h2" className="content_title">{'案件管理'}</Typography>
                                </center>
                            </Grid>

                            <AgendaListSesrchBox showRegisterFnc={this.showRegister} handleSearch={this.handleTopBoxSearchButton}/>
                            
                            {
                                <DataListTable
                                    data={data}
                                    header={header}
                                    keys={keys}
                                    pagingFunc={this.paging}
                                    Component={component}
                                    showModalFnc={this.showModal}
                                    closeModalFnc={this.closeModal}
                                    nextPageFnc={this.nextPage}
                                    pageNum={this.state.pageNum}
                                    pageView
                                    viewMode={'agendaList'}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <CommFooter />
            </Grid>
        );
    }
}

AgendaList.defaultProps = {
    formData: {
        recommended: "",
        needWorkLocation: "",
        recruitmentType: "",
        agendaStatus: "",
        language: "",
        nearest_station: "",
    },
};

export default withStyles(style)(AgendaList);