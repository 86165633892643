import React from 'react';
import { Link } from 'react-router-dom';
import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import FAQList from './FAQList';
import CustomUtil from '../../model/common/CustomUtil'
import {
    Grid,
    Typography
} from '@mui/material';


import { withStyles } from '@mui/styles';

// override style
const style = {}

class FAQ extends React.Component {

    renderDescription = () => {
        return (
            <Grid item xs={12}>
                <center>
                    <Typography variant="h2" className="content_title">{'よくある質問'}</Typography>
                    <Typography vartiant="p" className="content_desc">
                        {'ミノナビに寄せられるよくある質問をまとめました。'}<br />
                        {'こちらを確認しても不明な点がある場合は、ログイン後、'}{<Link to="/faq">お問い合わせ</Link>}{'ページからご連絡ください。'}
                    </Typography>
                </center>
            </Grid>
        )
    }

    renderFaQ = () => {
        return (
            <>
                <CommNav />
                <Grid className="content">
                    {this.renderDescription()}
                    <FAQList />
                </Grid>
                <CommFooter />
            </>
        )
    }

    renderMypageFaQ = () => {
        return (
            <>
                {this.renderDescription()}
                <FAQList />
            </>
        )
    }

    render() {
        CustomUtil.scrollTarget();
        const { mypage } = this.props;

        return (
            <Grid style={{ position: 'relative' }}>
                {
                    mypage ? this.renderMypageFaQ() : this.renderFaQ()
                }
            </Grid>
        );
    }
}

export default withStyles(style)(FAQ);