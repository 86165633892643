import React from 'react';
import moment from 'moment';

import RecruitmentType from '../../constants/RecruitmentType';
import NeedWorkLocation from '../../constants/NeedWorkLocation';
import Location from '../../constants/Location';
import EmpStatus from '../../constants/EmpStatus';
import Language from '../../constants/Language';
import Hitch from '../../constants/Hitch';
import Editor from '../../component/common/Editor';
import Message from '../../constants/Message';
import model from '../../model/manager/AgendaRegister';

import {
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import TextBox from '../common/TextBox';
import ErrorMsg from '../common/ErrorMsg';
import { inject, observer } from 'mobx-react';
import CustomDatePicker from '../common/CustomDatePicker';

// override style
const style = {
    root:{
        '& .thirdWidth' : {
            minWidth : '15%'
        },
        '& .halfWidth': {
            minWidth : '49%'
        },
        '& .maxWidth': {
            minWidth : '100%'
        },
        '& .margin-left-20': {
            marginLeft : '20px'
        },
        '& .margin-top-30': {
            marginTop : '30px'
        }
    }
}

@inject('stores')
@observer
class RegistForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cityList: [],
            ...props.formData
        };
        this.registValidation = props.stores.validation;
    }

    componentDidMount = async() => {
        let { data, setFormDataFnc } = this.props;
        data = data || [];
        data['assignList'] = await model.getAssignList();

        if (data) {
            data['cityList'] = [];

            for (const index in data['city']) {
                for(const [kk, vv] of Object.entries(Location)){
                    if (vv.value === data['location'][index]) {
                        data['cityList'][index] = vv.area;
                    }
                }
            }

            for(const [key, value] of Object.entries(data)){
                setFormDataFnc(key, value)
            }
        }

        this.setState({
            ...data
        })
    }

    renderRequireSpan = () => {
        return (
            <Typography variant="span" className="mandatory">{'*必須'}</Typography>
        )
    }

    handleEditorOnChange = (e) => {
        const { setFormDataFnc } = this.props;
        setFormDataFnc('contents', e.editor.getData());
    }

    renderTitle = () => {
        const { setFormDataFnc, data } = this.props;
        const errorCheck = this.registValidation.getData('title');
        const config = {
            type: 'text',
            error: this.registValidation.getData('title'),
            id: 'title',
            label: Message.info.regist.title,
            defaultValue: data ? data.title : null,
            className : 'maxWidth',
            onChange: (e => { setFormDataFnc('title', e.target.value) })
        }

        return (
            <>
                <Grid item md={3} className="form_title_cell">{Message.info.regist.title}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} className="form_input_cell">
                    <TextBox {...config} />
                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.title}</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    handleRercommended = (e) => {
        const { setFormDataFnc } = this.props;
        this.setState({
            recommended: e.target.value
        });

        setFormDataFnc('recommended', e.target.value);
    }

    renderEmpStatus = () => {
        const { emp_status } = this.state;
        const empStatus = emp_status ? emp_status : []
        const errorCheck = this.registValidation.getData('emp_status');
        return (
            <>
                <Grid item md={3} className="form_title_cell ai_top">{Message.info.regist.emp_status}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} className="form_input_cell">
                    {
                        EmpStatus.map((item, index) => {
                            const checked = empStatus.indexOf(item.value.toString()) > -1 ? true : false;
                            return (
                                <Grid item md={4} key={index}>
                                    <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Checkbox color="primary" name={'emp_status'} onChange={this.handleCheckBox} checked={checked}/>}
                                        label={item.key}
                                        labelPlacement="end"
                                    />
                                </Grid>
                            )
                        })
                    }
                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.emp_status}</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }
    
    renderRecruitmentType = () => {
        const { recruitment_type } = this.state;
        const checkValue = recruitment_type ? recruitment_type : '';
        const errorCheck = this.registValidation.getData('recruitment_type');
        return (
            <>
                <Grid item md={3} className="form_title_cell ai_top">{Message.info.regist.recruitment_type}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} className="form_input_cell">
                    {
                        RecruitmentType.map((item, index) => {
                            const checked = checkValue.indexOf(item.value.toString()) > -1 ? true : false;
                            return (
                                <Grid item md={6} key={index}>
                                    <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Checkbox color="primary" name={'recruitment_type'} onChange={this.handleCheckBox} checked={checked}/>}
                                        label={item.key}
                                        labelPlacement="end"
                                    />
                                </Grid>
                            )
                        })
                    }
                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.recruitment_type}</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    handleRecruitmentType = (e) => {
        const { setFormDataFnc } = this.props;
        this.setState({
            recruitment_type: e.target.value
        });

        setFormDataFnc('recruitment_type', e.target.value);
    }

    renderPay = () => {
        const { data, setFormDataFnc } = this.props;
        const errorCheck = this.registValidation.getData('pay');
        const config = {
            type: 'text',
            error: errorCheck,
            id: 'pay',
            label: '60、80、150 など、半角数字のみ入力すると、〜60万円/月、〜150万円/月で表記されます。',
            defaultValue: data ? data.pay : null,
            className : 'maxWidth',
            onChange: (e => { setFormDataFnc('pay', e.target.value) })
        }

        return (
            <>
                <Grid item md={3} className="form_title_cell">{Message.info.regist.pay}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} className="form_input_cell">
                    <TextBox {...config} />
                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.pay}</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    handlePay = (e) => {
        const { setFormDataFnc } = this.props;
        this.setState({
            pay: e.target.value
        });

        setFormDataFnc('pay', e.target.value);
    }

    renderWorkLocation = () => {
        const checkLocation = this.registValidation.getData('location');
        const checkCity = this.registValidation.getData('city');
        const checkStation = this.registValidation.getData('nearest_station');
        const errorCheck = checkLocation || checkCity || checkStation;
        
        return (
            <>
                <Grid item md={3} className="form_title_cell">{'勤務地'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9}>
                    {this.renderWorkLocationParts(0)}
                    {this.renderWorkLocationParts(1)}
                    {this.renderWorkLocationParts(2)}
                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.location}</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    renderWorkLocationParts = (key) => {
        return (
            <Grid item>
                {this.renderLocation(key)}
                {this.renderCity(key)}
                {this.renderNearestStation(key)}
            </Grid>
        )
    }

    renderLocation = (key) => {
        const { location } = this.state;
        const locationValue = location ? location : [];
        const defualtValue = locationValue[key] ? locationValue[key] : '';
        const errorCheck = this.registValidation.getData('location');
        const config = {
            value: defualtValue,
            onChange: (e) => this.handleLocation(e, key),
            helperText: Message.info.regist.location,
            label: '',
            listData: NeedWorkLocation,
            id: 'location',
            type: 'select',
            className : 'thirdWidth',
            error: errorCheck
        }

        return (
            <TextBox {...config} />
        )
    }

    handleLocation = (e, index) => {
        const { setFormDataFnc, getFormDataFnc } = this.props;
        const location = getFormDataFnc('location');
        const { cityList } = this.state;

        for (const [key, data] of Object.entries(Location)) {
            if (data.value === e.target.value) {
                location[index] = e.target.value;
                cityList[index] = data.area;

                this.setState({
                    location: location,
                    cityList: cityList,
                });
                break;
            }
        }

        setFormDataFnc('location', location);
    }

    renderCity = (key) => {
        const { city, cityList } = this.state;
        const cityValue = city ? city[key] : '';
        const cityListValue = cityList ? cityList : [];
        const cityListDefaultValue = cityListValue[key] ? cityListValue[key] : [];
        const errorCheck = this.registValidation.getData('city');

        const config = {
            value: cityValue,
            onChange: e => this.handleCity(e, key),
            helperText: Message.info.regist.city,
            label: '',
            listData: cityListDefaultValue ,
            id: 'city',
            type: 'select',
            error: errorCheck,
            className: 'thirdWidth margin-left-20',
        }

        return (
            <TextBox {...config} />
        )
    }

    handleCity = (e, key) => {
        const { setFormDataFnc, getFormDataFnc } = this.props;
        const city = getFormDataFnc('city');

        city[key] = e.target.value;

        this.setState({
            city: city,
        });

        setFormDataFnc('city', city);
    }


    renderNearestStation = (key) => {
        const { data } = this.props;
        const defaultValue = data ? data.nearest_station[key] : null;
        const config = {
            type: 'text',
            error: this.registValidation.getData('nearest_station'),
            id: 'nearest_station',
            defaultValue: defaultValue,
            helperText: Message.info.regist.nearest_station,
            onChange: (e => this.handlNerenderNearestStation(e, key)),
            className : 'thirdWidth margin-left-20',
        }
        return (
            <TextBox {...config} />
        )
    }

    handlNerenderNearestStation = (e, key) => {
        const { setFormDataFnc, getFormDataFnc } = this.props;
        const nearest_station = getFormDataFnc('nearest_station');
        nearest_station[key] = e.target.value;
        setFormDataFnc('nearest_station', nearest_station)
    }

    renderLanguage = () => {
        const { language } = this.state;
        const languageValue = language ? language : [];
        const errorCheck = this.registValidation.getData('language');
        return (
            <>
                <Grid item md={3} className="form_title_cell ai_top">{'開発言語'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} className="form_input_cell">
                    {
                        Language.map((item, index) => {
                            const checked = languageValue.indexOf(item.value.toString()) > -1 ? true : false;
                            return (
                                <Grid item md={4} key={index}>
                                    <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Checkbox color="primary" name={'language'} onChange={this.handleCheckBox} checked={checked}/>}
                                        label={item.key}
                                        labelPlacement="end"
                                    />
                                </Grid>
                            )
                        })
                    }
                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.language}</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    handleCheckBox = (e) => {
        const { setFormDataFnc, getFormDataFnc } = this.props;
        let tmp = getFormDataFnc(e.target.name);

        if (e.target.checked) {
            tmp.push(e.target.value)
        } else {
            const indexOf = tmp.indexOf(e.target.value);
            if (indexOf !== -1) {
                tmp.splice(indexOf, 1);
            }
        }

        const distint = [...new Set(tmp.map(x => x))];
        setFormDataFnc(e.target.name, distint);
        const stateData = {};
        stateData[e.target.name] = distint;
        this.setState(stateData);
    }

    renderHitch = () => {
        const { hitch } = this.state;
        const hitchValue = hitch ? hitch : [];
        const errorCheck = this.registValidation.getData('hitch');
        return (
            <>
                <Grid item md={3} className="form_title_cell ai_top">{'こだわり'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} className="form_input_cell">
                    <Grid container direction="row" className="checkbox_wrap">
                        {
                            Hitch.map((item, index) => {
                                const checked = hitchValue.indexOf(item.value.toString()) > -1 ? true : false;
                                return (
                                    <Grid item md={3} key={index}>
                                        <FormControlLabel
                                            key={item.key}
                                            value={item.value}
                                            control={<Checkbox color="primary" name={'hitch'} onChange={this.handleCheckBox} checked={checked}/>}
                                            label={item.key}
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                )
                            })
                        }
                        {
                            errorCheck &&
                            <Grid container>
                                <Typography color={'red'}>{Message.validation.regist.hitch}</Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </>
        )
    }

    renderContents = () => {
        const { data } = this.props;
        const errorCheck = this.registValidation.getData('contents');
        return (
            <>
                <Grid item md={3} className="form_title_cell ai_top">{'詳細内容'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} >
                    <Editor
                        onChange={this.handleEditorOnChange}
                        initData={data ? data.contents : null}
                        config={{ height: '400px' }} />
                        {
                            errorCheck &&
                            <Grid container>
                                <Typography color={'red'}>{Message.validation.regist.contents}</Typography>
                            </Grid>
                        }
                </Grid>
            </>
        )
    }

    renderMessage = () => {
        const { setFormDataFnc, data } = this.props;
        const errorCheck = this.registValidation.getData('manager_message');
        const config = {
            type: 'text',
            error: errorCheck,
            id: 'manager_message',
            label: 'メッセージ',
            className : 'maxWidth',
            defaultValue: data ? data.manager_message : null,
            helperText: Message.info.regist.manager_message,
            onChange: (e => { setFormDataFnc('manager_message', e.target.value) })
        }
        return (
            <>
                <Grid item md={3} className="form_title_cell margin-top-30">{'メッセージ'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9}>
                    <TextBox {...config} />
                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.manager_message}</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    renderKeyword = () => {
        const { setFormDataFnc, data } = this.props;
        const errorCheck = this.registValidation.getData('keyword');
        const config = {
            type: 'text',
            error: errorCheck,
            id: 'keyword',
            label: 'キーワード',
            className : 'maxWidth',
            defaultValue: data ? data.keyword : null,
            helperText: Message.info.regist.keyword,
            onChange: (e => { setFormDataFnc('keyword', e.target.value) })
        }
        return (
            <>
                <Grid item md={3} className="form_title_cell">{'キーワード'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} className="form_input_cell">
                    <TextBox {...config} />
                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.keyword}</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    renderAssign = () => {
        const errorCheck = this.registValidation.getData('assign');
        const { assign, assignList } = this.state
        const config = {
            value: assign ? assign : '',
            onChange: this.handleAssing,
            helperText: Message.info.regist.assign,
            label: '',
            listData: assignList,
            id: 'assign',
            className : 'maxWidth',
            type: 'select',
            error: errorCheck,
        }

        return (
            <>
                <Grid item md={3} className="form_title_cell">{'案件担当'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} className="form_input_cell">

                    {
                        assignList &&
                        <TextBox {...config} />
                    }

                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.assign}</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    renderDb = () => {
        const { setFormDataFnc, data } = this.props;
        const errorCheck = this.registValidation.getData('db');
        const config = {
            type: 'text',
            error: errorCheck,
            id: 'db',
            label: 'データベース',
            defaultValue: data ? data.db : null,
            className : 'maxWidth',
            onChange: (e => { setFormDataFnc('db', e.target.value) })
        }

        return (
            <>
                <Grid item md={3} className="form_title_cell">{Message.info.regist.db}</Grid>
                <Grid item md={9} className="form_input_cell">
                    <TextBox {...config} />
                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.db}</Typography> 
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    renderFw = () => {
        const { setFormDataFnc, data } = this.props;
        const errorCheck = this.registValidation.getData('fw');
        const config = {
            type: 'text',
            error: errorCheck,
            id: 'fw',
            label: 'フレームワーク',
            defaultValue: data ? data.fw : null,
            className : 'maxWidth',
            onChange: (e => { setFormDataFnc('fw', e.target.value) })
        }

        return (
            <>
                <Grid item md={3} className="form_title_cell">{Message.info.regist.fw}</Grid>
                <Grid item md={9} className="form_input_cell">
                    <TextBox {...config} />
                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.fw}</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    renderStartDate = () => {
        const { setFormDataFnc, data } = this.props;
        const errorCheck = this.registValidation.getData('job_start_date');
        const date = data ? moment(data.job_start_date) : null
        return (
            <>
                <Grid item md={3} className="form_title_cell margin-top-30">{'作業開始日'}</Grid>
                <Grid item md={9}>
                    <CustomDatePicker 
                        value={date} 
                        setValue={setFormDataFnc}/>
                    {
                        errorCheck &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.regist.job_start_date}</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    handleAssing = (e) => {
        const { setFormDataFnc } = this.props;
        this.setState({
            assign: e.target.value
        });

        setFormDataFnc('assign', e.target.value);
    }

    render() {
        const { errorView, classes } = this.props;

        return (
            <Grid className={`column-shadow ${classes.root}`}>
                <Grid container direction="row" justifyContent="center" alignItems="center" className="form">
                    {
                        errorView &&
                        <ErrorMsg message={Message.validation.dataErr} />
                    }

                    {this.renderEmpStatus()}
                    {this.renderTitle()}
                    {this.renderRecruitmentType()}
                    {this.renderWorkLocation()}
                    {this.renderPay()}
                    {this.renderLanguage()}
                    {this.renderDb()}
                    {this.renderFw()}
                    {this.renderHitch()}
                    {this.renderContents()}
                    {this.renderStartDate()}
                    {this.renderMessage()}
                    {this.renderKeyword()}
                    {this.renderAssign()}
                    
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(style)(RegistForm)