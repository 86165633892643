import React from 'react';

import CommNav from '../../component/common/CommNav';
import CommFooter from '../../component/common/CommFooter';
import MainTopSearch from '../../component/user/MainTopSearch';
import MainRecommendAgenda from '../../component/user/MainRecommendAgenda';
import MainAbout from '../../component/user/MainAbout';
import CustomUtil from '../../model/common/CustomUtil';
import {
    Grid,
    Container,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import MainSearchBox from '../../component/user/MainSearchBox';
// import MainSearchCondition from '../../component/user/MainSearchCondition';

// override style
const style = {
    root:{
        
    },
    resetMargin: {
        marginTop : '0px',
        position : 'relative',
        paddingTop : '0px',
    },
}

class Main extends React.Component {
    aboutFocus() {
        CustomUtil.scrollTarget('about-target');
    }

    render() {
        CustomUtil.scrollTarget();
        return (
            <Grid style={{ position: 'relative' }} >
                <CommNav />
                <MainTopSearch aboutFocus={this.aboutFocus} />

                <Grid className={"content"} style={style.resetMargin}>
                    <Container>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <MainSearchBox />
                            <MainRecommendAgenda />
                            {/* <MainSearchCondition /> */}
                        </Grid>
                    </Container>
                </Grid>
                <MainAbout id="about-target"/>
                <CommFooter />
            </Grid>
        );
    }
}

export default withStyles(style)(Main);