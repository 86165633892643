const data = [
    {
        key: '検討中',
        value: '10',
    },
    {
        key: '面談中',
        value: '20',
    },
    {
        key: '採用',
        value: '30',
    },
    {
        key: '不採用',
        value: '250',
    },
];

export default data;