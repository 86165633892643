import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";
import Validation from "../common/Validation";

class UserModify {
    getData = (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.detl, params, headers)
    }

    submit = async (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.modify, params, headers)
    }

    deleteFile = (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.fileDelete, params, headers)
    }

    exiteMember = (email) => {
        const formData = {
            email : email
        }

        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.exitMember, params, headers)
    }

    validation = (formData) => {
        const result = {};

        // check empty
        const ignore = ['delete_at', 'files', 'update_at'];
        for (const item of Object.keys(formData)) {
            if(ignore.indexOf(item) > -1){
                result[item] = false;
            }else{
                if (Validation.empty(formData[item])) {
                    result[item] = true;
                } else {
                    result[item] = false;
                }
            }
        }

        // check email
        const email = formData['email'];
        if (!Validation.empty(email) && Validation.email(email)) {
            result['email'] = true;
            result['emailNotmatch'] = true;
        }

        // check 最終学歴
        if(result['last_education'] || result['last_education_grade'] || result['last_education_status']){
            result['educationFail'] = true;
        }

        // check password
        const pw = formData['pw'];
        const pw_confirm = formData['pw_confirm'];
        if(Validation.empty(pw) && Validation.empty(pw_confirm)){
            result['pw'] = false;
            result['pw_confirm'] = false;
        }else{
            if (pw !== pw_confirm) {
                result['pw'] = true;
                result['pw_confirm'] = true;
                result['passwdNotmatch'] = true;
            }
        }

        // check 電話番号
        const phone = formData['phone'];
        if (!Validation.empty(phone) && Validation.phone(phone)) {
            result['phone'] = true;
            result['phoneNotmatch'] = true;
        }

        const check = Object.values(result).find(item => item) || false;

        return {
            result: result, 
            check: check
        }
    }

}

export default new UserModify();
