import React from "react";
import { inject, observer } from "mobx-react";
import LocationsList from '../../constants/LocationsList';
import CityList from '../../constants/CityList';
import CustomUtil from "../../model/common/CustomUtil";
import model from '../../model/user/Search';

import EmpStatus from '../../constants/EmpStatus';
import RecruitmentType from '../../constants/RecruitmentType';
import Language from '../../constants/Language';

import IMGx from '../../asset/img/x.svg';
import IMGwhitesearch from '../../asset/img/white_search.svg';

import {
    Grid,
    Typography,
    Button,
    Input,
} from '@mui/material';
import { withStyles } from '@mui/styles';

//orverrid style
const style = {
    root: {
        '& .active': {
            background: '#1976d2',
            color: '#ffffff',
            '&:hover': {
                background: '#1976d2'
            },
            '&:focus': {
                background: '#1976d2',
                color: '#ffffff',
            }
            
        },
        '& .card-btn': {
            margin: '2px',
            textTransform: 'capitalize'
        }, 
        '@media (max-width : 900px)': {
            
            '& .search_bar': {
                    width : '100%',
                    justifyContent : 'space-between',
                '& .MuiInput-root': {
                    width : '80%',
                    '& input': {
                        background : '#fff',
                        border : '1px solid #1976d2',
                        padding : '0.5rem 1rem',
                    },
                },
                'button': {
    
                }
                
            },
            '& .close-button': {
                position : 'fixed',
                top : '30px',
                right : '20px',
                
            },
            '& .mobile_aside_btn': {
                width : '100%',
                marginTop : '2rem',
                '& .sp_fixbtn': {
                    '& .MuiTypography-root': {
                        '& button': {
                            margin : '0 1rem',
                            '& img':{marginRight : '5px'}
                        }
                    }
                }
                
            }
        },
        
    }
}

@inject('stores')
@observer
class SearchLeftBox extends React.Component {
    constructor(props) {
        super(props);
        this.stores = props.stores;
        this.state = {
            keyword: ''
        }
    }

    getCategoty = (value) => {
        if(LocationsList.find(item => item.value === value)){
            return 'area';
        }else if(EmpStatus.find(item => item.value === value)){
            return 'empState';
        }else if(Language.find(item => item.value === value)){
            return 'language';
        }else if(RecruitmentType.find(item => item.value === value)){
            return 'position';
        }

        let isCity = false;
        for(const location of LocationsList){
            const check = CityList[location.value].find(item => item.value === value);
            if(check){
                isCity = true;
                break;
            }
        }

        if(isCity){
            return 'city';
        }

        return 'station';
    }

    handleDeleteTag = async (key, value) => {
        const searchData = this.stores.search.getAllData();
        const cate = this.getCategoty(value);
        
        let addTag = true;

        if (cate === 'area') {
            for(const item of CityList[value]){
                const check = searchData.location.city.find(i => i === item.value);
                if(check){
                    addTag = false;
                    break;
                }
            }
            searchData.cityList = CityList[value];

            if(addTag){
                searchData.location.area = this.searchDataDuplicateCheck(searchData.location.area, value);
            }

            if(searchData.location.area.length < 1){
                searchData.stationList = await model.getStationList([value]);
            }else{
                searchData.stationList = await model.getStationList(searchData.location.area);
            }
        } else if (cate === 'city') {
            searchData.location.city = this.searchDataDuplicateCheck(searchData.location.city, value)
        } else if (cate === 'station') {
            searchData.stations = this.searchDataDuplicateCheck(searchData.stations, value)
        } else if (cate === 'empState') {
            searchData.empStatus = this.searchDataDuplicateCheck(searchData.empStatus, value)
        } else if (cate === 'language') {
            searchData.language = this.searchDataDuplicateCheck(searchData.language, value)
        } else if (cate === 'position') {
            searchData.position = this.searchDataDuplicateCheck(searchData.position, value)
        }

        if(addTag){
            searchData.tags = {
                key: this.searchDataDuplicateCheck(searchData.tags.key, key),
                value: this.searchDataDuplicateCheck(searchData.tags.value, value),
            }
        }

        this.stores.search.resetData(searchData);
    }

    renderCardBox = () => {
        const tagDatas = this.stores.search.getData('tags');

        const renderCard = () => {
            return (
                tagDatas.key.map((item, index) => {
                    return (
                        <Typography key={index} variant="span" className="condition_list">
                            {tagDatas.key[index]}
                            <Button onClick={() => this.handleDeleteTag(tagDatas.key[index], tagDatas.value[index])}>
                                <img src={IMGx} alt={''} />
                            </Button>
                        </Typography>
                    )
                }
                )
            )
        }

        const renderNoCard = () => {
            return <Typography variant="span">{'選択された条件がありません。'}</Typography>
        }

        return (
            <Grid>
                {tagDatas.key.length > 0 ? renderCard() : renderNoCard()}
            </Grid>

        )
    }

    searchDataDuplicateCheck = (ref, value) => {
        let result = [...ref]

        const isHave = result.find(item => item === value)
        if (isHave) {
            result = result.filter(item => item !== value);
        } else {
            result.push(value);
        }

        return result;
    }

    handleChildProcess = async(e) => {
        const { dataset } = e.target;
        const { cate, value } = dataset;
        const name = e.target.name;
        const searchData = this.stores.search.getAllData();

        let addTag = true;

        if (cate === 'area') {
            for(const item of CityList[value]){
                const check  = searchData.location.city.find(i => i === item.value);
                if(check){
                    addTag = false;
                    break;
                }
            }

            if(addTag){
                searchData.location.area = this.searchDataDuplicateCheck(searchData.location.area, value);
            }
            
            searchData.cityList = CityList[value];
            if(searchData.location.area.length < 1){
                searchData.stationList = await model.getStationList([value]);
            }else{
                searchData.stationList = await model.getStationList(searchData.location.area);
            }
        } else if (cate === 'city') {
            searchData.location.city = this.searchDataDuplicateCheck(searchData.location.city, value)
        } else if (cate === 'station') {
            searchData.stations = this.searchDataDuplicateCheck(searchData.stations, name)
        } else if (cate === 'empState') {
            searchData.empStatus = this.searchDataDuplicateCheck(searchData.empStatus, value)
        } else if (cate === 'language') {
            searchData.language = this.searchDataDuplicateCheck(searchData.language, value)
        } else if (cate === 'position') {
            searchData.position = this.searchDataDuplicateCheck(searchData.position, value)
        }

        if(addTag){
            const tagsValue = cate === 'station' ? name : value;
            searchData.tags = {
                key: this.searchDataDuplicateCheck(searchData.tags.key, name),
                value: this.searchDataDuplicateCheck(searchData.tags.value, tagsValue),
            }
        }

        this.stores.search.resetData(searchData);

    }

    renderChildArea = (ref, cate) => {
        const tags = this.stores.search.getData('tags');
        const dataList = ref || [];
        return (
            dataList.map((item, index) => {
                const active = tags.key.find(tag => tag === item.key) ? 'active' : '';
                return <Button
                    key={index}
                    className={`${active} card-btn`}
                    data-value={item.value}
                    data-cate={cate}
                    name={item.key}
                    onClick={this.handleChildProcess}
                >{item.key}</Button>
            })
        )
    }

    renderPCSearchControllPage = () => {
        const { handleSearch, listData, handleReset } = this.props;
        return (
            <Grid className="fixbtn_wrap">
                <Grid className="condition">
                    <Grid className="hit">{'選択した条件'}</Grid>
                    <Grid className="condition_list_wrap" id="condition">
                        {this.renderCardBox()}
                    </Grid>
                </Grid>

                <Grid className="hit">{'該当件数'}<Typography variant="span"> {listData.totalCnt}</Typography>{'件'}</Grid>
                <Button variant="outlined" onClick={handleReset}>{'リセット'}</Button>
                <Button variant="contained" className="" onClick={handleSearch}>{'選択した条件で検索'}</Button>
            </Grid>
        )
    }

    renderSPSearchControllPage = () => {
        const { handleSearch, handleReset } = this.props;
        return (
            <Grid container justifyContent={'center'} className="sp_fixbtn">
                <Typography variant="subtitle1">
                    <Button variant="outlined" onClick={handleReset}>{'リセット'}</Button>
                    <Button variant="contained" className="" onClick={handleSearch}><img src={IMGwhitesearch} alt=''/>{'検索する'}</Button>
                </Typography>
            </Grid>
        )
    }

    render() {
        const { classes, handleSearch, handleKeywordChange, onClose } = this.props;
        const searchData = this.stores.search.getData();
        const isMobile = CustomUtil.checkMobile();

        if(!searchData){
            return <></>
        }

        const { cityList, stationList, keyword } = searchData;

        return (
            <Grid id="mobile_aside" className="aside" classes={classes}>

                { isMobile && <div className={'btn_close close-button'} onClick={onClose}>&times;</div> }

                <Grid className="side_wrap">
                    <Grid className="side_title">
                        <Typography variant="h6">{'キーワード検索'}</Typography>
                    </Grid>

                    <Grid container className="search_bar">
                        <Input variant="outlined" label={'職種など、フリーワードで検索'} onChange={handleKeywordChange} value={keyword || ''}/>
                        <Button variant="contained" className="" onClick={handleSearch}><img src={IMGwhitesearch} alt="検索"/></Button>
                    </Grid>

                    <Grid>
                        <Grid className="card-title">{'都道府県'}</Grid>
                        <Grid container className="card-body">
                            {this.renderChildArea(LocationsList, 'area')}
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid className="card-title">{'市町村'}</Grid>
                        <Grid container className="card-body">
                            { this.renderChildArea(cityList, 'city') }
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid className="card-title">{'最寄駅'}</Grid>
                        <Grid container className="card-body">
                            { this.renderChildArea(stationList, 'station') }
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid className="card-title">{'雇用形態'}</Grid>
                        <Grid container className="card-body">
                            {this.renderChildArea(EmpStatus, 'empState')}
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid className="card-title">{'開発環境'}</Grid>
                        <Grid container className="card-body">
                            {this.renderChildArea(Language, 'language')}
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid className="card-title">{'職種'}</Grid>
                        <Grid container className="card-body">
                            {this.renderChildArea(RecruitmentType, 'position')}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className="mobile_aside_btn">
                    { isMobile ? this.renderSPSearchControllPage() : this.renderPCSearchControllPage() }
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(style)(SearchLeftBox);
