const data = {
    name: '福岡県',
    key: 'fukuoka',
    value: 'KYU10',
    area: [
        {
            key: '福岡県',
            value: 'FU000'
        },
        {
            key: "その他",
            value: "FU999"
        }
    ]
}

export default data;