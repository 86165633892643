import React from 'react';
import { Link } from 'react-router-dom';

import IMGblacklogo from '../../asset/img/black_logo.svg';

import { 
    Grid, 
    MenuList, 
    MenuItem, 
    Container,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CustomUtil from '../../model/common/CustomUtil';


// override style
const style = {
    root: {
        width: '100%'
    }
}

class CommFooter extends React.Component {
    handleMoveHome = () => {
        const { pathname } = window.location;
        if (pathname === '/') {
            CustomUtil.scrollTarget()
        }
    }

    render() {
        const { classes } = this.props;
        return (
                <Grid classes={classes}>
                {/* <Grid className="foot_navi">
                <Container>
                    <Typography variant="h5">おすすめの求人・案件一覧</Typography>
                    <MenuList>
                        <MenuItem>
                            <Link to="/search">
                                <img src={IMGwhitetag} alt="-"/>
                                {'Javaの求人・案件'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/search">
                                <img src={IMGwhitetag} alt="-"/>
                                {'PHPの求人・案件'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/search">
                                <img src={IMGwhitetag} alt="-"/>
                                {'Rubyの求人・案件'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/search">
                                <img src={IMGwhitetag} alt="-"/>
                                {'Pythonの求人・案件'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/search'>
                                <img src={IMGwhitetag} alt="-"/>
                                {'Scalaの求人・案件'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/policy'>
                                <img src={IMGwhitetag} alt="-"/>
                                {'JavaScriptの求人・案件'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/search'>
                                <img src={IMGwhitetag} alt="-"/>
                                {'Swiftの求人・案件'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/search'>
                                <img src={IMGwhitetag} alt="-"/>
                                {'C#の求人・案件'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/search'>
                                <img src={IMGwhitetag} alt="-"/>
                                {'C++の求人・案件'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/search'>
                                <img src={IMGwhitetag} alt="-"/>
                                {'VBAの求人・案件'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/search'>
                                <img src={IMGwhitetag} alt="-"/>
                                {'AWSの求人・案件'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/search'>
                                <img src={IMGwhitetag} alt="-"/>
                                {'PMOの求人・案件'}
                            </Link>
                        </MenuItem>
                    </MenuList>
                    <hr/>
                    <Typography variant="h5">ABOUT</Typography>
                    <MenuList>
                        <MenuItem>
                            <Link to="/search">
                                <img src={IMGwhitelink} alt="-"/>
                                {'案件を探す'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/siteinfo">
                                <img src={IMGwhitelink} alt="-"/>
                                {'サービス紹介'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <img src={IMGwhitelink} alt="-"/>
                            <a href="https://www.minosys.co.jp/companyInfo#ci2" target="_blank" rel="noopener noreferrer">{'運営会社'}</a>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/agreement'>
                                <img src={IMGwhitelink} alt="-"/>
                                {'利用規約'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/policy'>
                                <img src={IMGwhitelink} alt="-"/>
                                {'個人情報の取扱い'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/faq">
                                <img src={IMGwhitelink} alt="-"/>
                                {'ヘルプ'}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/sitemap'>
                                <img src={IMGwhitelink} alt="-"/>
                                {'サイトマップ'}
                            </Link>
                        </MenuItem>
                    </MenuList>
                </Container>
            </Grid> */}

            <footer id="about" >
                <Container>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <MenuList>
                                <MenuItem>
                                    <Link to="/search">{'案件を探す'}</Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link to="/siteinfo">{'サービス紹介'}</Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link to="/faq">{'よくある質問'}</Link>
                                </MenuItem>
                                {/* <MenuItem>
                                    <Link to='/sitemap'>{'サイトマップ'}</Link>
                                </MenuItem> */}
                                <MenuItem>
                                    <a href="https://www.minosys.co.jp/companyInfo#ci2" target="_blank" rel="noopener noreferrer">{'運営会社'}</a>
                                </MenuItem>
                                <MenuItem>
                                    <Link to='/inquiry'>{'お問い合わせ'}</Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link to='/agreement'>{'利用規約'}</Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link to='/policy'>{'個人情報保護方針'}</Link>
                                </MenuItem>
                            </MenuList>


                            <Grid className='footerLogo'>
                                <Link to='/' onClick={this.handleMoveHome}><img src={IMGblacklogo} alt="MINONAVI" /></Link>
                            </Grid>
                            <center>
                                <small>&copy; {'MINOSYS Co.,Ltd.'}</small>
                            </center>
                        </Grid>
                    </Grid>
                </Container>
            </footer>
            </Grid>
        );
    }
}


export default withStyles(style)(CommFooter);
