import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";

class UserDetl {
    getData = async (formDate) => {
        const params = CustomUtil.getAxiosFormData(formDate);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.detl, params, headers)
    }

    dataProcess = (obj) => {
        obj.last_education_status = CustomUtil.getGenerationStatus(obj.last_education_status);
        obj.now_work_kind = CustomUtil.getNowWorkKind(obj.now_work_kind);
        obj.work_history = CustomUtil.getWorkHistory(obj.work_history);
        obj.getNowWorkKind = CustomUtil.getNowWorkKind(obj.getNowWorkKind);
        obj.need_work_location = CustomUtil.getNeedWorkLocation(obj.need_work_location);
        obj.use_language = CustomUtil.getLanguage(obj.use_language);
        obj.gender = CustomUtil.getGender(obj.gender);
        return obj;
    }

    userVan = (email) => {
        const data = {
            email : email
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.userVan, params, headers)
    }

    exitMember = (email) => {
        const data = {
            email : email
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.exitMember, params, headers)
    }

    managerStatus = (email, status) => {
        const data = {
            email : email,
            status : status
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.setGrant, params, headers)
    }

    restoreMember = (email) => {
        const data = {
            email : email
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.restore, params, headers)
    }
}

export default new UserDetl();