import React from 'react';
import model from '../../model/manager/AgendaDetl';
import util from '../../model/common/CustomUtil';
import { inject, observer } from 'mobx-react';
import Auth from '../../model/common/Auth';
import BackDrop from '../../component/common/BackDrop';
import CustomModal from '../../component/common/CustomModal';
import msg from '../../constants/Message';
import ApplicantList from '../../component/manager/ApplicantList';

import {
    Button,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
        '& .ybtn': {
            marginLeft: '5px !important',
            marginRight: '5px !important'
        }
    }
}

@inject('stores')
@observer
class AgendaDetl extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            backDropView: false,
            showModal: false,
            modalMsg: null,
            confirmView: false,
            list: null,
        }
    
        this.store = this.props.stores.form;
    }

    getAgendaDetlData = async(agendaIndex) => {
        const detlData = await model.getDetlData(agendaIndex);
        this.setState({
            list: detlData,
        });
    }

    componentDidMount = () => {
        const agendaIndex = this.store.getData('agendaIndex');
        this.getAgendaDetlData(agendaIndex);
    }

    checkConfirm = () => {
        this.setState({
            confirmView: true
        })
    }

    handleConfirmOK = () => {
        this.confirmClose();
        this.exiteMember();
    }

    exiteMember = () => {
        const { list } = this.state

        this.setState({
            backDropView: true,
            showModal: false,
        })

        if (list.del_flag === "N") {
            model.deleteData(list.idx).then((res) => {
                alert(res.data.message)
                window.location.reload();
            })
        } else {
            model.restoreData(list.idx).then((res) => {
                alert(res.data.message)
                window.location.reload();
            })
        }
    }

    confirmClose = () => {
        this.setState({
            confirmView: false
        })
    }

    modalClose = () => {
        window.location.href = '/mg/agendaList';
    }

    changeRecruitmentStatus = (e) => {
        const { idx } = this.state.list;
        model.changeRecruitmentType({
            agendaIdx: idx,
            recruitmentType: e.target.dataset.value
        }).then(res => {
            if (res.data.status === "success") {
                this.getAgendaDetlData(idx);
            }
            alert(res.data.message);
        })
    }

    renderAliveAgendaButtons = () => {
        const { list } = this.state;
        const { nextPageFnc } = this.props;
        return (
            <Grid item>
                <Button className="ybtn" onClick={() => { nextPageFnc('modify') }}>{'修正'}</Button>
                <Button className="ybtn" name="delete" onClick={this.checkConfirm}>{'削除'}</Button>
                {
                    list.statusKey === '10' &&
                        <>
                            <Button className="ybtn" data-value={20} onClick={this.changeRecruitmentStatus}>{'募集保留'}</Button>
                            <Button className="ybtn" data-value={30} onClick={this.changeRecruitmentStatus}>{'充足'}</Button>
                        </>
                }
                {
                    list.statusKey === '20' &&
                        <Button className="ybtn" data-value={10} onClick={this.changeRecruitmentStatus}>{'募集再開'}</Button>
                }
                {
                    list.statusKey === '30' &&
                        <Button className="ybtn" data-value={10} onClick={this.changeRecruitmentStatus}>{'募集再開'}</Button>
                }
            </Grid>
        )
    }

    renderDeadedAgendaButtons = () => {
        return (
            <Grid item>
                <Button className="ybtn" name="restore" onClick={this.checkConfirm}>{'復旧'}</Button>
            </Grid>
        )
    }

    render() {
        const { list } = this.state;
        const { onClose, classes } = this.props;
        let confirmText = "";
        if (Auth.checkAdmin()) {
            window.location.href = '/';
            return (<></>);
        }

        if(!list){
            return <></>
        }

        if (list !== null) {
            confirmText = (list.del_flag === "N" ? 'この案件を削除' : 'この案件を復旧');
        }

        const confirm = {
            title: msg.confirm.replace('[A]', confirmText),
            ok: {
                msg: '確認',
                fnc: this.handleConfirmOK
            },
            cancel: {
                msg: '取り消し',
                fnc: this.confirmClose
            }
        }

        return (
            <Grid classes={classes}>
                <Grid className="content mt_none bg_gray">
                    <div className={'btn_close close-button'} onClick={onClose}>&times;</div>
                    <Grid className="mb_30">
                        <Typography variant="h6" className="form_name">{'案件詳細'}</Typography>
                    </Grid>
                    <Grid className="info_card column-shadow2">
                        <Grid container justifyContent={'left'}>
                            <Typography variant="h6" className="mb_10">{list.title}</Typography>
                        </Grid>
                        <Table className={'agenda-detl-table'}>
                            <TableBody>
                                <TableRow>
                                    <TableCell component={'th'}>{'案件番号'}</TableCell>
                                    <TableCell>{list.agenda_number}</TableCell>
                                    <TableCell component={'th'}>{'案件担当'}</TableCell>
                                    <TableCell>{`${list.assign.name_sei}　${list.assign.name_mei}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={'th'}>{'雇用形態'}</TableCell>
                                    <TableCell>{list.emp_status}</TableCell>
                                    <TableCell component={'th'}>{'開発言語'}</TableCell>
                                    <TableCell>{list.language}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={'th'}>{'募集職種'}</TableCell>
                                    <TableCell>{list.recruitment_type}</TableCell>
                                    <TableCell component={'th'}>{'データベース'}</TableCell>
                                    <TableCell>{list.db}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={'th'}>{'勤務地'}</TableCell>
                                    <TableCell>
                                        { list.workLocations.map((item, index )=> <Typography key={index}>{item}</Typography>) }
                                    </TableCell>
                                    <TableCell component={'th'}>{'フレームワーク'}</TableCell>
                                    <TableCell>{list.fw}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={'th'}>{'契約金額'}</TableCell>
                                    <TableCell>{`～ ${list.pay} 万円/月`}</TableCell>
                                    <TableCell component={'th'}>{'案件状態'}</TableCell>
                                    <TableCell>{list.status}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={'th'}>{'作業開始日'}</TableCell>
                                    <TableCell>{`${list.job_start_date} ～`}</TableCell>
                                    <TableCell component={'th'}>{'案件クリック数'}</TableCell>
                                    <TableCell>{list.hitCnt}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={'th'}>{'こだわり'}</TableCell>
                                    <TableCell colSpan={3}>{list.hitch}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        
                        <Grid container className={'contents-frame'}>
                            <Typography className={'agenda-detl-title'}>{'詳細内容'}</Typography>
                            <Divider/>
                            <Grid container className={'agenda-detl-contents'}> {util.getHtmlContents(list.contents)} </Grid>
                        </Grid>

                        <Grid container justifyContent={'center'}>
                        {
                            list.del_flag === 'N' ? this.renderAliveAgendaButtons() : this.renderDeadedAgendaButtons()
                        }
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'center'} className={'agenda-applicant-frmae'}>
                    <Grid className="mb_30 mt_30">
                        <Typography variant="h6" className="form_name">{'応募状況'}</Typography>
                    </Grid>
                        <ApplicantList className={'agenda-detl-applicant-list'} agendaIdx={list.idx} />
                    </Grid>
                    
                </Grid>
                <BackDrop open={this.state.backDropView} />
                <CustomModal message={this.state.modalMsg} onClose={this.modalClose} open={this.state.showModal} />
                <CustomModal open={this.state.confirmView} onClose={this.confirmClose} confirm={confirm} />
            </Grid>
        );
    }
}

export default withStyles(style)(AgendaDetl);