import React from 'react';
import CustomUtil from '../../model/common/CustomUtil';
import Yen from '../../asset/img/yen.svg';
import Pin from '../../asset/img/pin.svg';
import Code from '../../asset/img/code.svg';
import Job from '../../asset/img/job.svg';

import {
    Divider,
    Grid,
    Typography
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
        margin: '0.5rem',
        cursor: 'pointer',
        '& .main-frame': {
            width: '270px',
            padding: '1rem 1.5rem',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #B1B4E3',
            borderRadius: '30px',
            '&:hover': {
                background: '#d0f2ff99'
            },
            '& .badge-frame': {
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                height : '52px',
                overflow : 'hidden',
                '& .badge-icon': {
                    fontSize: '0.8rem',
                    border: '1px solid #88888888',
                    padding: '0.2rem 0.5rem',
                    color: '#888888',
                    marginRight: '0.5rem',
                    marginTop: '0.5rem',
                },
                '& .new-arrival': {
                    background: '#006DFF',
                    color: '#FFFFFF',
                    border : 'none'
                }
            },
            '& .title-frame': {
                paddingTop: '0.5rem',
                height: '100px',
                '& .agenda-title':{
                    fontWeight:'bold',
                    fontSize:'1.2rem',
                    whiteSpace:'normal',
                    display:'-webkit-box;',
                    lineClamp:'3',
                    boxOrient:'vertical',
                    overflow:'hidden', 
                }
            },
            '& .icon-frame': {
                marginTop: '0.5rem',
                '& .items': {
                    paddingRight: '1rem',
                    width:'calc(100% - 62px)',
                    wordBreak:'keep-all',
                    whiteSpace:'nowrap',
                    overflow:'hidden',
                    textOverflow:'ellipsis'
                },
                '& .icon-container': {
                    alignItems: 'center',
                    marginBottom: '0.5rem',
                    flexWrap:'nowrap',
                },
                '& .icons': {
                    marginRight: '12px'
                },
                '& .pay': {
                    paddingRight: '0.4rem',
                    whiteSpace : 'nowrap',
                    '&:nth-child(3)': {
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize:'1.4rem'
                    }
                }
            }, 
            '& .hr':{
                marginBottom: '0.5rem'
            }
        },
        '& .contents-frame': {
            '& th, td': {
                borderTop: '1px solid rgba(224, 224, 224, 1)'
            },
            '& th': {
                background: '#EFEFEF',
            }
        },
        '& .button-frame': {
            '& .btn': {
                border: '1px solid #AAAAAA',
            },
            '& .join': {
                background: '#00BAFF',
                color: '#FFFFFF',
                width: '75%'
            },
            '& .favorites': {
                color: '#888888',
                marginLeft: '2%',
                width: '15%',
                '& .star-added': {
                    color: 'blue',
                }
            }
        }, 
        '@media (max-width : 768px)': {
            marginLeft: 0,
            marginRight: 0,
            width: '100%',
            '& .main-frame': {
                width: '100%',
                height: 'auto',
                '& .badge-frame': {

                    '& .badge-icon': {
                        marginTop: '0.5rem'
                    },
                },
                '& .title-frame': {
                    height: 'auto !important'
                },
            }
        }
    }
}

class MainAgendaCard extends React.Component {
    constructor(props){
        super(props);
        this.isMobile = CustomUtil.checkMobile();
    }

    renderBadgeIcons = (data) => {
        const badges = CustomUtil.getNewArrivalBadgeIcon(data);
        const hitch = CustomUtil.explodeData(',', data.hitch);

        for(const item of hitch){
            badges.push({
                isNewArrival: false, 
                text: item
            })

            if(!this.isMobile){
                if(badges.length > 1){
                    break;
                }
            }
        }

        return badges
    }

    render() {
        const { classes, data, showModalFnc } = this.props;
        const badges = this.renderBadgeIcons(data);
        const pay = parseInt(data.pay).toLocaleString()
        return (
            <Grid classes={classes} onClick={() => showModalFnc(data.idx)}>
                <Grid container justifyContent={'center'} className={'searchCardPC'}>
                    <Grid item className={'main-frame'}>
                        <Grid className={'title-frame'}>
                            <Typography className="agenda-title">{data.title}</Typography>
                        </Grid>

                        <Grid className={'icon-frame'}>
                            <Grid container className={'icon-container'}>
                                <img src={Yen} alt='' className="icons"/>
                                <Typography className={'pay'}>{`〜`}</Typography>
                                <Typography className={'pay'}>{pay}</Typography>
                                <Typography className={'pay'}>{`万円/月`}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent={'left'} className={'badge-frame'}>
                            {
                                badges.map((item, key) => {
                                    return <Typography key={key} className={`badge-icon ${item.isNewArrival ? 'new-arrival' : ''}`}>{item.text}</Typography>
                                })
                            }
                        </Grid>

                        <Divider className={'hr'}/>

                        <Grid className={'icon-frame'}>
                            <Grid container className={'icon-container'}>
                                <img src={Pin} alt='' className="icons"/>
                                <Typography className={'items location'}>{data.location}</Typography>
                            </Grid>
                            <Grid container className={'icon-container'}>
                                <img src={Code} alt='' className="icons"/>
                                <Typography className={'items req-type'}>{data.language}</Typography>
                            </Grid>
                            <Grid container className={'icon-container'}>
                                <img src={Job} alt='' className="icons"/>
                                <Typography className={'items req-type'}>{data.emp_status}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(MainAgendaCard);
