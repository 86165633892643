import React from 'react';
import { inject, observer } from 'mobx-react';
import model from '../../model/manager/UserDetl';
import Validation from '../../model/common/Validation';
import Auth from '../../model/common/Auth';
import CustomModal from '../../component/common/CustomModal';
import CustomUtil from '../../model/common/CustomUtil';
import ApiUrl from '../../constants/ApiUrl';
import MailForm from './MailForm';
import MemberEvaluation from './MemberEvaluation';

import {
    Grid,
    Button,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TableHead,
    Backdrop,
    Switch,
    Divider,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import { runInAction } from 'mobx';

// override style
const style = {
    root: {
        '& button': {
            margin: '0.5rem'
        },
        '& .infomation': {
            backgroundColor: 'rgba(0,0,0,0.8)',
            '& > *': {
                color: 'white',
                textAlign: 'center'
            }
        },
        '& .contents-table': {
            borderTop : '2px solid rgba(224, 224, 224, 1);',
            borderBottom : '2px solid rgba(224, 224, 224, 1);',
            '& .headers': {
                backgroundColor: '#EFEFEF',
                textAlign: 'center'
            },
        },
        '& .custom-switch' : {
            zoom: 1.2,
        }
    }
};

@inject('stores')
@observer
class UserDetl extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            backDropView: false,
            modalView: false,
            modalMsg: null,
        }
    
        this.store = this.props.stores.form;
        this.login = this.props.stores.login;
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const userIdx = this.store.getData('userIdx') || this.props.userIdx;

        model.getData({userIdx}).then((res) => {
            res.data = model.dataProcess(res.data);
            this.setState({
                data: { ...res.data }
            });

            runInAction(() => {
                this.store.data.name = res.data.name;
                this.store.data.nameKana = res.data.nameKana;
            });
        });
    }

    showModal = (msg) => {
        this.setState({
            modalView: true,
            modalMsg: msg
        });
    }

    closeModal = () => {
        this.setState({
            modalView: false,
        });
    }

    backDropSwitch = (bool) => {
        this.setState({
            backDropView: bool
        })
    }

    userVan = () => {
        this.setState({
            backDropView: true
        });
        const { data } = this.state;

        model.userVan(data.email).then((res) => {
            this.setState({
                backDropView: false
            });

            alert(res.data.message)

            data.del_flag = 'Y';
            this.setState({
                data: data
            })
        });
    }

    restoreMember = () => {
        this.setState({
            backDropView: true
        });

        const { data } = this.state;

        model.restoreMember(data.email).then((res) => {
            this.setState({
                backDropView: false
            });

            alert(res.data.message)

            data.del_flag = 'N';
            this.setState({
                data: data
            })
        });
    }

    renderEmptyFile = () => {
        return (
            <Typography variant="p" color={'#FF3636'}>{'ファイルが存在しません。'}</Typography>
        )
    }

    renderFileList = () => {
        const { files } = this.state.data;
        return (
            <>
                {
                    files.map((item, index) => {
                        const downloadUrl = `${ApiUrl.fileDown}/${item.hash}`;
                        return (
                            <Button key={index} href={downloadUrl}>
                                {CustomUtil.getFileName(item.kind)}
                            </Button>
                        )
                    })
                }
            </>
        )
    }

    handleStatusSwitch = (e) => {
        const { data } = this.state;

        var status = 'N';

        if (e.target.checked) {
            status = 'Y';
        } else {
            status = 'N';
        }

        model.managerStatus(data.email, status).then((res) => {
            this.setState({
                data: data
            })
        });
    }

    renderMembershipManagement = () => {
        const { data } = this.state;
        return (
            <>
                {
                    data.del_flag === 'Y' ?
                        <Button variant="contained" color="secondary" onClick={this.restoreMember}>{'会員資格一時停止解除'}</Button> :
                        <Button variant="contained" color="secondary" onClick={this.userVan}>{'会員資格一時停止'}</Button>
                }
            </>
        )
    }

    render() {
        if (Auth.checkAdmin()) {
            window.location.href = '/';
            return (<></>);
        }

        const { data } = this.state;
        const { classes, browse, onClose } = this.props;
        const isAdmin = this.login.getData('is_admin');

        if(!data){
            return <></>;
        }

        return (
            <Grid className={`content mt_none bg_gray`} classes={classes}>
                { !browse && <div className={'btn_close close-button'} onClick={onClose}>&times;</div> }
                <Grid>
                    <Typography variant="h6" className="form_name mb_30">{'応募者情報'}</Typography>
                </Grid>

                <Grid className="column-shadow2">
                    <Table className={'contents-table'}>
                        {
                            data.del_flag === 'Y' &&
                            <TableHead>
                                <TableRow>
                                    <TableCell className={'infomation'} colSpan={4}>
                                        <Typography variant="h4">{'停止ユーザー'}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        }
                        <TableBody>
                            <TableRow>
                                <TableCell className={'headers'}>{'氏名'}</TableCell>
                                <TableCell>{`${data.name}(${data.nameKana})`}</TableCell>
                                <TableCell className={'headers'}>{'Email'}</TableCell>
                                <TableCell>{data.email}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={'headers'}>{'性別'}</TableCell>
                                <TableCell>{data.gender}</TableCell>
                                <TableCell className={'headers'}>{'生年月日'}</TableCell>
                                <TableCell>{data.birthday}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={'headers'}>{'電話番号'}</TableCell>
                                <TableCell>{data.phone}</TableCell>
                                <TableCell className={'headers'}>{'最終学歴'}</TableCell>
                                <TableCell>{`${data.last_education_name} (${data.last_education_grade}年、${data.last_education_status})`}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={'headers'}>{'国籍'}</TableCell>
                                <TableCell>{data.nationality}</TableCell>
                                <TableCell className={'headers'}>{'IT経歴年数'}</TableCell>
                                <TableCell>{data.work_history}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={'headers'}>{'勤務希望地'}</TableCell>
                                <TableCell>{data.need_work_location}</TableCell>
                                <TableCell className={'headers'}>{'今の雇用形態'}</TableCell>
                                <TableCell>{data.now_work_kind}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={'headers'}>{'住所'}</TableCell>
                                <TableCell colSpan={3}>{data.now_address}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={'headers'}>{'開発言語'}</TableCell>
                                <TableCell colSpan={3}>{data.use_language}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={'headers'}>{'添付ファイル'}</TableCell>
                                <TableCell colSpan={!browse ? 1 : 3}>
                                    {
                                        Validation.empty(data.files) ?
                                            this.renderEmptyFile() : this.renderFileList()
                                    }
                                </TableCell>
                                {
                                    !browse &&
                                    <>
                                        <TableCell className={'headers'}>{'管理者権限'}</TableCell>
                                        <TableCell>
                                            <Switch defaultChecked={data.is_admin === "Y"} onChange={this.handleStatusSwitch} className={'custom-switch'} />
                                        </TableCell>
                                    </>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {
                        isAdmin === 'Y' &&
                        <>
                            <MemberEvaluation userIdx={data.idx} />
                            <Divider/>
                            <MailForm email={data.email} backDropSwitch={this.backDropSwitch}/>
                        </>
                    }
                    
                </Grid>

                <Grid container direction="row" className="mt_30" justifyContent={'center'}>
                    { 
                        !browse && this.renderMembershipManagement() 
                    }
                </Grid>
                <Backdrop open={this.state.backDropView} />
            </Grid>
        );
    }
}

export default withStyles(style)(UserDetl);