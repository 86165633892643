import React from 'react';
import { inject, observer } from "mobx-react";
import registerModel from '../../model/manager/AgendaRegister';
import Language from '../../constants/Language';
import NeedWorkLocation from '../../constants/NeedWorkLocation';
import AgendaRecruitmentStatus from '../../constants/AgendaRecruitmentStatus';
import CustomUtil from '../../model/common/CustomUtil';

import IMGwhiteclose from '../../asset/img/white_close.svg';
import IMGwhitesearch from '../../asset/img/white_search.svg';

import {
    Grid,
    Button,
    MenuItem,
    Select,
    TextField,
    InputLabel,
    FormControl,
    Box
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
    }
}

@inject('stores')
@observer
class AgendaListSesrchBox extends React.Component {
    constructor(props) {
        super(props);
        this.stores = props.stores;
        this.state = {
            adminList: []
        }
    }

    componentDidMount = async() => {
        const adminList = await registerModel.getAssignList();
        this.setState({
            adminList : adminList
        })
    }

    renderSelectBox = (name, refData, key, className) => {
        const value = this.stores.search.getData(key) || '';

        return (
            <Box>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                    <InputLabel id="demo-simple-select-label" > {name}</InputLabel>
                    <Select
                        id="grouped-native-select"
                        label={name}
                        value={value || ''}
                        onChange={this.handleSelectbox}
                        name={key}
                        className={className}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            refData.map((item, index) => (
                                <MenuItem key={index} value={item.value}><em>{item.key}</em></MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
        )
    }

    renderInputBox = (name, key) => {
        const value = this.stores.search.getData(key) || '';
        return (
            <TextField
                id="outlined-basic"
                label={name}
                variant="outlined"
                value={value || ''}
                name={key}
                onChange={this.handleSelectbox}
            />
        );
    };

    handleSelectbox = (e) => {
        const { name, value } = e.target;
        this.stores.search.setData(name, value);

        const searchData = this.stores.search.getData();
        CustomUtil.setSessionItem('search', CustomUtil.encrypt(JSON.stringify(searchData)));
    }

    handleSearchReset = () => {
        const { handleSearch } = this.props;
        CustomUtil.delSessionItem('search');
        this.stores.search.resetData({});
        handleSearch();
    }

    render() {
        const { showRegisterFnc, handleSearch, classes } = this.props;
        const { adminList } = this.state;

        return (
            <Grid classes={classes} className="search_wrap mb_30">
                {this.renderInputBox('案件番号', 'agendaNum')}
                {this.renderSelectBox("言語", Language, "language", "search-language")}
                {this.renderSelectBox("勤務地", NeedWorkLocation, "location", "search-location")}
                {this.renderSelectBox("担当", adminList, "assign", "search-assign")}
                {this.renderSelectBox("状態", AgendaRecruitmentStatus, "agendaStatus", "search-status")}

                <Grid className="search_btn">
                    <Button className="list_btn" onClick={handleSearch}><img src={IMGwhitesearch} alt="" />{'検索'}</Button>
                    <Button className="list_btn" onClick={this.handleSearchReset}><img src={IMGwhiteclose} alt="" />{'条件クリア'}</Button>
                    <Button className="list_btn" onClick={showRegisterFnc}>{'案件登録'}</Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(AgendaListSesrchBox);
