// こだわり
const data = [
    {
        key: 'アジャイル',
        value: '10'
    },
    {
        key: 'ウォーターフォール',
        value: '20'
    },
    {
        key: '長期案件',
        value: '30'
    },
    {
        key: 'リモートOK',
        value: '40'
    },
    {
        key: '服装自由',
        value: '50'
    },
    {
        key: '未経験者歓迎',
        value: '60'
    },
    {
        key: '外国籍可',
        value: '70'
    },
    {
        key: '外国籍不可',
        value: '80'
    },
    {
        key: '外国籍相談可',
        value: '90'
    },
    {
        key: '稼働安定',
        value: '100'
    },
    {
        key: '現場常駐',
        value: '110'
    },
    {
        key: 'BtoB向け',
        value: '120'
    },
    {
        key: '新技術に積極的',
        value: '130'
    },
    {
        key: 'リーダー経験を活かす',
        value: '140'
    },
    {
        key: '20代活躍中',
        value: '150'
    },
    {
        key: '30代活躍中',
        value: '160'
    },
    {
        key: '40代活躍中',
        value: '170'
    },
    {
        key: '急募',
        value: '180'
    },
    {
        key: '上流工程の仕事',
        value: '190'
    },
    {
        key: 'オフショア開発',
        value: '200'
    },
    {
        key: '実務経験が浅い方OK',
        value: '210'
    },
    {
        key: 'ゲーム好き歓迎',
        value: '220'
    },
    {
        key: '小規模チーム体制',
        value: '230'
    },
    {
        key: '新規立ち上げ',
        value: '240'
    },
    {
        key: 'エンド直',
        value: '250'
    },
    {
        key: '駅近く',
        value: '260'
    },
    {
        key: '参画実績あり',
        value: '270'
    },
]

export default data;
