import React from 'react';
import { CKEditor } from 'ckeditor4-react';


import { withStyles } from '@mui/styles';

// override style
const style = {}

/**
 *  const data = e.editor.getData();
 *  e.editor.setData('');
 *  onChange    ( e ) => { }
 *  onBlur      ( e ) => { }
 *  onFocus     ( e ) => { }
 */

class Editor extends React.Component {
    render() {
        const { initData, onChange, onBlur, onFocus, config, name } = this.props;
        return (
            <CKEditor
                name={name}
                initData={initData}
                config={config}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
            />
        );
    }
}

export default withStyles(style)(Editor);

