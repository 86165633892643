import React from 'react';
import { inject, observer } from 'mobx-react';
import model from '../../model/user/UserModify';
import ApiUrl from '../../constants/ApiUrl';
import { withStyles } from '@mui/styles';
import {
    Grid,
    Typography,
    Button,
} from '@mui/material';

// override style
const style = {}

@inject('stores')
@observer
class FormFileUpload extends React.Component {
    constructor(props){
        super(props);
        this.stores = props.stores;
    }

    renderFileForm = (name) => {
        const fileList = this.stores.form.getData('files');
        const fileData = this.stores.form.getData(name);
        let downloadUrl = '';
        let hash = '';
        const fileName = fileData ? fileData.name : '';

        if (fileList) {
            for (const item of Object.keys(fileList)) {
                const file = fileList[item];
                if (file.kind === name) {
                    downloadUrl += `${ApiUrl.fileDown}/${file.hash}`;;
                    hash = file.hash;
                }
            }
        }

        return (
            <Grid item md={8} className="form_input_cell">
                <Button
                    name={name}
                    onChange={this.handleFileControl}
                    variant="contained"
                    component="label"
                    >
                    {'ファイルアップロード'}
                    <input type={'file'} hidden name={name}/>
                </Button>
                <Typography>{fileName}</Typography>
                {
                    downloadUrl &&
                    <>
                        <Button variant={'outlined'} href={downloadUrl} target='_blank'>{'ダウンロード'}</Button>
                        <Button variant={'outlined'} onClick={() => this.handleFileDelete(hash) } color={'error'}>{'削除'}</Button>
                    </>

                }
            </Grid>
        )
    }

    handleFileDelete = async(hash) => {
        const formData = {
            hash : hash
        }
        await model.deleteFile(formData).then(res => {
            alert(res.data.message);
            window.location.reload()
        })
    }

    handleFileControl = (e) => {
        const fileData = e.target.files[0] ? e.target.files[0] : '';
        const name = e.target.name;

        this.stores.form.setData(name, fileData);
    }

    renderMemberFileForms = () => {
        return (
            <>
                <Grid item md={12} className="fileupload" container direction="row" alignItems="center">
                    <Grid item md={4} className="form_title_cell">{`履歴書　(任意)`}</Grid>
                    {this.renderFileForm('resume')}
                </Grid>
                <Grid item md={12} className="fileupload" container direction="row" alignItems="center">
                    <Grid item md={4} className="form_title_cell">{`職務経歴書（スキルシート）　(任意)`}</Grid>
                    {this.renderFileForm('career')}
                </Grid>
                <Grid item md={12} className="fileupload" container direction="row" alignItems="center">
                    <Grid item md={4} className="form_title_cell">{`ポートフォリオ　(任意)`}</Grid>
                    {this.renderFileForm('portfolio')}
                </Grid>
            </>
        )
    }

    renderPhoto = () => {
        return (
            <Grid item md={12} className="fileupload" container direction="row" alignItems="center">
                <Grid item md={4} className="form_title_cell">{'担当者写真'}</Grid>
                {this.renderFileForm('managerPhort')}
            </Grid>
        )
    }

    render() {
        const isAdmin = this.stores.login.getData('is_admin');
        return (
            <>
                <Grid item xs={12}>
                    <center>
                        <Typography variant="h2" className="content_title mt_80">{'ファイルアップロード'}</Typography>
                    </center>
                </Grid>
                <Grid item xs={12} className="column-shadow">
                    <Grid container direction="row" className="form">
                        {
                            isAdmin === 'Y' ? this.renderPhoto() : this.renderMemberFileForms()
                        }
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withStyles(style)(FormFileUpload);