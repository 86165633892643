import { action, autorun, makeObservable, observable } from "mobx"
import CustomUtil from "../model/common/CustomUtil";

class Search {
  @observable data = null;

  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @action
  initData = autorun(() => {
    try {
      const session = CustomUtil.getSessionItem('search');
      if (session) {
        this.data = JSON.parse(CustomUtil.decrypt(session))
      }
    } catch (err) {
      console.log('Search store', err);
    }
  })

  @action
  setData = (key, value) => {
    this.data[key] = value;
  }

  @action
  resetData = (obj) => {
    this.data = obj;
  }

  getData = (key) => {
    try {
      return key ? this.data[key] : this.data;
    } catch (e) {
      return '';
    }
  }

  getAllData = () => {
    const tmp = Object.entries({ ...this.data });
    const rep = {}
    tmp.forEach((v) => {
      if (v[1] !== '') {
        rep[v[0]] = v[1];
      }
    })

    return rep;
  }
}

export default Search;
