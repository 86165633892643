import React from 'react';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import CustomUtil from '../../model/common/CustomUtil'

import {
    Grid,
    Typography,
    Container,
    Button,

} from '@mui/material';
import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
        '@media(max-width: 900px)': {
            linkArea: {
                minWidth: '100%',
                display: 'block',
            }
        }
    }

}
// TODO :: 검색이랑 조건 맞추기
@inject('stores')
class Siteinfo extends React.Component {

    constructor(props) {
        super(props);

        this.stores = props.stores;
    }

    renderLinkList = (data, size, category) => {
        return (
            <Grid container item md={9} className="main_lang_desc">
                {
                    data.map((item, index) => (
                        <Grid item md={size} key={index} className={'linkArea'}>
                            <Button onClick={() => { this.handleSerch(item, category) }}>{item.key}</Button>
                        </Grid>
                    ))
                }
            </Grid>
        );
    }

    handleSerch = (item, category) => {
    }

    render() {
        const { classes } = this.props;
        const loginUser = this.stores.login.getData();
        CustomUtil.scrollTarget();
        return (
            <>
                <Grid style={{ position: 'relative' }} className={classes.root}>
                    <CommNav />
                    <Grid className="content">
                        <Container>
                            <Grid item xs={12}>
                                <center>
                                    <Typography variant="h2" className="content_title">{'サービス紹介'}</Typography>
                                    <Typography vartiant="p" className="content_desc">
                                        {'MINONAVI（ミノナビ）は、ITエンジニア専用の案件・求人情報サイトです。'}<br />
                                        {'ご応募から案件の紹介、専任担当によるサポートまですべて無料でご利用いただけます。'}<br />
                                    </Typography>
                                </center>
                            </Grid>

                            <Grid item xs={12} className="column-shadow2">
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                    <Grid item xs={12} className="sitemap_title"><Typography variant="span">{'ITエンジニア（個人事業主や正社員）の専門エージェント'}</Typography></Grid>
                                    <Grid item xs={12}>
                                        これまで培った経験やノウハウがあるIT専門エージェントだからこそ、ITフリーランス（個人事業主）や正社員までITエンジニアのみなさんをしっかりとサポートすることができます。<br />
                                        契約条件はもちろん、言語やポジションから案件エリアなどご希望条件を丁寧にヒヤリングし、ご希望にピッタリあった案件をご紹介いたします。
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="mt_30">&nbsp;</Grid>

                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                    <Grid item xs={12} className="sitemap_title"><Typography variant="span">{'参画後も安心のサポート体制'}</Typography></Grid>
                                    <Grid item xs={12}>
                                        プロジェクトに参画後も、業務に集中して力を発揮できるよう、専任担当がしっかりサポートいたします。<br />
                                        現場で起こった困ったことや不安、不満など「直接言いづらいこと」は専任担当へお気軽にご相談ください。<br />
                                        また、契約条件につきましても専任担当がより良い条件で契約更新ができるよう交渉を行いますので、ご安心しておまかせください。
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12}>
                                <center>
                                    <Typography variant="h2" className="content_title mt_80">{'ご利用の流れ'}</Typography>
                                    <Typography vartiant="p" className="content_desc">
                                        {'ご応募までの希望案件・求人のご紹介、面談、ご成約、参画後のサポートの流れをご紹介します。'}
                                    </Typography>
                                </center>
                            </Grid>

                            <Grid item xs={12} className="column-shadow2">
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                    <Grid item xs={12} className="sitemap_title"><Typography variant="span">{'＜Step1＞　まず、無料会員登録！'}</Typography></Grid>
                                    <Grid item xs={12}>
                                        ミノナビのサービスはすべて無料でご利用いただけます。<br />
                                        まずは、簡単にご応募ください。ご応募後、1営業日を目安に専任担当よりご連絡差し上げます。<br />
                                        契約形態（業務委託(フリーランス)・契約社員・正社員）に関係なくお気軽にご応募・ご相談ください。

                                    </Grid>
                                    <Grid item xs={12} className="mt_30">&nbsp;</Grid>

                                    <Grid item xs={12} className="sitemap_title"><Typography variant="span">{'＜Step２＞　専任担当とのカウンセリング面談／案件申し込み'}</Typography></Grid>
                                    <Grid item xs={12}>
                                        経験豊富な専任担当が、カウンセリング面談にてご経歴やご希望を詳しくお伺いいたします。<br />
                                        電話やオンライン面談の対応も可能です。<br />
                                        いただいた条件などを元に、ピッタリあった案件を選出してご紹介いたします。<br />
                                        また、スキルシートの書き方やクライアント面談時のポイントやコツなどのアドバイスも行っております。
                                    </Grid>
                                    <Grid item xs={12} className="mt_30">&nbsp;</Grid>

                                    <Grid item xs={12} className="sitemap_title"><Typography variant="span">{'＜Step３＞　クライアントとの面談'}</Typography></Grid>
                                    <Grid item xs={12}>
                                        専任担当があなたのアピールポイントを丁寧にクライアントにお伝えし、面談の日程調整を行います。<br />
                                        条件交渉につきましても、専任担当が行いますので、ご希望の条件（契約金額・参画時期）などご遠慮なくお伝えください。<br />
                                        もちろん面談にも専任担当が同席し、フォローいたします。
                                    </Grid>
                                    <Grid item xs={12} className="mt_30">&nbsp;</Grid>

                                    <Grid item xs={12} className="sitemap_title"><Typography variant="span">{'＜Step４＞　ご成約とサポート'}</Typography></Grid>
                                    <Grid item xs={12}>
                                        クライアントとの面談後、双方の条件面での合意に至ればクライアント先と弊社による契約の締結を行い、ご成約となります。<br />
                                        ご契約書類の対応などのご不明な点につきましてもすべてご説明いたします。<br />
                                        また、プロジェクト参画後につきましても専任担当がサポートいたします。<br />
                                        応募からプロジェクトへの参画、参画後につきましても、専任担当がフォローいたしますので、是非ご安心してご利用いただければ幸いです。
                                    </Grid>
                                    <Grid item xs={12} className="mt_30">&nbsp;</Grid>

                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <center>
                                    {
                                        !loginUser && 
                                            <>
                                                <Link to="/Regist" className="ybtn">{'無料会員登録'}</Link>
                                                &nbsp;
                                            </>
                                    }
                                    &nbsp;
                                    <Link to="/Search" className="ybtn">{'案件一覧'}</Link>
                                </center>
                            </Grid>

                        </Container>
                    </Grid>
                    <CommFooter />
                </Grid>

            </>
        );
    }
}

export default withStyles(style)(Siteinfo);