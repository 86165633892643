// 雇用形態
const data = [
    {
        key: '新卒',
        value: '10',
    },
    {
        key: '中途',
        value: '20',
    },
    {
        key: '業務委託（フリーランス）',
        value: '30',
    },
];

export default data;