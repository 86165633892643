import Form from './Form';
import Login from './Login';
import Search from './Search';
import Server from './Server';
import PasswordForgot from './PasswordForgot';
import Validation from './Validation';


class Root {
  constructor() {
    this.form = new Form(this);
    this.login = new Login(this);
    this.search = new Search(this);
    this.passwordForgot = new PasswordForgot(this);
    this.server = new Server(this);
    this.validation = new Validation(this);
  }
}

export default Root;