import react from "react";
import AgendaDetl from "./AgendaDetl";
import mAuth from "../../model/common/Auth";
import { withStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { observer, inject } from "mobx-react";
import CustomUtil from "../../model/common/CustomUtil";

const styles = {
    root: {
        '& .close-button' : {
            display: 'none'
        }
    }
}

@inject('stores')
@observer
class DisplayAgendaInfo extends react.Component {
    constructor(props){
        super(props);
        this.stores = props.stores;
    }

    componentDidMount = () => {
        const { params } = this.props;
        mAuth.doAuthTokenLogin(params.authKey).then(res => {
            const { data } = res;
            if(data.status === 'success'){
                const session = CustomUtil.encrypt(JSON.stringify(res.data.data));
                CustomUtil.setSessionItem('session', session);
                this.stores.login.setAllData(data.data)
                this.stores.form.setData('agendaIndex', params.agendaIdx);
            }
        })
    }

    render() {
        const { classes } = this.props;
        const agendaIndex = this.stores.form.getData('agendaIndex');
        if(!agendaIndex){
            return  <></>
        }
        return (
            <Grid container justifyContent={'center'} classes={classes}>
                <AgendaDetl />
            </Grid>
        )
    }
}

export default withStyles(styles)((props) => <DisplayAgendaInfo params={useParams()} {...props}/>)
