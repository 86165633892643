import React from 'react';
import DataTable from '../../component/common/DataTable';
import { Pagination, Grid } from '@mui/material';
import { inject, observer } from 'mobx-react';

import Modal from './CustomModal';

import { withStyles } from '@mui/styles';

// override style
const style = {}

@inject('stores')
@observer
class DataListTable extends React.Component {
    store = this.props.stores.form;

    render() {
        const {
            xs,
            data,
            pagingFunc,
            Component,
            maxWidth,
            pageNum,
            pageView,
            nextPageFnc, 
            closeModalFnc,
        } = this.props;
        
        const modaFlg = this.store.getData('modalView');
        return (
            <Grid item xs={xs} className="dataTable">

                <DataTable {...this.props} />
                {
                    data.paging.maxPage > 1 &&
                    <Grid container direction="row" justifyContent="center" alignItems="center" className="mt_30">
                        <Pagination page={pageNum} count={data.paging.maxPage} variant="outlined" shape="rounded" color="primary" onChange={pagingFunc} />
                    </Grid>
                }

                <Modal
                    pageView={pageView}
                    Component={Component}
                    onClose={closeModalFnc}
                    open={modaFlg || false}
                    maxWidth={maxWidth}
                    nextPageFnc={nextPageFnc}
                />
            </Grid>
        );
    }
}

export default withStyles(style)(DataListTable);