import React from 'react';
import { inject, observer } from 'mobx-react';
import lodash from 'lodash';
import CityList from '../../constants/CityList';
import SearchDefaultFormData from '../../constants/SearchDefaultFormData';

import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import SearchLeftBox from '../../component/user/SearchLeftBox';
import model from '../../model/user/Search';
import mEntryDetl from '../../model/user/EntryDetl';
import CustomUtil from '../../model/common/CustomUtil';
import Validation from '../../model/common/Validation';
import Message from '../../constants/Message';

import AgendaDetl from './AgendaDetl';
import SearchCardPC from '../../component/user/SearchCardPC';
import SearchCardSP from '../../component/user/SearchCardSP';
import MobileSearchBox from './MobileSearchBox';

import {
    Grid,
    Container,
    Button,
    Typography,
    Pagination
} from '@mui/material';

import { withStyles } from '@mui/styles';
import CustomModal from '../../component/common/CustomModal';
import IMGwhitesearch from '../../asset/img/white_search.svg';
import BackDrop from '../../component/common/BackDrop';
import DataNotFound from '../../component/common/DataNotFound';
import { useParams } from 'react-router-dom';

// override style
const style = {
    root: {
        '& .content-frame': {
            paddingLeft: '40px !important',
            paddingTop: '40px !important',
            width: '100%',
            '@media (max-width : 768px)': {
                paddingLeft: '20px !important',
                paddingTop: '20px !important',
            }
        },
    }
}

@inject('stores')
@observer
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.stores = props.stores;
        this.state = {
            modalView: false,
            mobileModalView: false,
            backDropView: false,
            panelType: 'list',
            currentPage: 1
        }
    }

    componentDidMount = async () => {
        let initData = this.initData();
        const { params } = this.props;
        const searchData = this.stores.search.getAllData();
        
        if (!Validation.empty(searchData)) {
            initData.tags = searchData.tags;
            initData.language = searchData.language;
            initData.position = searchData.position;
            initData.location = searchData.location;

            const cityListKey = searchData.location.area.length > 0 ? searchData.location.area[0] : 'KAN10';
            initData.cityList = CityList[cityListKey];

            const stationListKey = searchData.location.area.length > 0 ? searchData.location.area : ['KAN10'];
            initData.stationList = await model.getStationList(stationListKey);
        } else {
            initData.stationList = await model.getStationList(['KAN10']);
            initData.cityList = CityList['KAN10'];
        }

        if(params.agendaIdx){
            this.showModal(params.agendaIdx);
        }

        this.stores.search.resetData(initData);
        this.getListData(1)
        CustomUtil.delSessionItem('search');
    }

    initData = () => {
        return lodash.cloneDeep(SearchDefaultFormData);
    }

    closeModal = () => {
        this.setState({
            modalView: false
        });
    }

    showModal = (agendaIdx) => {
        this.stores.form.setData('agendaIndex', agendaIdx);
        this.setState({
            modalView: true
        })
    }

    handleTopBoxSearchButton = async () => {
        const isMobile = CustomUtil.checkMobile();
        await this.getListData(1);

        if(isMobile){
            CustomUtil.scrollTarget();
            this.handleMobileModalClose();
        }
    }

    getListData = async (page) => {
        let { panelType } = this.state;
        const email = this.stores.login.getData('email');
        const searchData = this.stores.search.getAllData();

        const list = model.getList(page, searchData).then((res) => {
            if(res.data.totalCnt < 1){
                panelType = 'dataEmpty';
            }else{
                panelType = 'list';
                res.data.list = model.dataProcess(res.data.list);
            }
            return res.data;
        });

        const joinedAgendas = model.getJoinedAgendas(email);

        const reuslt = await Promise.all([list, joinedAgendas]).then(res => {
            return {
                list: res[0],
                joinedAgendas: res[1]
            }
        })

        this.setState({
            data: reuslt.list,
            Loading: false,
            maxPageLen: reuslt.list.totalCnt,
            panelType: panelType
        });

        this.stores.form.setData('joinedAgendas', reuslt.joinedAgendas);
        this.stores.form.setData('agendaDatas', reuslt.list);
    }

    renderNextPageButton = () => {
        const { data } = this.state;
        const { maxPage } = data.paging;
        return (
            <>
                {
                    maxPage >= this.nextPage &&
                    <Grid container justifyContent={'center'}>
                        <Button variant={'outlined'} onClick={this.getListData}>{'次のページ'}</Button>
                    </Grid>
                }
            </>
        );
    }

    handleJoinAgenda = (agendaIdx) => {
        this.setState({
            backDropView: true
        })
        const email = this.stores.login.getData('email');

        if (!email) {
            const server = CustomUtil.getSessionItem('server');
            const serverData = JSON.parse(CustomUtil.decrypt(server));
            serverData.referer = window.location.href;
            const enc = CustomUtil.encrypt(JSON.stringify(serverData));
            CustomUtil.setSessionItem('server', enc);

            window.location.href = '/login';
            return;
        }

        const formData = {
            email: email,
            agenda_idx: agendaIdx,
        }

        mEntryDetl.joinAgenda(formData).then(res => {
            if (res.data.status === 'success') {
                alert(Message.agendaJoin.joined);
                this.setState({
                    backDropView: false,
                })

                this.handleUpdateJoinedAgendas(agendaIdx)
            }
        })
    }

    handleChangeFreeWord = (e) => {
        this.stores.search.setData('keyword', e.target.value);
    };

    handleUpdateJoinedAgendas = (agendaIdx) => {
        const joinedAgendas = this.stores.form.getData('joinedAgendas');
        joinedAgendas.push(agendaIdx);
        this.stores.form.setData('joinedAgendas', joinedAgendas);
    }

    handleNextPage = async (e, value) => {
        this.setState({
            currentPage: value
        })
        await this.getListData(value);
        CustomUtil.scrollTarget();
    }

    // TODO ::: 로딩마크 넣기
    renderList = () => {
        const { data } = this.state;
        const joinedAgendas = this.stores.form.getData('joinedAgendas');
        const isMobile = CustomUtil.checkMobile();
        const Component = isMobile ? SearchCardSP : SearchCardPC;

        return (
            data.list.map((item) => {
                return <Component
                    key={item.idx}
                    data={item}
                    detlPageFnc={this.showModal}
                    handleJoinAgenda={this.handleJoinAgenda}
                    joinedAgendaData={joinedAgendas}
                    agendaJoinBtn
                />
            })
        )
    }

    handleMobileModalOpen = ()=> {
        this.setState({
            mobileModalView: true
        })
    }

    handleMobileModalClose = () => {
        this.setState({
            mobileModalView: false
        })
    }

    handleResetSearch = async () => {
        const initData = this.initData();
        initData.cityList = CityList['KAN10'];
        initData.stationList = await model.getStationList(['KAN10']);
        CustomUtil.delSessionItem('search');
        this.stores.search.resetData(initData);
        this.getListData(1);

        const isMobile = CustomUtil.checkMobile();
        if(isMobile){
            CustomUtil.scrollTarget();
            this.handleMobileModalClose();
        }
    }

    renderSPSearchControll = () => {
        const { data } = this.state;
        return (
            <>
                <Grid className="sp_fixbtn_wrap">
                    <Grid className="sp_fixbtn">
                        <Grid className="hit">{'該当件数'}<Typography variant="span">{data.totalCnt}</Typography>{'件'}</Grid>
                        <Typography variant="subtitle1">
                            <Button variant="outlined" onClick={this.handleResetSearch}>{'リセット'}</Button>
                            <Button variant="contained" className="" onClick={this.handleMobileModalOpen}><img src={IMGwhitesearch} alt=''/>{'条件を変えて検索する'}</Button>
                        </Typography>
                    </Grid>
                </Grid>

                <CustomModal 
                    maxWidth={'xl'}
                    open={this.state.mobileModalView} 
                    onClose={this.handleMobileModalClose} 
                    getListFnc={this.handleTopBoxSearchButton}
                    handleReset={this.handleResetSearch}
                    handleKeywordChange={this.handleChangeFreeWord}
                    Component={MobileSearchBox} 
                />
            </>
        )
    }

    render() {
        const { data, panelType, currentPage } = this.state;
        const { classes } = this.props;
        const message = Message.dataNotFound.replace('[A]', '検索');
        const isMobile = CustomUtil.checkMobile();

        if (!data) {
            return <></>;
        }

        return (
            <Grid container justifyContent={'center'} classes={classes}>
                <CommNav />
                <Grid className="content search_content">
                    <Container>
                        <Grid container justifyContent="center" alignItems="flex-start" spacing={3}>
                            {
                                !isMobile &&
                                <Grid item md={4} className="pc_sidebar">
                                    <SearchLeftBox handleReset={this.handleResetSearch} listData={data} handleSearch={this.handleTopBoxSearchButton} handleKeywordChange={this.handleChangeFreeWord}/>
                                </Grid>
                            }
                            

                            <Grid item md={8} className="article content-frame">
                                <Grid item xs={12} className="search-title-wrap">
                                    <Typography className="search-title" variant="h2"><Typography className="strong" variant="span">{'ITエンジニア'}</Typography>{'の求人・案件一覧'}</Typography>
                                </Grid>
                                <Grid container direction="row" className='search-card-wrap' justifyContent="space-between" alignItems="flex-start" spacing="20">
                                    { panelType === 'list' ? this.renderList() : <DataNotFound message={message}/> }
                                </Grid>
                                <Grid container direction="row" justifyContent="center" alignItems="center" className="mt_30">
                                    <Pagination page={currentPage} count={data.paging.maxPage} variant="outlined" shape="rounded" color="primary" onChange={this.handleNextPage} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>

                { isMobile && this.renderSPSearchControll() }

                <CommFooter />
                <CustomModal maxWidth={'md'} open={this.state.modalView} onClose={this.closeModal} Component={AgendaDetl} />
                <BackDrop open={this.state.backDropView} />
            </Grid>
        );
    }
}

export default withStyles(style)((props) => <Search {...props} params={useParams()}/>);
