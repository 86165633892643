import React from "react";
import { inject, observer } from 'mobx-react';
import lodash from 'lodash';

import SearchLeftBox from '../../component/user/SearchLeftBox';
import SearchDefaultFormData from '../../constants/SearchDefaultFormData';

import { withStyles } from '@mui/styles';
import { Grid } from "@mui/material";

// override style
const style = {
    root: {
        padding: '1rem'
    }
}

@inject('stores')
@observer
class MobileSearchBox extends React.Component{
    constructor(props){
        super(props);
        this.stores = props.stores;
    }
    
    render(){
        const { classes, getListFnc, handleKeywordChange, handleReset, onClose } = this.props;
        const data = this.stores.form.getData('agendaDatas');
        return (
            <Grid container justifyContent={'center'} classes={classes}>
                <SearchLeftBox 
                    handleReset={handleReset} 
                    listData={data} 
                    handleSearch={getListFnc} 
                    onClose={onClose}
                    handleKeywordChange={handleKeywordChange}
                />
            </Grid>
        )
    }
}

export default withStyles(style)(MobileSearchBox);