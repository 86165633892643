import { action, autorun, makeObservable, observable } from "mobx"
import CustomUtil from "../model/common/CustomUtil";
import Validation from "../model/common/Validation";

class Server {
  @observable data = {};

  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @action
  init = autorun(() => {
    try {
      const server = CustomUtil.getSessionItem('server');
      let serverData = null;

      if (Validation.empty(server)) {
        serverData = {
          host: window.location.host,
          referer: '',
          protocol: window.location.protocol,
          origin: window.location.origin,
        }

        const json = CustomUtil.encrypt(JSON.stringify(serverData));
        CustomUtil.setSessionItem('server', json);
      } else {
        serverData = JSON.parse(CustomUtil.decrypt(server));
      }

      this.data = serverData;
    } catch (err) {
      console.log('Server store', err);
    }
  });

  getData = (key) => {
    try {
      return key ? this.data[key] : this.data;
    } catch (e) {
      return '-99';
    }
  }
}

export default Server;
