import React from 'react';
import { inject } from 'mobx-react';
import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import Typography from '@mui/material/Typography';
import CustomUtil from '../../model/common/CustomUtil';
import {
    Grid,
    Container,
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {
    company:{
        textAlign : 'right',
        fontWeight : 'bold'
    }
}

@inject('stores')
class Policy extends React.Component {
    render() {
        CustomUtil.scrollTarget();
        return (
            <>
                <Grid style={{ position: 'relative' }}>
                <CommNav />
                    <Grid className="content">
                        <Container>
                            <Typography variant="h2" className="content_title">個人情報保護方針</Typography>
                            <Grid item xs={12} className="column-shadow2">
                                <Typography variant="h3" className="entry_desc_detail_title">■ 個人情報保護理念</Typography>
                                <Typography variant="p" className="entry_desc">
                                    当社は、コンピュータシステムの開発及び開発支援を主な事業としており、業務を遂行する際には、<br/>
                                    社員の個人情報は元より顧客の個人情報も最重要情報と位置付け、その保護の重要性を深く認識し、安全かつ適切に取り扱う。<br/>
                                    この理念を実現するために、個人情報保護方針を以下に定める
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 個人情報保護方針</Typography>
                                <Typography variant="p" className="entry_desc">
                                    当社は、以下に個人情報保護方針を定め、個人情報を安全かつ適切に取扱うことを誓います。<br/>
                                    また、個人情報保護マネジメントシステムを構築し、この方針の達成に努めてまいります。<br/>
                                <br/>
                                <ul className='list_style'>
                                    <li>
                                        当社は、開発関連業務に関わる内外の個人情報を取り扱っており、<br/>
                                        安全かつ適切な手段で取得し、同意を得た利用目的の範囲で利用いたします。<br/>
                                        また、利用目的の範囲外での利用、提供が出来ない手順を定め、管理運用します。<br/></li>
                                    <li>
                                        当社は、個人情報に関する法令、国が定める指針等を特定し、順守いたします。<br/>
                                        また、それらの最新性を常に維持いたします。<br/></li>
                                    <li>
                                        当社は、個人情報の漏洩、滅失、き損等のリスク発生に対して、常に監視し、<br/>
                                        リスクの発生を予防します。また、個人情報に関わる事件・事故、<br/>
                                        システム上の不備については再発防止をいたします。<br/>
                                    </li>
                                    <li>
                                        当社は、個人情報に関する苦情相談の常設窓口を設け、遅滞無く対応いたします。<br/>
                                    </li>
                                    <li>
                                        当社は、個人情報保護マネジメントシステムを見直し、継続的に改善していきます。<br/></li>
                                </ul>
                                <br/>
                                    株式会社MINOSYS　代表取締役　崔 民鎬<br/>
                                    <br/>
                                    個人情報保護マネジメントシステムに関する問合せ先<br/>
                                    個人情報保護管理者　経営支援部　取締役　元 美羅　電話: 03-5942-8170<br/>
                                <Grid style={style.company}>
                                    制定日：2014年4月22日 <br/>
                                    改訂日：2016年1月12日 <br/>
                                </Grid>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 個人情報の利用目的</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 開示対象</Typography>
                                <Typography variant="p" className="entry_desc">
                                    保有する個人情報及び個人データは、以下の目的でのみ当社が利用し、その他の目的には利用いたしません。<br/>
                                    括弧内は利用目的に使用する書類です。<br/>
                                    <br/>
                                    <ul className='list_style'>
                                        <li>
                                            お客様について<br/>
                                            ・コンピュータープログラム開発業務の遂行、及び再委託に利用いたします。（案件情報全般）<br/>
                                            ・顧客管理、顧客ニーズの分析に利用いたします。（案件情報全般）<br/>
                                            ・販売促進資料のお客様への送付に利用いたします。（案件情報全般）<br/>
                                            ・お客様からの苦情相談及び個人情報開示等の処理の対応に利用いたします。（お客様名刺）<br/>
                                        </li>
                                        <li>
                                            従業者様について<br/>
                                            ・人事労務、福利厚生、社会保険、税務申告、給与支払い、助成申請、入退管理と必要な内外連絡<br/>
                                            &nbsp;&nbsp;&nbsp;（社内への提出書類全般）<br/>
                                            ・外部との連絡のため従業者が自身の名刺を提供する（担当者名刺）<br/>
                                            ・従業者側からの個人情報の開示等の一連の処理の対応（社員リスト）<br/>
                                            ・役員については、役員及び株主情報として法令に基づき公開（株主一覧表）<br/>
                                            ・従業者の監視（カメラ及びオンライン記録）<br/>
                                            ・委託業務のため、個人情報を顧客及び業者へ提供（担当者名刺）<br/>
                                        </li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 非開示対象</Typography>
                                <Typography variant="p" className="entry_desc">
                                    <ul className='list_style3'>
                                        <li>
                                            その他について<br/>
                                            ・協力会社様技術者のスキルシートは、案件受注活動のため顧客へ提供することがあります。（スキルシート）<br/>
                                            ・協力会社様の技術者情報は、委託元への入館手続きに利用します。（入館申請書）<br/>
                                            ・会員登録者様の個人情報は採否の判断や各種連絡等に利用します。（会員登録申込書類）<br/>
                                        </li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 安全管理措置</Typography>
                                <Typography variant="p" className="entry_desc">
                                    当社は取り扱う個人情報を保護するため、PC､IDCサーバﾞ及び書庫にて保管し、ID、パスワードによるログイン、作業ログの点検、定期的なバックアップ、ウィルス対策ソフトの導入等の措置を講じています。<br/>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 苦情相談等の窓口</Typography>
                                <Typography variant="p" className="entry_desc">
                                    保有個人データ又は第三者提供の記録に関する事項の周知又は提供、利用目的の通知、開示、苦情相談、訂正、削除、消去、利用停止及び第三者への提供停止等の申立先は以下の通りです。連絡をいただければ遅滞なく対応いたします。<br/>
                                    <br/>
                                    株式会社MINOSYS<br/>
                                    東京都新宿区西新宿7-17-14　新宿シティ源共同ビル3F<br/>
                                    代表取締役　崔 民鎬<br/>
                                    <br/>
                                    ※相談窓口: 経営支援部　取締役　元 美羅　電話: 03-5942-8170
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 個人情報に関する責任者</Typography>
                                <Typography variant="p" className="entry_desc">
                                    当社は、個人情報保護管理者として次の者を定めております。<br/>
                                    <br/>
                                    ※経営支援部　取締役　元 美羅　電話: 03-5942-8170
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 認定個人情報保護団体の名称及び苦情の解決の申出先</Typography>
                                <Typography variant="p" className="entry_desc">
                                    認定個人情報保護団体の名称<br/>
                                    一般財団法人日本情報経済社会推進協会<br/>
                                    <br/>
                                    苦情の解決の申出先<br/>
                                    認定個人情報保護団体事務局<br/>
                                    <br/>
                                    住所<br/>
                                    〒106-0032 東京都港区六本木一丁目9番9号　六本木ファーストビル内<br/>
                                    <br/>
                                    電話番号<br/>
                                    03-5860-7565 / 0120-700-779<br/>
                                    <br/>
                                    ※当社の製品・サービスに関する問合わせ先ではありません。
                                </Typography>
                            </Grid>
                        </Container>
                    </Grid>
                    <CommFooter />
                </Grid>
            </>
        );
    }
}

export default withStyles(style)(Policy);