const data = [
    {
        key: 'お仕事をお探しの方',
        value: '10',
    },
    {

        key: 'エンジニアをお探しの企業様',
        value: '20',
    },
    {
        key: 'その他',
        value: '250',
    }
]

export default data;