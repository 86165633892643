import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { withStyles } from '@mui/styles';

//override style
const style = {}

class CustomDatePicker extends React.Component {
    constructor(props) {
        super(props);
        
        const { value } = this.props;
        this.state = {
          date: value,
        };
    }
    
    render() {
        const { setValue } = this.props;

        const handleChange = (newValue) => {
            this.setState({date: newValue})
            
            setValue('job_start_date', (newValue ? newValue.format("YYYY-MM-DD h:mm:ss"):null));
        };

        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    label="2000/01/01のように記入してください。"
                    inputFormat="YYYY/MM/DD"
                    className='maxWidth'
                    value={this.state.date}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        )
    }
}

export default withStyles(style)(CustomDatePicker);