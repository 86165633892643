const data = [
    {
        key: '募集中',
        value: '10',
    },
    {
        key: '募集保留',
        value: '20',
    },
    {
        key: '充足',
        value: '30',
    },
];

export default data;