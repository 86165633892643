import exp from '../../constants/RegExp';
class Validation {
    empty = (value) => {
        if (
            value === "" ||
            value === null ||
            value === undefined ||
            (value !== null && typeof value == "object" && !Object.keys(value).length) ||
            !value
        ) {
            return true
        }

        return false;
    }

    symbol = (value) => {
        const rexExp = /[`~!@#$%^&*|\\/'/";://?]/gi;
        return rexExp.test(value);
    }

    name = (value) => {
        const rexExp = /^\W{1,10}/;
        return !rexExp.test(value);
    }

    email = (value) => {
        const regExp = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$/;
        if (this.empty(value) || !regExp.test(value)) {
            return true
        }

        return false;
    }

    pw = (value) => {
        if (!exp.pw.test(value)) {
            return true;
        }

        return false;
    }

    phone = (value) => {
        if (!exp.phone.test(value)) {
            return true;
        }

        return false;
    }
}

export default new Validation();