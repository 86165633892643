import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import FormDefaultUserInfo from '../../component/user/FormDefaultUserInfo';
import FormDetlUserInfo from '../../component/user/FormDetlUserInfo';
import FormFileUpload from '../../component/user/FormFileUpload';
import Message from '../../constants/Message';
import ErrorMsg from '../../component/common/ErrorMsg';
import UserRegister from '../../constants/form/UserRegister'

import model from '../../model/user/Regist';
import BackDrop from '../../component/common/BackDrop';
import CustomUtil from '../../model/common/CustomUtil';


import {
    Grid,
    Typography,
    Checkbox,
    Container,
    FormControlLabel,
    Button,
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {
    root:{
        '& .thirdWidth' : {
            minWidth : '32.5%'
        },
        '& .halfWidth': {
            minWidth : '49%'
        },
        '& .maxWidth': {
            minWidth : '100%'
        },
    }
}

@inject('stores')
@observer
class Regist extends React.Component {
    constructor(props){
        super(props);
        this.stores = props.stores;
        this.policyAccept = false;

        if (this.stores.login.getData('email')) {
            window.location.href = '/';
            this.render = () => (<></>)
        }

        this.state = {
            errorView: false,
            backDropView: false,
        }

        const initData = CustomUtil.deepCopy(UserRegister);
        this.stores.form.resetData(initData);

        const validation = {}
        for (const item of Object.keys(initData)) {
            validation[item] = false;
        }

        this.stores.validation.resetData(validation);
    }

    handlePolicyAccept = (e) => {
        this.policyAccept = e.target.checked;
    }

    checkValidate = () => {
        const formData = CustomUtil.deepCopy(this.stores.form.getData());
        formData.policyAccept = this.policyAccept;
        const { result , check } = model.validation(formData)
        this.stores.validation.resetData(result);
        return check;
    }

    submitForm = () => {
        if (this.checkValidate()) {
            this.setState({
                errorView: true,
            })

            CustomUtil.scrollTarget();
            return;
        }

        this.setState({
            errorView: false,
            backDropView: true,
        })

        const formData = this.stores.form.getData();

        model.submit(formData).then((res) => {
            alert(res.data.message);
            if(res.data.status === 'success'){
                window.location.href = '/';
            }else{
                this.setState({
                    backDropView: false
                })
            }
        })
    }

    render() {
        const { errorView } = this.state;
        const { classes } = this.props;
        const validation = this.stores.validation.getData();
        
        CustomUtil.scrollTarget();
        return (
            <>
                <Grid style={{ position: 'relative' }} id={'Top'} className={classes.root}>
                    <CommNav />

                    <Grid className="content">
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Container>
                                {
                                    errorView &&
                                    <ErrorMsg message={Message.validation.dataErr} />
                                }
                                <Grid item xs={12}>
                                    <center>
                                        <Typography variant="h2" className="content_title">{'会員登録'}</Typography>
                                    </center>
                                </Grid>
                                <FormDefaultUserInfo />
                                <FormDetlUserInfo />
                                <FormFileUpload />

                                <Grid item md={12}>
                                    <center>
                                        <Grid className="agreement_check">
                                            <FormControlLabel
                                                control={<Checkbox color="primary" onChange={this.handlePolicyAccept} />}
                                                label=""
                                                labelPlacement="end"
                                            />
                                            <Link to="/Agreement" target="_blank" rel="noopener noreferrer">{'「利用規約」'}</Link>
                                            <Link to="/Accept" target="_blank" rel="noopener noreferrer">{'「個人情報の取扱い」'}</Link>{'に同意する'}
                                        </Grid>
                                        {
                                            validation.policyAccept &&
                                                <Grid className="agreement_check">
                                                    <Typography color={'red'}>{Message.validation.regist.policy}</Typography>
                                                </Grid>
                                        }
                                    </center>
                                </Grid>

                                <Grid container justifyContent={'center'}>
                                    <Button className="ybtn" onClick={this.submitForm}>{'登録(無料)'}</Button>
                                </Grid>
                            </Container>
                        </Grid>
                    </Grid>
                    <CommFooter />
                </Grid>
                <BackDrop open={this.state.backDropView} />
            </>
        );
    }
}

export default withStyles(style)(Regist);
