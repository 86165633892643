import React from 'react';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';

import model from '../../model/user/Main';
import mSearch from '../../model/user/Search';
import MainAgendaCard from '../../component/user/MainAgendaCard';
import AgendaDetl from '../../page/user/AgendaDetl';
import CustomModal from '../common/CustomModal';

import {
    Grid,
    Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
        
    }
}

@inject('stores')
class MainRecommendAgenda extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            agendaList: null,
            modalView: false,
        }

        this.stores = props.stores;
    }

    getAgendaList = async () => {
        const email = this.stores.login.getData('email');
        const list = await model.getAgendaList(email);
        const joinedAgendas = await mSearch.getJoinedAgendas(email);
        this.stores.form.setData('joinedAgendas', joinedAgendas);
        this.setState({
            agendaList: list
        });
    }

    componentDidMount() {
        this.getAgendaList();
    }

    componentWillUnmount = () => {
        this.stores.form.initData();
    }

    showModal = (key) => {
        this.stores.form.setData('agendaIndex', key)
        this.setState({
            modalView: true
        });
    }

    closeModal = () => {
        this.setState({
            modalView: false
        });
    }

    render() {
        const { agendaList } = this.state;
        const { classes } = this.props;

        if(!agendaList){
            return <></>
        }

        return (
            <Grid classes={classes} className={'MainCardSP'}>
                <Grid container justifyContent={'center'}>
                    <Typography variant="h2" color={'#1976D2'} className="content_title mt_80 color-blue">
                        {'新'}
                    </Typography>
                    <Typography variant="h2" className="content_title mt_80">
                        {'着案件'}
                    </Typography>
                </Grid>
                <Grid container justifyContent={'center'}>
                    {
                        agendaList.map((item) => (
                            <MainAgendaCard key={item.idx} data={item} showModalFnc={this.showModal} />
                        ))
                    }

                </Grid>
                <Grid container justifyContent={'center'}>
                    <Link to="/search" className="ybtn">{'もっと見る'}</Link>
                </Grid>
                <CustomModal
                    maxWidth={'md'}
                    open={this.state.modalView}
                    onClose={this.closeModal}
                    Component={AgendaDetl}
                />
            </Grid>
        );
    }
}

export default withStyles(style)(MainRecommendAgenda);