const data = {
    name: '神奈川県',
    key: 'kanagawa',
    value: 'KAN20',
    area: [
        {
            key: '横浜市',
            value: 'KA000'
        },
        {
            key: '川崎市',
            value: 'KA010'
        },
        {
            key: "その他",
            value: "KA999"
        }
    ]
}

export default data;