import React from 'react';
import { inject } from 'mobx-react';
import Auth from '../../model/common/Auth';
import UserDetl from './UserDetl';

import { withStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

// override style
const styles = {
    root: {
        width : '90%',
        '& .contents':{
            width : '80%'
        }
    }
}

@inject('stores')
class DisplayUserInfo extends React.Component {
    constructor(props){
        super(props);
        this.params = props.params;
    }

    render() {
        if (Auth.checkAdmin()) {
            window.location.href = '/';
            return (<></>);
        }

        const { userIdx } = this.params;
        const { classes } = this.props;
        return (
            <Grid container justifyContent={'center'} classes={classes}>
                <Grid item md={9} >
                    <UserDetl userIdx={userIdx} browse />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)((props) => <DisplayUserInfo params={useParams()} {...props}/>)
