import React from 'react';
import { Link, useParams } from 'react-router-dom';

import SlideNaviMenu from './SlideNaviMenu';
import IMGblacklogo from '../../asset/img/black_logo.svg';

import { withStyles } from '@mui/styles';
import CustomUtil from '../../model/common/CustomUtil';

// override style
const style = {}

class CommNav extends React.Component {
    handleMoveHome = () => {
        const { pathname } = window.location;
        if (pathname === '/') {
            CustomUtil.scrollTarget()
        }
    }

    render() {
        return (
            <header className="fixheader">
                <Link to="/" className="headerImg" onClick={this.handleMoveHome}>
                    <img src={IMGblacklogo} alt="MINONAVI" />
                </Link>
                <SlideNaviMenu />
            </header>
        );
    }
}

export default withStyles(style)(CommNav);