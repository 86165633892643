
const data = {
    pw: /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,20}$/,       // 암호 체크
    phone: /^(\d{3,4})-?(\d{3,4})-?(\d{3,4})$/,     // 핸드폰 번호 체크
    last_education_name: /^\W{1,50}$/,              // 최종학력 체크
    nationality: /^\W{1,100}$/,                     // 국적 체크
    near_station: /^\W{1,20}$/,                     // 가까운역 체크
    now_address: /^\W{1,10}$/,                      // 주소 체크
    harf_text: /[^\\x01-\\x7E]$/,                   // 半角 체크
}

export default data;