import { action, makeObservable, observable, autorun } from "mobx"
import util from "../model/common/CustomUtil";

class PasswordForgot {
  @observable data = null;

  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @action
  initPasswordForgot = autorun(() => {
    try {
      const session = util.getSessionItem('pwReset');
      if (session !== null) {
        this.data = JSON.parse(util.decrypt(session))
      }
    } catch (err) {
      console.log('initPasswordForgot store', err);
    }
  });

  @action
  setData = (obj) => {
    this.data = obj;
  }

  init = () => {
    this.data = null;
  }

  getData = (key) => {
    try {
      return key ? this.data[key] : this.data;
    } catch (e) {
      return '';
    }
  }
}

export default PasswordForgot;
