import React from 'react';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';

import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';

import Message from '../../constants/Message';
import ErrorMsg from '../../component/common/ErrorMsg';
import model from '../../model/common/Mail';
import BackDrop from '../../component/common/BackDrop';
import CustomUtil from '../../model/common/CustomUtil';
import Inquery from '../../constants/Inquery';

import {
    Grid,
    Typography,
    Button,
    Radio,
    FormControl,
    RadioGroup,
    Container,
    FormControlLabel,
    TextField,
    Checkbox,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import Validation from '../../model/common/Validation';

// override style
const style = {
    root: {
        '& .margin-top': {
            // marginTop: '10vh'
        },
        '& .mandatory': {
            fontSize: '0.8rem',
            color: 'red'
        },
        '& .component-frame': {
            marginTop: '1rem',
            marginBottom: '1rem'
        }, 
        '& .ybtn' : {
            marginBottom: '2rem !important'
        },
        '& .main-title': {
            marginBottom: '1rem'
        }
    }
}

@inject('stores')
class Inquiry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            backDropView: false,
            showModal: false,
            modalMsg: '',
            formDatas : {},
            validation: null
        }

        this.stores = props.stores;
    }

    componentDidMount = () => {
        const inqueryValue = CustomUtil.getSessionItem('inquery') || '10';
        const form = this.setEmptyFormDatas(inqueryValue)

        form.kind = inqueryValue;

        this.setState({
            formDatas : form,
            validation: {}
        })
    }

    componentWillUnmount = () => {
        CustomUtil.delSessionItem('inquery');
    }

    setEmptyFormDatas = (key) => {
        const email = this.stores.login.getData('email') || '';
        const name = this.stores.login.getData('name') || '';
        const nameKana = this.stores.login.getData('name_kana') || '';
        const phone = this.stores.login.getData('phone') || '';

        const data = {
            name: name,
            nameKana: nameKana,
            email: email,
            contents: '',
        }

        if(key === '10'){
            return  {
                ...data,
            }
        }else{
            return {
                ...data,
                comName: '',
                dept: '',
                address: '',
                phone: phone,
                contents: '',
            }
        }
    }

    setFormData = (e) => {
        const { formDatas } = this.state;
        formDatas[e.target.name] = e.target.value;

        this.setState({
            formDatas: formDatas
        })
    }

    checkValidate = () => {
        const { formDatas } = this.state;
        const checkValues = {};
        let ignore = [];

        if(formDatas.kind === '20'){
            ignore = ['address'];
        }else if(formDatas.kind === '250'){
            ignore = ['address', 'dept', 'comName'];
        }

        // check empty
        for (const key in formDatas) {
            if(ignore.indexOf(key) > -1){
                checkValues[key] = false;
                continue;
            }
            checkValues[key] = Validation.empty(formDatas[key]);
        }

        // check email
        if (Validation.email(formDatas.email)) {
            checkValues['email'] = true;
        }

        const isPhone = formDatas.phone || false;
        if(isPhone){
            // check phone
            if (Validation.phone(formDatas.phone)) {
                checkValues['phone'] = true;
            }
        }

        if(!formDatas.policy){
            checkValues['policy'] = true;
        }

        this.setState({
            validation: checkValues
        })

        return Object.values(checkValues).find(item => item);
    }

    sendMail = () => {
        const { formDatas } = this.state;

        if (this.checkValidate()) {
            this.setState({
                errorView: true,
            })

            CustomUtil.scrollTarget();
            return;
        }

        this.setState({
            errorView: false,
            backDropView: true
        });

        model.userToManagerMailSend(formDatas).then((res) => {
            this.setState({
                backDropView: false
            })

            const msg = res.data.status === 'success' ? Message.inquery.mailSend.success : Message.inquery.mailSend.fail;
            alert(msg);

            if(res.data.status === 'success'){
                window.location.reload();
                CustomUtil.scrollTarget()
            }
        });
    }

    changeRadio = (e) => {
        const form = this.setEmptyFormDatas(e.target.defaultValue);
        form.kind = e.target.defaultValue;
        form.policy = false;

        this.setState({
            errorView: false,
            validation: {},
            formDatas: form
        })
    }

    renderRadioButtons = () => {
        const { formDatas } = this.state;
        const radioValue = formDatas.kind || '';

        return (
            <Grid container alignItems={'center'}>
                <Grid item md={3} xs={12} className="inquiry_title">{'お問い合わせ種類'}{this.renderRequireSpan()}</Grid>
                <Grid item className="form_input_cell radio">
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" defaultValue="top" onChange={this.changeRadio} value={radioValue}>
                            {
                                Inquery.map((item, index) => (
                                    <FormControlLabel key={index} value={item.value} control={<Radio color="primary" name={'kind'} />} label={item.key} />
                                ))
                            }
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    renderRequireSpan = () => {
        return (
            <Typography variant="span" className="mandatory">{'*必須'}</Typography>
        )
    }

    renderName = () => {
        const { validation, formDatas } = this.state;
        const { name } = validation || false;
        return (
            <Grid container alignItems={'center'} className={'component-frame'}>
                <Grid item md={3} xs={3} className="form_title_cell mb_none inquiry_title">{'氏名'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} xs={12}>
                    <TextField
                        error={name}
                        size="small"
                        id="outlined-error-helper-text"
                        helperText={Message.inquery.name}
                        onChange={this.setFormData}
                        name="name"
                        defaultValue={formDatas.name}
                        fullWidth
                        placeholder={'佐藤　太郎'}
                    />
                    {
                        name &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.inquery.name}</Typography>
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }

    renderNameKana = () => {
        const { validation, formDatas } = this.state;
        const { nameKana } = validation || false;
        return (
            <Grid container alignItems={'center'} className={'component-frame'}>
                <Grid item md={3} className="form_title_cell mb_none inquiry_title">{'氏名ガナ'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} xs={12}>
                    <TextField
                        error={nameKana}
                        size="small"
                        id="outlined-error-helper-text"
                        helperText={Message.inquery.nameKana}
                        onChange={this.setFormData}
                        name="nameKana"
                        defaultValue={formDatas.nameKana}
                        fullWidth
                        placeholder={'さとう　たろう'}
                    />
                    {
                        nameKana &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.inquery.nameKana}</Typography>
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }

    renderEmail = () => {
        const { validation, formDatas } = this.state;
        const { email } = validation || false;

        return (
            <Grid container alignItems={'center'} className={'component-frame'}>
                <Grid item md={3} className="form_title_cell mb_none inquiry_title">{'メールアドレス'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} xs={12}>
                    <TextField
                        error={email}
                        type={'url'}
                        size="small"
                        id="outlined-error-helper-text"
                        helperText={Message.inquery.email}
                        onChange={this.setFormData}
                        name="email"
                        fullWidth
                        defaultValue={formDatas.email}
                        placeholder={'test@gmail.com'}
                    />
                    {
                        email &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.inquery.email}</Typography>
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }

    renderInqueryContents = () => {
        const { validation } = this.state;
        const { contents } = validation || false;
        return (
            <Grid container alignItems={'center'} className={'component-frame'}>
                <Grid item md={3} className="form_title_cell mb_none inquiry_title">{'お問い合わせ内容'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} xs={12}>
                    <TextField
                        multiline
                        minRows={12}
                        error={contents}
                        size="small"
                        id="outlined-multiline-flexible"
                        helperText={Message.inquery.contents}
                        onChange={this.setFormData}
                        name="contents"
                        fullWidth
                    />
                    {
                        contents &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.inquery.contents}</Typography>
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }

    renderCompanyName = (require) => {
        const { validation } = this.state;
        const { comName } = validation || false;
        return (
            <Grid container alignItems={'center'} className={'component-frame'}>
                <Grid item md={3} className="form_title_cell mb_none inquiry_title">{'貴社名'}{ require && this.renderRequireSpan()}</Grid>
                <Grid item md={9} xs={12}>
                    <TextField
                        error={comName}
                        size="small"
                        id="outlined-error-helper-text"
                        helperText={Message.inquery.comName}
                        onChange={this.setFormData}
                        name="comName"
                        placeholder={'株式会社 MINONAVI'}
                        fullWidth
                    />
                    {
                        comName &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.inquery.comName}</Typography>
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }

    renderDeptName = (require) => {
        const { validation } = this.state;
        const { dept } = validation || false;
        return (
            <Grid container alignItems={'center'} className={'component-frame'}>
                <Grid item md={3} className="form_title_cell mb_none inquiry_title">{'部署名 / ご役職'}{ require && this.renderRequireSpan()}</Grid>
                <Grid item md={9} xs={12}>
                    <TextField
                        error={dept}
                        size="small"
                        id="outlined-error-helper-text"
                        helperText={Message.inquery.dept}
                        onChange={this.setFormData}
                        name="dept"
                        fullWidth
                        placeholder={'営業部、課長'}
                    />
                </Grid>

            </Grid>
        )
    }

    renderAddress = () => {
        const { validation } = this.state;
        const { address } = validation || false;
        return (
            <Grid container alignItems={'center'} className={'component-frame'}>
                <Grid item md={3} className="form_title_cell mb_none inquiry_title">{'ご連絡先（住所）'}</Grid>
                <Grid item md={9} xs={12}>
                    <TextField
                        error={address}
                        size="small"
                        id="outlined-error-helper-text"
                        helperText={Message.inquery.address}
                        onChange={this.setFormData}
                        name="address"
                        fullWidth
                        placeholder={'東京都新宿区'}
                    />
                    {
                        address &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.inquery.address}</Typography>
                        </Grid>
                    }
                </Grid>

            </Grid>
        )
    }

    renderPhone = () => {
        const { validation, formDatas } = this.state;
        const { phone } = validation || false;
        return (
            <Grid container alignItems={'center'} className={'component-frame'}>
                <Grid item md={3} className="form_title_cell mb_none inquiry_title">{'電話番号'}{this.renderRequireSpan()}</Grid>
                <Grid item md={9} xs={12}>
                    <TextField
                        error={phone}
                        type={'tel'}
                        size="small"
                        defaultValue={formDatas.phone}
                        id="outlined-error-helper-text"
                        helperText={Message.inquery.phone}
                        onChange={this.setFormData}
                        name="phone"
                        fullWidth
                        placeholder={'03-5920-1234'}
                    />
                    {
                        phone &&
                        <Grid container>
                            <Typography color={'red'}>{Message.validation.inquery.phone}</Typography>
                        </Grid>
                    }
                </Grid>

            </Grid>
        )
    }

    renderPolicy = () => {
        const { validation, formDatas } = this.state;
        const policyValue = formDatas.policy || false;
        const { policy } = validation || false;
        return (
            <Grid container justifyContent={'center'} alignItems={'center'}>
                <Checkbox checked={policyValue} onChange={this.handlePolicyAccept} />

                <Link to="/Agreement" target="_blank" rel="noopener noreferrer">{'「利用規約」'}</Link>
                <Link to="/Accept" target="_blank" rel="noopener noreferrer">{'「個人情報の取扱い」'}</Link>{'に同意する'}
                {
                    policy &&
                    <Grid container justifyContent={'center'}>
                        <Typography color={'red'}>{Message.validation.inquery.policy}</Typography>
                    </Grid>
                }
            </Grid>
        )
    }

    renderTypeA = () => {
        return (
            <>
                {this.renderName()}
                {this.renderNameKana()}
                {this.renderEmail()}
                {this.renderInqueryContents()}
            </>
        )
    }

    renderTypeB = () => {
        return (
            <>
                {this.renderCompanyName(true)}
                {this.renderName()}
                {this.renderNameKana()}
                {this.renderDeptName(true)}
                {this.renderAddress()}
                {this.renderPhone()}
                {this.renderEmail()}
                {this.renderInqueryContents()}
            </>
        )
    }

    renderTypeC = () => {
        return (
            <>
                {this.renderCompanyName()}
                {this.renderName()}
                {this.renderNameKana()}
                {this.renderDeptName()}
                {this.renderAddress()}
                {this.renderPhone()}
                {this.renderEmail()}
                {this.renderInqueryContents()}
            </>
        )
    }

    handlePolicyAccept = (e) => {
        const { formDatas } = this.state;
        formDatas.policy = e.target.checked;

        this.setState(formDatas);
    }

    render() {
        const { errorView, formDatas, validation } = this.state;
        const { mypage, classes } = this.props;
        const frameClass = !mypage ? 'margin-top' : '';

        if(!validation){
            CustomUtil.scrollTarget();
        }

        return (
            <Grid classes={classes}>
                <CommNav />
                <Container className={frameClass}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" className="'form, content">
                        <Typography variant="h5" className={'content_title'}>{'お問い合わせ'}</Typography>

                        {errorView && <ErrorMsg message={Message.validation.dataErr} />}

                        <Grid container className="column-shadow">
                            {!mypage && this.renderRadioButtons()}

                            {formDatas.kind === '10' && this.renderTypeA()}
                            {formDatas.kind === '20' && this.renderTypeB()}
                            {formDatas.kind === '250' && this.renderTypeC()}
                        </Grid>

                        {this.renderPolicy()}

                        <Button className="ybtn" onClick={this.sendMail}>{'送信'}</Button>
                    </Grid>
                </Container>
                <BackDrop open={this.state.backDropView} />
                <CommFooter />
            </Grid>
        );
    }
}

export default withStyles(style)(Inquiry);
