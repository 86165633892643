import React from 'react';
import { inject, observer } from 'mobx-react';
import lodash from 'lodash';
import Language from '../../constants/Language';
import RecruitmentType from '../../constants/RecruitmentType';
import LocationsList from '../../constants/LocationsList';
import CityList from '../../constants/CityList';
import SearchDefaultFormData from '../../constants/SearchDefaultFormData';
// import PopularAgenda from './PopularAgenda';

import IMGwhitesearch from '../../asset/img/white_search.svg';
import IMGblacksearch from '../../asset/img/black_search.svg';
import IMGblacktag from '../../asset/img/tag.svg';
import IMGx from '../../asset/img/x.svg';

import {
    Grid,
    Typography,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CustomUtil from '../../model/common/CustomUtil';
import Validation from '../../model/common/Validation';

// style override
const styles = {
    root: {
        width: '100%',
        '& .lang-button':
        {
            textTransform: 'none',
            margin: '2px 4px'
        },
        '& .active': {
            background: '#1976d2',
            color: 'white',
            '&:hover': {
                background: '#1976d2',
            }
        },
        '& .child-area': {
            border: '1px solid',
            margin: '0.5rem 0',
            padding: '5px',
            borderRadius: '5px'
        }
    }
}

@inject('stores')
@observer
class MainSearchBox extends React.Component {
    componentDidMount = () => {
        this.initData();
    }

    initData = () => {
        const initData = lodash.cloneDeep(SearchDefaultFormData)
        this.setState({
            ...initData
        })
    }

    searchDataDuplicateCheck = (ref, value) => {
        let result = [...ref]

        const isHave = result.find(item => item === value)
        if (isHave) {
            result = result.filter(item => item !== value);
        } else {
            result.push(value);
        }

        return result;
    }

    renderCategoryArea = () => {
        const { selectedTab } = this.state;

        const kantou = LocationsList.filter(item => item.area === 'kantou')
        const kyusyu = LocationsList.filter(item => item.area === 'kyusyu')

        const categorys = [
            {
                key: 'kantou',
                name: '関東',
                data: kantou,
            },
            {
                key: 'kyusyu',
                name: '九州',
                data: kyusyu,
            },
            {
                key: 'language',
                name: '言語',
                data: Language,
            },
            {
                key: 'position',
                name: 'ポジション',
                data: RecruitmentType,
            },
        ]

        return (
            <Grid container justifyContent={'center'}>
                {
                    categorys.map((item, index) => {
                        return (
                            <Grid item md={3} xs={6} key={index} className="location_btn">
                                <Button className={selectedTab === item.key ? "selectedCategory" : ""}
                                    onClick={this.handleCategoryChange}
                                    data-value={item.key} name={item.name}
                                >
                                    {item.name}
                                </Button>
                            </Grid>
                        )
                    })
                }
            </Grid>
        )
    }

    handleCategoryChange = (e) => {
        const { location } = this.state;
        const { dataset } = e.target;
        const selectedTab = dataset.value;

        const kinds = LocationsList.filter(item => item.area === selectedTab);
        let cities = [];

        for (const data of kinds) {
            const areaCheck = location.area.find(item => item === data.value)
            if (areaCheck) {
                cities = CityList[data.value];
                break;
            }
        }

        this.setState({
            selectedTab: selectedTab,
            cityList: cities
        })
    }

    renderChildAreaDetl = (childData) => {
        const { tags } = this.state;
        const childs = childData || [];
        return (
            <Grid container>
                {
                    childs.map((item, index) => {
                        const active = tags.key.find(tag => tag === item.key) ? 'active' : '';
                        return (
                            <Button className={`${active} lang-button`} key={index} onClick={this.handleChildProcess} data-value={item.value} name={item.key}>
                                {item.key}
                            </Button>
                        )
                    })
                }
            </Grid>
        )
    }

    renderChildArea = () => {
        const { selectedTab, cityList } = this.state;
        let child1 = [];
        let child2 = [];

        if (selectedTab === 'language') {
            child1 = Language;
        } else if (selectedTab === 'position') {
            child1 = RecruitmentType
        } else if (selectedTab === 'kantou' || selectedTab === 'kyusyu') {
            child1 = LocationsList.filter(item => item.area === selectedTab);
            child2 = cityList;
        }

        return (
            <Grid container>
                {
                    child1.length > 0 &&
                    <Grid container className={'child-area'}>
                        {this.renderChildAreaDetl(child1)}
                    </Grid>
                }

                {
                    child2.length > 0 &&
                    <Grid container className={'child-area'}>
                        {this.renderChildAreaDetl(child2)}
                    </Grid>
                }
            </Grid>
        )
    }

    handleChildProcess = (e) => {
        const data = { ...this.state };
        const { value } = e.target.dataset;
        const { name } = e.target;
        let addTag = false;

        if (data.selectedTab === 'language') {
            data.language = this.searchDataDuplicateCheck(data.language, value)
        } else if (data.selectedTab === 'position') {
            data.position = this.searchDataDuplicateCheck(data.position, value);
        } else if (data.selectedTab === 'kantou' || data.selectedTab === 'kyusyu') {
            const areaCheck = LocationsList.find(item => item.value === value);
            let hasCity = false;
            if(areaCheck){
                for(const item of CityList[value]){
                    hasCity = data.location.city.find(i => item.value === i);
                    if(hasCity){
                        addTag = true;
                        break;
                    }
                }

                if(!hasCity){
                    data.location.area = this.searchDataDuplicateCheck(data.location.area, value);
                }

                data.cityList = CityList[value];
            }else{
                data.location.city = this.searchDataDuplicateCheck(data.location.city, value);
            }
        }

        if(!addTag){
            data.tags = {
                key : this.searchDataDuplicateCheck(data.tags.key, name),
                value : this.searchDataDuplicateCheck(data.tags.value, value),
            };
        }

        this.setState({
            ...data
        })
    }

    renderTagArea = () => {
        const { tags } = this.state;
        return (
            <Grid container justifyContent={'left'}>
                {
                    !Validation.empty(tags.key) &&
                    <Grid>
                        <Grid container className="additional_tag">
                            <Typography variant="h5" className="tag_title"><img src={IMGblacktag} alt="" />{'選択したタグ'}</Typography>
                        </Grid>
                        <Grid container className="additional_tag">
                            {
                                tags.key.map((item, index) => {
                                    return (
                                        <Grid className="tag" key={index}>
                                            {tags.key[index]}
                                            <Button>
                                                <img src={IMGx} alt="x" onClick={() => this.handleDeleteTag(tags.key[index], tags.value[index])} />
                                            </Button>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                }
            </Grid>
        )
    }

    handleDeleteTag = (key, value) => {
        const searchData = {...this.state};
        let addTag = true;
        const isLanguage = Language.find(item => item.value === value);
        const isPosition = RecruitmentType.find(item => item.value === value);

        if (isLanguage) {
            searchData.language = this.searchDataDuplicateCheck(searchData.language, value)
        } else if (isPosition) {
            searchData.position = this.searchDataDuplicateCheck(searchData.position, value);
        } else {
            const isArea = LocationsList.find(item => item.value === value);

            if(isArea){
                let hasCity = false;
                for(const item of CityList[value]){
                    hasCity = searchData.location.city.find(i => item.value === i);
                    if(hasCity){
                        addTag = false;
                        break;
                    }
                }

                if(!hasCity){
                    searchData.location.area = this.searchDataDuplicateCheck(searchData.location.area, value); 
                }

                searchData.cityList = CityList[value];
            }else{
                searchData.location.city = this.searchDataDuplicateCheck(searchData.location.city, value);
            }
        }

        if(addTag){
            searchData.tags = {
                key: this.searchDataDuplicateCheck(searchData.tags.key, key),
                value: this.searchDataDuplicateCheck(searchData.tags.value, value),
            }
        }

        this.setState({...searchData})
    }

    handleMoveSearch = () => {
        const formData = CustomUtil.encrypt(JSON.stringify(this.state));
        CustomUtil.setSessionItem('search', formData);
        window.location.href = '/search';
    }

    handleClearSearch = () => {
        this.initData();
    }

    render() {
        const { classes } = this.props;
        if(!this.state){
            return <></>
        }

        return (
            <Grid classes={classes}>
                <Grid item xs={12} >
                    <center>
                        <Typography className="content_title mt_80">
                            <img src={IMGblacksearch} alt="Search" /><br />
                            {'案件を探す'}
                        </Typography>
                    </center>
                </Grid>
                {/* <PopularAgenda /> */}
                <Grid container direction="row" justifyContent="center" alignItems="center" className="column-shadow2">

                    {this.renderCategoryArea()}
                    {this.renderChildArea()}
                    {this.renderTagArea()}

                    <Grid item xs={12} className="search_btn_wrap mt_30">
                        <Button variant="outlined" onClick={this.handleClearSearch}>{'クリア'}</Button>
                        <Button className="btn" variant="contained" onClick={this.handleMoveSearch}><img src={IMGwhitesearch} alt="" />{'検索'}</Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(MainSearchBox);
