import React from 'react';
import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import Typography from '@mui/material/Typography';
import {
    Grid,
    Container,
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {}

class Privacy extends React.Component {
    render() {
        return (
            <>
                <Grid style={{ position: 'relative' }}>
                <CommNav />
                    <Grid className="content">
                        <Container>
                            <Typography variant="h2" className="content_title">プライバシーポリシー</Typography>
                            <Grid item xs={12} className="column-shadow2">
                                <Typography variant="h1" className="entry_desc_title">プライバシーポリシー</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ プライバシーポリシー</Typography>
                                <Typography variant="p" className="entry_desc">
                                </Typography>
                            </Grid>
                        </Container>
                    </Grid>
                    <CommFooter />
                </Grid>
            </>
        );
    }
}

export default withStyles(style)(Privacy);