import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress'
import { withStyles } from '@mui/styles';

// override style
const style = {
    root : {
        zIndex: 99999
    }
}

class BackDrop extends React.Component {
    render() {
        const { classes, open } = this.props;
        return (
            <Backdrop classes={classes} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
}

export default withStyles(style)(BackDrop)