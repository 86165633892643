import Tokyo from './location/Tokyo';
import Kanagawa from './location/Kanagawa';
import Saitama from './location/Saitama';
import Chiba from './location/Chiba';
import Fukuoka from './location/Fukuoka';

const data = {
    tokyo: Tokyo,
    kanagawa: Kanagawa,
    saitama: Saitama,
    chiba: Chiba,
    fukuoka: Fukuoka,
}

export default data;