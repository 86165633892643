import { action, makeObservable, observable } from "mobx"

class Form {
  @observable data = {};

  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @action
  setData = (key, value) => {
    this.data[key] = value;
  }

  @action
  resetData = (data) => {
    this.data = data;
  }

  @action
  initData = () => {
    this.data = {
      modalView: false,
      modalNextPage: 'detl',
      search: [],
    };
  }

  getData = (key) => {
    try {
      return key ? this.data[key] : this.data;
    } catch (e) {
      return '';
    }
  }

}

export default Form;
