import React from 'react';
import { inject } from 'mobx-react';
import model from '../../model/user/EntryDetl';
import util from '../../model/common/CustomUtil';
import Message from '../../constants/Message';

import AgendaDetlPC from '../../component/user/AgendaDetlPC';
import AgendaDetlSP from '../../component/user/AgendaDetlSP';
// import RecommendAgenda from '../../component/user/RecommendAgenda'

import {
    Grid,
} from '@mui/material';

import NoImage from '../../asset/img/no_image.png';

import { withStyles } from '@mui/styles';
import BackDrop from '../../component/common/BackDrop';
import CustomUtil from '../../model/common/CustomUtil';

// override style
const style = {
    root:{
        '& .reset-height': {
            minHeight : 'auto'
        },
    },
}

@inject('stores')
class EntryDetl extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            backDropView: false,
            agendaComplete: false,
        }
    
        this.form = props.stores.form;
        this.login = props.stores.login;
    }

    componentDidMount = () => {
        this.getData();
        this.addAgendaHit();
    }

    getData = async() => {
        const agendaIdx = this.form.getData('agendaIndex');
        const email = this.login.getData('email');

        const detlData = await model.getData(agendaIdx)
        const joinCheck = await model.agendaJoinChecked(email, agendaIdx);
        
        this.setState({
            data: model.dataProcess(detlData),
            joinCheck: joinCheck
        });
    }

    addAgendaHit = () => {
        const agendaIdx = this.form.getData('agendaIndex');
        const formData = {
            agenda_idx: agendaIdx
        }

        model.addAgendaHit(formData);
    }

    getManagerFileLink = () => {
        const { data } = this.state;
        let img = NoImage

        if(data){
            if(data.assign.file){
                const server = util.getApiServerUrl();
                img = `${server}/uploads/${data.assign.file}`;
            }
        }

        return img;
    }

    handleUpdateJoinedAgendas = (agendaIdx) => {
        const joinedAgendas = this.form.getData('joinedAgendas');
        joinedAgendas.push(agendaIdx);
        this.form.setData('joinedAgendas', joinedAgendas);
    }

    handleJoinAgenda = (agendaIdx) => {
        this.setState({
            backDropView: true
        })
        const email = this.login.getData('email');

        if(!email){
            const server = CustomUtil.getSessionItem('server');
            const serverData = JSON.parse(CustomUtil.decrypt(server));
            serverData.referer = window.location.href;
            const enc = CustomUtil.encrypt(JSON.stringify(serverData));
            CustomUtil.setSessionItem('server', enc);
            
            window.location.href= '/login';
            return;
        }

        const formData = {
            email: email,
            agenda_idx: agendaIdx,
        }
       
        model.joinAgenda(formData).then(res => {
            if (res.data.status === 'success') {
                alert(Message.agendaJoin.joined);
                this.setState({
                    backDropView: false, 
                })

                this.handleUpdateJoinedAgendas(agendaIdx);
            }
        })
    }

    render() {
        const { data } = this.state;

        if(!data){
            return <></>
        }

        const { classes, onClose } = this.props;
        data.managerImage = this.getManagerFileLink();
        data.joinCheck = this.state.joinCheck;
        const isMobile = CustomUtil.checkMobile();
        const joinedAgendas = this.form.getData('joinedAgendas');
        const Component = isMobile ? AgendaDetlSP : AgendaDetlPC;

        return (
            <>
                {
                    <Grid classes={classes} className="content mt_none bg_gray modal_padding">
                        <div className={'btn_close close-button'} onClick={onClose}>&times;</div>
                        
                        <Component data={data} agendaJoinBtn handleJoinAgenda={this.handleJoinAgenda} joinedAgendas={joinedAgendas}/>

                        {/* <RecommendAgenda data={data}/> */}
                    </Grid>
                }

                <BackDrop open={this.state.backDropView} />
            </>
        );
    }
}

export default withStyles(style)(EntryDetl);