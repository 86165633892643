import React from 'react';
import { withStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import Message from '../../constants/Message';
import TextBox from '../common/TextBox';

import {
    Grid,
    Typography,
} from '@mui/material';
import Validation from '../../model/common/Validation';

// override style
const style = {
    root: {
        '& .w45': {
            width: '49%'
        },
        '& .w100': {
            width: '100%'
        }
    }
}

@inject('stores')
@observer
class FormDefaultUserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.stores = props.stores;
    }

    handleSetFormData = (e) => {
        this.stores.form.setData(e.target.id, e.target.value)
    }

    renderRequireMsg = () => {
        return (
            <Typography variant="span" className="mandatory">{'*必須'}</Typography>
        )
    }

    renderInputComponent = (key, type, label, className) => {
        const loginUser = this.stores.login.getData();
        const value = this.stores.form.getData(key) || '';
        const validation = this.stores.validation.getData(key)
        let disabled = false;

        if(loginUser && key === 'email'){
            disabled = true;
        }

        const config = {
            type: type,
            error: validation,
            id: key,
            name: key,
            label: label,
            defaultValue: value,
            onChange: this.handleSetFormData,
        }

        return (
            disabled ? <Typography>{value}</Typography> : <TextBox {...config} className={className}/>
        )
    }

    renderValidationError = (key, ...args) => {
        let validation = false;
        const msg = Message.userRegist[key];

        if (Validation.empty(args)) {
            validation = this.stores.validation.getData(key);
        } else {
            const check1 = this.stores.validation.getData(args[0]);
            const check2 = this.stores.validation.getData(args[1]);

            validation = check1 || check2;
        }

        return (
            <>
                {
                    validation &&
                    <Grid container>
                        <Typography color={'red'}>{msg}</Typography>
                    </Grid>
                }
            </>
        )
    }

    render() {
        const { classes, modify } = this.props;
        const validation = this.stores.validation.getData();
        const loginUser = this.stores.login.getData();

        return (
            <Grid item xs={12} className="column-shadow" classes={classes}>
                <Grid container direction="row" className="form">
                    <Grid item md={4} className="form_title_cell">
                        {'氏名'} {this.renderRequireMsg()}
                    </Grid>
                    <Grid item md={8} className="form_input_cell">
                        {this.renderInputComponent('name_sei', 'text', '姓', 'w45')}
                        {this.renderInputComponent('name_mei', 'text', '名', 'w45')}
                        {this.renderValidationError('name', 'name_sei', 'name_mei')}
                    </Grid>

                    <Grid item md={4} className="form_title_cell">
                        {'氏名カナ'} {this.renderRequireMsg()}
                    </Grid>
                    <Grid item md={8} className="form_input_cell">
                        {this.renderInputComponent('name_kana_sei', 'text', 'セイ', 'w45')}
                        {this.renderInputComponent('name_kana_mei', 'text', 'メイ', 'w45')}
                        {this.renderValidationError('nameKana', 'name_kana_sei', 'name_kana_mei')}
                    </Grid>

                    <Grid item md={4} className="form_title_cell">
                        {'ID(メールアドレス)'} 
                        {
                            !loginUser && this.renderRequireMsg()
                        }
                    </Grid>
                    <Grid item md={8} className="form_input_cell">
                        {this.renderInputComponent('email', 'url', 'メールアドレス', 'w100')}
                        {validation.emailNotmatch ? this.renderValidationError('emailNotmatch') : this.renderValidationError('email')}
                    </Grid>

                    <Grid item md={4} className="form_title_cell">
                        {'パスワード'} {!modify && this.renderRequireMsg()}
                    </Grid>
                    <Grid item md={8} className="form_input_cell">
                        {this.renderInputComponent('pw', 'password', 'パスワード', 'w100')}
                        {validation.passwdNotmatch ? this.renderValidationError('passwdNotmatch') : this.renderValidationError('pw')}
                    </Grid>

                    <Grid item md={4} className="form_title_cell">
                        {'パスワード確認'} {!modify && this.renderRequireMsg()}
                    </Grid>
                    <Grid item md={8} className="form_input_cell mb_none">
                        {this.renderInputComponent('pw_confirm', 'password', 'パスワード確認', 'w100')}
                        {validation.passwdNotmatch ? this.renderValidationError('passwdNotmatch') : this.renderValidationError('pw_confirm')}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(style)(FormDefaultUserInfo);
