import React from 'react';
import { inject, observer } from 'mobx-react';

import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import FormDefaultUserInfo from '../../component/user/FormDefaultUserInfo';
import FormFileUpload from '../../component/user/FormFileUpload';
import ErrorMsg from '../../component/common/ErrorMsg';

import model from '../../model/user/UserModify';
import CustomUtil from '../../model/common/CustomUtil';
import msg from '../../constants/Message';
import Validation from '../../model/common/Validation';
import BackDrop from '../../component/common/BackDrop';
import CustomModal from '../../component/common/CustomModal';
import Auth from '../../model/common/Auth';
import UserRegister from '../../constants/form/UserRegister'

import {
    Grid,
    Typography,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';


// override style
const style = {
    root:{
        '& .thirdWidth' : {
            minWidth : '32.5%'
        },
        '& .halfWidth': {
            width : '49%'
        },
        '& .maxWidth': {
            minWidth : '100%'
        }
    }
}

// TODO ::: 디자인 조절하기
// TODO ::: 유효성 검사 설정하기

@inject('stores')
@observer
class UserModify extends React.Component {
    constructor(props){
        super(props);

        if (Auth.checkMember(window.location.href)) {
            window.location.href = '/login';
            this.render = () => (<></>)
        }

        this.state = {
            isLoad: false,
            errorView: false,
            backDropView: false,
        }

        this.stores = props.stores;

        const initData = CustomUtil.deepCopy(UserRegister);
        const validation = {}
        for (const item of Object.keys(initData)) {
            validation[item] = false;
        }

        this.stores.validation.resetData(validation);
    }

    componentDidMount = async() => {
        const userIdx = this.stores.login.getData('idx');
        const userInfo = await model.getData({userIdx});
        this.stores.form.resetData(userInfo.data);
        this.setState({
            isLoad: true
        })
    }

    setFormData = (key, value) => {
        this.props.formData[key] = value;
    }

    getFormData = (key) => {
        return this.props.formData[key];
    }

    checkValidate = () => {
        const result ={};
        const checkField = ['name_sei', 'name_mei', 'name_kana_sei', 'name_kana_mei', 'email', 'pw', 'pw_confirm'];
        const formData = this.stores.form.getData();

        // check empty
        for (const item of checkField) {
            if (Validation.empty(formData[item])) {
                result[item] = true;
            } else {
                result[item] = false;
            }
        }

        // check email
        const email = formData['email'];
        if (Validation.email(email)) {
            result['email'] = true;
        }

        // check password
        const pw = formData['pw'];
        const pw_confirm = formData['pw_confirm'];
        if(Validation.empty(pw) && Validation.empty(pw_confirm)){
            result['pw'] = false;
            result['pw_confirm'] = false;
        }else{
            if (pw !== pw_confirm) {
                result['pw'] = true;
                result['pw_confirm'] = true;
                result['passwdNotmatch'] = true;
            }
        }

        const check = Object.values(result).find(item => item) || false;
        this.stores.validation.resetData(result);
        return check;
    }

    submitForm = () => {
        if (this.checkValidate()) {
            this.setState({
                errorView: true,
            })

            CustomUtil.scrollTarget();
            return;
        }

        this.setState({
            errorView: false,
            backDropView: true,
        })

        const formData = this.stores.form.getData()
        model.submit(formData).then((res) => {
            alert(res.data.message);
            window.location.reload()
        })
    }

    render() {
        const { classes} = this.props;
        const { errorView, isLoad } = this.state;

        if(!isLoad){
            return <></>
        }

        return (
            <>
                <Grid className={classes.root}>
                    <CommNav />
                    <Grid className='content'>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item md={11}>
                                {
                                    errorView &&
                                    <ErrorMsg message={msg.validation.dataErr} />
                                }
                                <Grid item xs={12}>
                                    <center>
                                        <Typography variant="h2" className="content_title">{'管理者情報修正'}</Typography>
                                    </center>
                                </Grid>

                                <FormDefaultUserInfo modify/>
                                <FormFileUpload />

                                <Grid className="mt_30" item xs={12}>
                                    <center>
                                        <Button variant="contained" size="large" onClick={this.submitForm}>修正</Button><Typography variant="span">&nbsp;&nbsp;</Typography>
                                    </center>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <CommFooter />
                </Grid>
                <BackDrop open={this.state.backDropView} />
            </>
        );
    }
}

export default withStyles(style)(UserModify);