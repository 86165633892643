import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";
import m_AgendaDetl from '../../model/manager/AgendaDetl'

class EntryDetl {
    getData = (agendaIdx) => {
        const formData = {
            mode: 'user',
            agenda_idx: agendaIdx
        }

        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.itemDetl, params, headers).then(res => {
            return res.data;
        });

    }

    agendaJoinChecked = async(email, agendaIdx) => {
        const formData = {
            email: email,
            agenda_idx: agendaIdx
        }

        const data = await m_AgendaDetl.getApplicantData(formData);
        let checkJoined = false;

        for(const item of Object.values(data.list)){
            if(email === item.email){
                checkJoined = true;
                break;
            }
        }
        return checkJoined;
    }

    dataProcess = (obj) => {
        obj.recruitment_type = CustomUtil.getRecruitmentType(obj.recruitment_type);
        obj.emp_status = CustomUtil.getEmpStatus(obj.emp_status);
        obj.location = CustomUtil.getNeedWorkLocationCity(obj.location, obj.nearest_station)
        obj.language = CustomUtil.getLanguage(obj.language);
        obj.hitch = CustomUtil.getHitch(obj.hitch);
        return obj;
    }

    joinAgenda = (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.joinagenda, params, headers)
    }

    addAgendaHit = (formdata) => {
        const params = CustomUtil.getAxiosFormData(formdata);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.agendaHit, params, headers)
    }
}

export default new EntryDetl();