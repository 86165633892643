import { action, autorun, makeObservable, observable } from "mobx"
import util from "../model/common/CustomUtil";

class Login {
  @observable data = null;

  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @action
  initLogin = autorun(() => {
    try {
      const session = util.getSessionItem('session');
      if (session !== null) {
        this.data = JSON.parse(util.decrypt(session))
      }
    } catch (err) {
      console.err('Login Store', err);
    }
  });

  @action
  setData = (key, value) => {
    this.data[key] = value;
  }

  @action
  setAllData = (obj) => {
    this.data = obj
  }

  @action
  init = () => {
    this.data = null;
  }

  getData = (key) => {
    try {
      return key ? this.data[key] : this.data;
    } catch (e) {
      return '';
    }
  }

}

export default Login;
