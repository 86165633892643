import React from 'react';
import Modal from '../../component/common/CustomModal';

import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';

import Validation from '../../model/common/Validation';
import ErrorMsg from '../../component/common/ErrorMsg';
import Message from '../../constants/Message';

import model from '../../model/common/PasswdReset';
import util from '../../model/common/CustomUtil';
import BackDrop from '../../component/common/BackDrop';

import Typography from '@mui/material/Typography';
import {
    Grid,
    Container,
    Button,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import { inject } from 'mobx-react';

// override style
const style = {}

@inject('stores')
class PasswordForgotStep3 extends React.Component {
    state = {
        errorView: false,
        errorMsg: null,
        modalView: false,
        backDropView: false,
    }

    pwReset = this.props.stores.passwordForgot;
    errorMsg = null;

    setFormData = (e) => {
        if (!Validation.empty(e.target.value)) {
            this.props.formData[e.target.name] = e.target.value;
        }
    }

    hideErrorBox = () => {
        this.setState({ errorView: false });
    }

    showErrorBox = (msg) => {
        this.setState({
            errorView: true,
            errorMsg: msg
        });
    }

    showModal = () => {
        this.setState({
            modalView: true,
            errorView: false
        });
    }

    closeModal = () => {
        util.delSessionItem('pwReset');
        window.location.href = '/';
    }

    process = () => {
        const formData = this.props.formData;
        if (Validation.empty(formData.pw)) {
            this.showErrorBox(Message.validation.passwordForgot3.empty);
            return;
        }

        if (formData.pw !== formData.pw_confirm) {
            this.showErrorBox(Message.validation.passwordForgot3.notMatch);
            return;
        }

        if (Validation.pw(formData.pw)) {
            this.showErrorBox(Message.validation.passwordForgot3.regExpErr);
            return;
        }

        const data = {
            ...formData, 
            ...this.pwReset.data
        }

        this.setState({
            backDropView: true,
        });
        model.step3(data).then((res) => {
            this.setState({
                backDropView: false,
            });
            if (res.data.status === "success") {
                this.showModal();
            } else {
                this.showErrorBox(Message.error);
            }
        });
    }

    render() {
        return (
            <>
                <Modal message={Message.success} onClose={this.closeModal} open={this.state.modalView} />
                <Grid style={{ position: 'relative' }}>
                    <CommNav />
                    <Grid className="content">
                        <Container>
                            <Grid container direction="row" justifyContent="center" alignItems="center">

                                <Grid item xs={12}>
                                    <center>
                                        <Typography variant="h2" className="content_title">パスワードリセット</Typography>
                                        <Typography vartiant="p" className="content_desc">
                                            パスワードを再設定してください。
                                        </Typography>
                                    </center>
                                </Grid>

                                {
                                    this.state.errorView &&
                                    <ErrorMsg message={this.state.errorMsg} />
                                }

                                <Grid item xs={12} className="column-shadow">
                                    <Grid className="form" container direction="row">
                                        <Grid item md={4} className="form_title_cell">パスワード</Grid>
                                        <Grid item md={8} className="form_input_cell">
                                            <input className="cellwidth10 post-data" type="password" placeholder="パスワード(半角英数字6~20文字)" name="pw" onBlur={this.setFormData} onFocus={this.hideErrorBox} />
                                        </Grid>

                                        <Grid item md={4} className="form_title_cell mb_none">パスワード確認</Grid>
                                        <Grid item md={8} className="form_input_cell mb_none">
                                            <input className="cellwidth10 post-data" type="password" placeholder="パスワード(半角英数字6~20文字)" name="pw_confirm" onBlur={this.setFormData} onFocus={this.hideErrorBox} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <center>
                                        <Button className="ybtn" onClick={this.process}>再設定</Button>
                                    </center>
                                </Grid>

                            </Grid>
                        </Container>
                    </Grid>
                    <CommFooter />
                </Grid>
                <BackDrop open={this.state.backDropView} />
            </>
        );
    }
}

PasswordForgotStep3.defaultProps = {
    formData: {}
};

export default withStyles(style)(PasswordForgotStep3);