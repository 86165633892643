import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Main from '../page/user/Main';
import Login from '../page/user/Login';
import Mypage from '../page/user/Mypage';
import Regist from '../page/user/Regist';
import Search from '../page/user/Search';
import AgendaDetl from '../page/user/EntryDetl';
import PasswordForgot from '../page/user/PasswordForgot';
import PasswordForgot2 from '../page/user/PasswordForgotStep2';
import PasswordForgot3 from '../page/user/PasswordForgotStep3';
import Inquiry from '../page/user/Inquiry';
import Siteinfo from '../page/user/Siteinfo';
import DisplayUserInfo from "../page/manager/DisplayUserInfo";
import DisplayAgendaInfo from "../page/manager/DisplayAgendaInfo";
import FAQ from '../page/common/FAQ';
import Accept from '../page/common/Accept';
import Agreement from '../page/common/Agreement';
import Policy from '../page/common/Policy';
import Privacy from '../page/common/Privacy';
import Sitemap from '../page/common/Sitemap';
import NeedLogin from '../page/common/NeedLogin';
import NotFoundPage from '../page/common/NotFoundPage';

import AgendaList from '../page/manager/AgendaList';
import UserList from '../page/manager/UserList';
import AdminModify from '../page/manager/UserModify';
import UserFaqList from '../page/manager/UserFaqList';

import TestPage from '../page/TestPage';

class Router extends React.Component {
    render() {
        return (
            <Routes>
                {/* 유저 접근 페이지 */}
                <Route path="/" >
                    <Route path="/" element={<Main/>} />
                    <Route path="login" element={<Login/>} />
                    <Route path="mypage" element={<Mypage/>} />
                    <Route path="regist" element={<Regist/>} />
                    <Route path="search" element={<Search/>} />
                    <Route path="search/:agendaIdx" element={<Search/>} />
                    <Route path="agendaDetl" element={<AgendaDetl/>} />
                    <Route path="passwordForgot" element={<PasswordForgot/>} />
                    <Route path="passwordForgot2" element={<PasswordForgot2/>} />
                    <Route path="passwordForgot3" element={<PasswordForgot3/>} />
                    <Route path="inquiry" element={<Inquiry/>} />
                    <Route path="siteinfo" element={<Siteinfo/>} />

                    <Route path="agreement" element={<Agreement/>} />
                    <Route path="accept" element={<Accept/>} />
                    <Route path="policy" element={<Policy/>} />
                    <Route path="privacy" element={<Privacy/>} />
                    <Route path="FAQ" element={<FAQ/>} />
                    <Route path="FAQ/:tabkey" element={<FAQ/>} />
                    <Route path="sitemap" element={<Sitemap/>} />
                    <Route path="needLogin" element={<NeedLogin/>} />
                </Route>

                {/* 관리자 접근 페이지 */}
                <Route path="/mg">
                    <Route path="agendaList" element={<AgendaList/>} />
                    <Route path="userList" element={<UserList/>} />
                    <Route path="mypage" element={<AdminModify/>} />
                    <Route path="userFaq" element={<UserFaqList/>} />
                    <Route path="displayUserInfo/:userIdx/:agendaIdx" element={<DisplayUserInfo />}/>
                    <Route path="displayAgendaInfo/:agendaIdx/:authKey" element={<DisplayAgendaInfo />} />
                </Route>

                {/* 테스트 관련 라우팅 */}
                { process.env.REACT_APP_MODE === 'local' && <Route path="/test" element={<TestPage/>} /> }

                {/* 기타 */}
                
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        );
    }
}

export default Router;