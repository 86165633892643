import React from 'react';
import { withStyles } from '@mui/styles';
import {
    TextField,
} from '@mui/material';

// override style
const style = {}

class TextBox extends React.Component {

    renderTextBox = () => {
        const { label, onChange, helperText, defaultValue, error, id, type, inputProps, className, name } = this.props;
        return (
            <TextField
                type={type}
                error={error}
                id={id}
                name={name}
                label={label}
                defaultValue={defaultValue}
                helperText={helperText}
                onChange={onChange}
                inputProps={inputProps}
                className={className}
            />
        )
    }

    renderSelectBox = () => {
        const { value, onChange, helperText, label, listData, id, error, inputProps, className, name } = this.props;
        return (
            <TextField
                select
                id={id}
                name={name}
                label={label}
                value={value}
                SelectProps={{
                    native: true,
                }}
                inputProps={inputProps}
                helperText={helperText}
                variant="outlined"
                error={error}
                onChange={onChange}
                className={className}
            >
                <option value=""></option>
                {
                    listData.map((item, index) => (
                        <option value={item.value} key={index}>{item.key}</option>
                    ))
                }

            </TextField>
        )
    }

    render() {
        const { type } = this.props;
        return (
            <>
                {
                    type === 'select' ? this.renderSelectBox() : this.renderTextBox()
                }
            </>
        );
    }
}

export default withStyles(style)(TextBox)