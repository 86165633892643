import React, { Suspense } from 'react';
import { inject, observer } from 'mobx-react';
import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import { Link } from 'react-router-dom';
import {
    Grid,
    Container,
    MenuList,
    MenuItem,
    Typography
} from '@mui/material';

import { withStyles } from '@mui/styles';
import Auth from '../../model/common/Auth';
import CustomUtil from '../../model/common/CustomUtil';
import BackDrop from '../../component/common/BackDrop';

// override style
const style = {
    root:{
    }
}

@inject('stores')
@observer
class Mypage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuKind: 'modify'
        }

        this.login = props.stores.login;

        if (Auth.checkMember(window.location.href)) {
            window.location.href = '/login';
            this.render = () => (<></>)
        }

        this.menuList = {
            'modify': React.lazy(() => import('../user/Modify')),
            'entrylist': React.lazy(() => import('../user/EntryList')),
        }
    }

    changeMenu = (e) => {
        this.setState({ menuKind: e.target.dataset.value })
    }

    renderLeftNavi = () => {
        const { menuKind } = this.state;
        const menuItems = {
            'modify': '会員情報修正',
            'entrylist': '応募した案件一覧',
        };
        return (
            <Grid item md={3} className="mypage_wrap left_menu">
                <Grid className="column-shadow">
                    <Grid className="mypage_menu">
                        <MenuList>
                            {
                                Object.keys(menuItems).map((key) => {
                                    const currentMenu = menuKind === key ? 'current' : '';
                                    return (
                                        <MenuItem key={key}>
                                            <Link to="/mypage" className={`${currentMenu}`} data-value={key} onClick={this.changeMenu}>{menuItems[key]}</Link>
                                        </MenuItem>
                                    )
                                })
                            }
                        </MenuList>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    renderSidePage = () => {
        const { menuKind } = this.state;
        const Component = this.menuList[menuKind]
        return (
            <Suspense fallback={<BackDrop open={true} />}>
                <Component mypage />
            </Suspense>
        )
    }

    render() {
        CustomUtil.scrollTarget();
        const isAdmin = this.login.getData('is_admin');
        return (
            <Grid style={{ position: 'relative' }}>
                <Grid item xs={12}>
                    <center>
                        <Typography variant="h2" className="content_title">{''}</Typography>
                    </center>
                </Grid>
                <CommNav />
                <Grid className="content">
                    <Container>
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>
                            {
                                isAdmin === 'N' && this.renderLeftNavi()
                            }

                            <Grid item md={9} className="mypage_wrap">
                                <Grid className={'mypage_sp_agenda'}>
                                    {this.renderSidePage()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <CommFooter />
            </Grid>
        );
    }
}

export default withStyles(style)(Mypage);