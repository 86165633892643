const data = [
    {
        key: '会員',
        value: 'N',
    },
    {
        key: '管理者',
        value: 'Y',
    },
];

export default data;