import React from 'react';
import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import Typography from '@mui/material/Typography';
import CustomUtil from '../../model/common/CustomUtil';
import {
    Grid,
    Container,

} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {}

class Agreement extends React.Component {
    render() {
        CustomUtil.scrollTarget();
        return (
            <>
                <Grid style={{ position: 'relative' }}>
                    <CommNav />
                    <Grid className="content">
                        <Container>
                            <Typography variant="h2" className="content_title">利用規約</Typography>
                            <Grid item xs={12} className="column-shadow2">
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第1条（適用）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    <ul className="list_style">
                                        <li>本規約は、本サービスの提供条件及び本サービスの利用に関する当社と利用者との間の権利義務関係を定めることを目的とし、利用者と当社との間の本サービスの利用に関わる一切の関係に適用されます。</li>
                                        <li>本サイトを利用している利用者は、本規約に同意したものとみなします。</li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第2条（定義）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。<br/>
                                    <ul className='list_style'>
                                        <li>
                                        「サービス利用契約」とは、本規約及び当社と利用者の間で締結する、本サービスの利用契約を意味します。<br/>
                                        </li>
                                        <li>
                                        「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。<br/>
                                        </li>
                                        <li>
                                        「投稿データ」とは、利用者が本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含む）を意味します。<br/>
                                        </li>
                                        <li>
                                        「当社」とは、株式会社MINOSYSを意味します。<br/>
                                        </li>
                                        <li>
                                        「本サービス」とは、株式会社MINOSYSが運営する人材紹介サービス及びそれに関連するサービスの総称を意味します。<br/>
                                        </li>
                                        <li>
                                        「利用者」とは、第3条に基づいて本サービスの利用者としての登録がなされた個人を意味し、本サービスを利用する全ての方を意味します。<br/>
                                        </li>
                                        <li>
                                        「利用企業」とは、当社に対して案件と人材のマッチングを委託した企業をいいます。<br/>
                                        </li>

                                    </ul>

                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第3条（サービス利用登録）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    <ul className='list_style'>
                                        <li>本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。<br/></li>
                                        <li>
                                            当社は、当社の基準に従って、前項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。<br/>
                                            登録申請者の利用者としての登録は、当社が本項の通知を行ったことをもって完了したものとします。<br/>
                                        </li>
                                        <li>前項に定める登録の完了時に、サービス利用契約が利用者と当社の間に成立し、利用者は本サービスを本規約に従い利用することができるようになります。<br/></li>
                                        <li>
                                        当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。<br/>
                                            <ul className='list_style2'>
                                                <li>当社に提供した登録事項の全部又は一部につき虚偽、誤記又は記載漏れがあった場合<br/></li>
                                                <li>未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合<br/></li>
                                                <li>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合<br/></li>
                                                <li>登録希望者が過去当社との契約に違反した者又はその関係者であると当社が判断した場合<br/></li>
                                                <li>第6条に定める措置を受けたことがある場合<br/></li>
                                                <li>その他、当社が登録を適当でないと判断した場合</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第4条（利用者責任）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    <ul className='list_style'>
                                        <li>
                                            利用者は、当社及び利用企業に対し、個人情報を不備・齟齬のないよう正確に提供するものとします。<br/>
                                            利用者が提供した個人情報が正確でなかったこと、及び、その内容の不備・齟齬などに起因して利用企業、その他の第三者から何らかの異議、請求若しくは要求などがなされた場合には、自己の費用負担と責任で対処するものとし、当社に一切の迷惑をかけないことを保証するものとします。<br/>
                                        </li>
                                        <li>利用者は、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。<br/></li>
                                        <li>
                                            利用者は、当社が求めた場合には、以下の書類を速やかに提出するものとします。<br/>
                                            <ul className='list_style2'>
                                                <li>氏名・住所等、利用者であることを特定・確認するための本人確認書類<br/></li>
                                                <li>在留資格を確認するための在留カード又は就労資格証明書等<br/></li>    
                                                <li>その他利用企業が提出を求める証明書類</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第5条（サービス提供）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    当社は、以下のサービスの中から利用者に適切なサービスを当社の判断で提供するものとします。
                                    <br/>
                                    <ul className='list_style'>
                                        <li>利用者から受領したお申込み内容と利用企業から受領する求人条件との照合、並びに照合結果に基づく求人情報の提供<br/></li>
                                        <li>利用企業から受領する求人条件に適合度が高いと当社が判断する利用者に対する求人応募勧誘<br/></li>
                                        <li>応募手続きの代行<br/></li>
                                        <li>電話や面談によるマッチング相談の実施<br/></li>
                                        <li>その他利用者に有益と当社が判断する一切のサービス</li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第6条（禁止行為及び登録の抹消）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    利用者が以下のいずれかに該当した場合は、当社の判断にて登録の取り消しを行ない、本サービスの提供を終了する場合があります。これらの措置に対し、当社は何らの責任も負いません。<br/>
                                    また利用者はこれらの措置に対し、いかなる異議も申し立てることができません。
                                    <br/>
                                    <ul className='list_style'>
                                        <li>本規約の定めに違反した場合<br/></li>
                                        <li>登録事項に虚偽の事実があることが判明した場合<br/></li>
                                        <li>支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合<br/></li>
                                        <li>6ヶ月以上本サービスの利用がない場合<br/></li>
                                        <li>当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合<br/></li>
                                        <li>その他、当社が本サービスの利用、利用者としての登録、又はサービス利用契約の継続を適当でないと判断した場合</li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第7条（本サービスの停止又は終了）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    <ul className='list_style'>
                                        <li>
                                            当社は、一定の予告期間をもって利用者に通知のうえ、本サービスの提供を長期的に中断もしくは終了することができます。<br/>
                                            但し、やむを得ない事情がある場合は、予告を行なわない場合があります。<br/>
                                        </li>
                                        <li>当社は、前項に基づき当社が行った措置に起因して発生した損害について、一切の責任を負いません。</li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第8条（権利帰属）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    <ul className='list_style'>
                                        <li>本サイト及び本サービスに関する知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本サイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。<br/></li>
                                        <li>利用者は、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。<br/></li>
                                        <li>利用者は、投稿データについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他の利用者に対しても、本サービスを利用して利用者が投稿その他送信した投稿データの使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。<br/></li>
                                        <li>利用者は、当社及び当社から権利を承継し又は許諾された者に対して著作者人格権を行使しないことに同意するものとします。</li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第9条（退会）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    <ul className='list_style'>
                                        <li>利用者は、当社所定の方法で当社に通知することにより、本サービスから退会し、自己の利用者としての登録を抹消することができます。<br/></li>
                                        <li>退会後の個人情報の取扱いについては、第17条の規定に従うものとします。</li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第10条（サービス内容の変更等）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    <ul className='list_style'>
                                        <li>当社は、当社の都合により、本サービスの内容を変更し、又は提供を終了することができます。<br/></li>
                                        <li>当社が本サービスの提供を終了する場合、当社は利用者に事前に通知するものとします。<br/></li>
                                        <li>当社は、前項に基づき当社が行った措置に起因して発生した損害について、一切の責任を負いません。</li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第11条（不保証と免責）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    <ul className='list_style'>
                                        <li>当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令又は業界団体の内部規則等に適合すること、及び不具合が生じないことについて、何ら保証するものではありません。<br/></li>
                                        <li>当社は、利用者から受領したお申込み内容と利用企業が希望する求人条件とを照合しますが、検討基準や判断理由などをお伝えすることはできません。また、利用者から求人への応募依頼を受け付けた場合であっても、利用企業より示された選考基準などを参考にして当該求人への適合度合いを判断した結果、当社から当該求人への推薦を行わない場合や、利用企業に代わり求人条件に適合しない旨のご連絡をする場合があります。<br/></li>
                                        <li>当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、利用者が本サービスに送信したメッセージ又は情報の削除又は消失、利用者の登録の抹消、本サービスの利用による登録データの消失又は機器の故障若しくは損傷、その他本サービスに関して利用者が被った損害（以下「利用者損害」といいます。）につき、賠償する責任を一切負わないものとします。<br/></li>
                                        <li>本サービス又は本サイトに関連して利用者と他の利用者又は第三者との間において生じた取引、連絡、紛争等については、当社は一切責任を負いません。</li>
                                    </ul>
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第12条（反社会的勢力の排除）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    利用者は、反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者をいいます。）に該当しないこと、また暴力的行為、詐術・脅迫行為、業務妨害行為等違法行為を行わないことを、将来にわたっても表明するものとします。<br/>
                                    かかる表明に違反した場合には、異議なく本サービスの提供の終了を受け入れるものとします。
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第13条（機密保持）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    利用者は、本サービスに関連して当社が利用者に対して秘密に取り扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第14条（個人情報の取扱い）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    当社による利用者の個人情報の取扱いについては、「個人情報の取扱い」の定めによるものとし、利用者はこの「個人情報の取扱い」に従って当社が利用者情報を取り扱うことについて同意するものとします。
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第15条（損害賠償責任）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    利用者が本規約の各事項に違反して、当社、または第三者に損害を与えた場合、利用者はその損害を賠償するものとします。
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第16条（本規約の変更）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    当社は、本規約を変更できるものとします。当社は、本規約を変更した場合には、利用者に当該変更内容を通知するものとし、当該変更内容の通知後、利用者が本サービスを利用した場合又は当社の定める期間内に登録抹消の手続をとらなかった場合には、利用者は、本規約の変更に同意したものとみなします。
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第17条（分離可能性）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    本規約の一部が法令等により無効と判断された場合であっても、残部については、継続して効力を有するものとします。
                                </Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">&nbsp;</Typography>
                                <Typography variant="h3" className="entry_desc_detail_title">■ 第18条（準拠法及び管轄裁判所）</Typography>
                                <Typography variant="p" className="entry_desc">
                                    <ul className='list_style'>
                                        <li>本利用規約は日本法に基づき解釈されるものとし、本利用規約の一部が無効な場合でも、適用可能な項目については効力があるものとします。<br/></li>
                                        <li>
                                            本サービスに関連して訴訟等の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
                                        </li>
                                    </ul>
                                    

                                </Typography>
                                
                            </Grid>
                        </Container>
                    </Grid>
                    <CommFooter />
                </Grid>
            </>
        );
    }
}

export default withStyles(style)(Agreement);