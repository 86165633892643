const data = [
    {
        area: 'kantou',
        key: '東京都',
        value: 'KAN10',
    },
    {
        area: 'kantou',
        key: '神奈川県',
        value: 'KAN20',
    },
    {
        area: 'kantou',
        key: '埼玉県',
        value: 'KAN30',
    },
    {
        area: 'kantou',
        key: '千葉県',
        value: 'KAN40',
    },
    {
        area: 'kyusyu',
        key: '福岡県',
        value: 'KYU10',
    }
]

export default data;