import React from 'react';
import { inject, observer } from 'mobx-react';
import model from '../../model/manager/MemberEvaluation';
import CustomUtil from '../../model/common/CustomUtil';

import {
    Grid,
    Button,
    TextareaAutosize,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
} from '@mui/material';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import DisabledByDefaultTwoToneIcon from '@mui/icons-material/DisabledByDefaultTwoTone';

import { withStyles } from '@mui/styles';
import Validation from '../../model/common/Validation';

// override style
const style = {
    root : {
        padding: '4rem 1rem',
        '& .member-evaluation-table':{
            borderTop : '2px solid #E1E1E1',
            marginTop : '1rem',
            '& td':{
                padding: '8px 0',
                verticalAlign: 'middle', 
                width: '100px',
                '& .icons': {
                    verticalAlign: 'middle', 
                    marginLeft: '5px'
                },
            },
            '& td:nth-child(2)':{
                width: '70%',
            },
            '& td:nth-child(4)':{
                width: '24px',
                cursor: 'pointer'
            },
            '& .create-time, .create-user': {
                width: '70px'
            }
        },
        '& .comment-form': {
            alignItems: 'center',
            padding: '1rem 0rem',
            '& .input-box' : {
                minWidth: '80%',
                minHeight: '100px',
                boxSizing : 'border-box',
            },
            '& .input-button' : {
                minWidth: '18%',
                minHeight: '100px',
            }, 
        },
        '& .title': {
            fontSize: '1.2rem'
        }
    }
}

@inject('stores')
@observer
class MemberEvaluation extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            comment : '', 
            list : [],
        }
    }

    componentDidMount = () => {
        this.getUserEvaluationData();
    }

    getUserEvaluationData = () => {
        const { userIdx, agendaIdx } = this.props;
        model.getData({
            userIdx : userIdx, 
            agendaIdx : agendaIdx || ''
        }).then(res => {
            this.setState({
                list : res.data
            })
        })
    }

    setFormData = (e) => {
        this.setState({
            comment: e.target.value
        })
    }
    
    handleSubmit = () => {
        const adminUser = this.props.stores.login.getData('idx');
        const { userIdx } = this.props;
        const { comment } = this.state;

        if(Validation.empty(comment)){
            alert('コメントを記入してください。')
            return false;
        }

        model.setData({
            user_idx : userIdx, 
            admin_idx : adminUser, 
            comment: comment
        }).then(res => {
            this.getUserEvaluationData();
            if(res.data.status === 'success'){
                this.setState({
                    comment : ''
                })
                alert('処理が完了されました。');
            }else{
                alert(res.data.message);
            }
        })
    }

    handleDeleteComment = (idx) => {
        if(window.confirm('削除しますか？')){
            model.deleteData({
                idx : idx, 
            }).then(res => {
                if(res.data.status === 'success'){
                    this.getUserEvaluationData();
                    alert('処理が完了されました。');
                }else{
                    alert(res.data.message);
                }
            })
        }
    }

    renderEvaluationTable = () => {
        const { list } = this.state;
        return (
            <Table className={'member-evaluation-table'}>
                <TableBody>
                    {
                        list.map((item, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align={'center'} className={'name'}>
                                        {item.adminName}
                                        <TextsmsOutlinedIcon className={'icons'}/>
                                    </TableCell>
                                    <TableCell>{item.comment}</TableCell>
                                    <TableCell align={'center'} >{CustomUtil.getDateTime(item.create_at, 'YYYY/MM/DD HH:mm')}</TableCell>
                                    <TableCell align={'center'} >
                                        <DisabledByDefaultTwoToneIcon className={'icons'} onClick={() => this.handleDeleteComment(item.idx)}/>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        )
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid classes={classes}>
                <Grid container justifyContent={'center'}>
                    <Typography className="title">{'応募者についてコメント'}</Typography>
                </Grid>
                { this.renderEvaluationTable()}
                <Grid container justifyContent={'center'} className={'comment-form'}>
                    <TextareaAutosize className={'input-box'} value={this.state.comment} onInput={this.setFormData} placeholder={'応募者に対する評価等を記入してください。'}/>
                    <Button className={'input-button'} variant="contained" color="primary" onClick={this.handleSubmit}>{'応募者を評価する'}</Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(MemberEvaluation);