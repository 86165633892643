import React from 'react';
import RegistForm from '../../component/manager/RegistForm';
import Auth from '../../model/common/Auth';
import model from '../../model/manager/AgendaRegister';
import { inject, observer } from 'mobx-react';
import Validation from '../../model/common/Validation';
import CustomUtil from '../../model/common/CustomUtil';
import BackDrop from '../../component/common/BackDrop';

import {
    Grid,
    Button,
    Typography,
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {}

@inject('stores')
@observer
class AgendaRegister extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            errorView: false,
            backDropView: false,
            showModal: false,
            modalMsg: null,
        }
        this.stores = props.stores;
    }

    componentWillUnmount = () => {
        const { formData } = this.props
        const result = {};
        for (const item of Object.entries(formData)) {
            result[item[0]] = false;
        }

        this.stores.validation.resetData(result)
    }

    checkValidate = () => {
        let check = false;
        const result = {};

        const { formData } = this.props;
        const workLocationParts = ['location', 'city', 'nearest_station'];

        // check empty
        for (const item of Object.entries(formData)) {
            if (Validation.empty(formData[item[0]])) {
                if(item[0] ==='fw'|| item[0] ==='db'){
                    result[item[0]] = false;
                }else{
                    result[item[0]] = true;
                    check = true;
                }
            } else {
                if(workLocationParts.indexOf(item[0]) > -1){
                    result[item[0]] = formData.location.length !== formData.city.length || formData.location.length !== formData.nearest_station.length;;
                }else{
                    result[item[0]] = false;
                }
            }
        }

        this.stores.validation.resetData(result)
        return check;
    }

    submit = () => {
        if (this.checkValidate()) {
            this.setState({
                errorView: true,
            })
            alert("必須項目は必ず、入力してください。");
            CustomUtil.scrollTarget();
            return;
        }

        this.setState({
            errorView: false,
            backDropView: true,
        })

        model.submit(this.props.formData).then((res) => {
            alert(res.data.message);
            this.setState({
                backDropView: false,
            })

            window.location.reload(true)
        })
    }

    setFormData = (key, value) => {
        this.props.formData[key] = value;
    }

    getFormData = (key) => {
        return this.props.formData[key];
    }

    modalClose = () => {
        window.location.href = '/mg/agendaList';
    }

    render() {
        if (Auth.checkAdmin()) {
            window.location.href = '/';
            return (<></>);
        }

        return (
            <>
                <Grid className="content mt_none bg_gray">
                    <Grid>
                        <Typography variant="h6" className="form_name mb_30">{'案件登録'}</Typography>
                    </Grid>

                    <RegistForm {...this.state} setFormDataFnc={this.setFormData} getFormDataFnc={this.getFormData}/>

                    <center>
                        <Button className="ybtn" onClick={this.submit}>{'登録'}</Button>
                    </center>
                    <Grid item xs className="mb_30"></Grid>
                </Grid>

                <BackDrop open={this.state.backDropView} />
            </>
        );
    }
}

AgendaRegister.defaultProps = {
    formData: {
        title: '',
        emp_status: [],
        recruitment_type: [],
        location: [],
        city : [],
        nearest_station : [],
        pay: '',
        language: [],
        hitch: [],
        contents: '',
        job_start_date: '',
        manager_message: '',
        keyword: '',
        db: '',
        fw: '',
        assign: '',
    }
};

export default withStyles(style)(AgendaRegister);