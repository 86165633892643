import React from 'react';

import model from '../../model/manager/AgendaModify';

import Typography from '@mui/material/Typography';
import {
    Grid,
    Button,
} from '@mui/material';
import { inject, observer } from 'mobx-react';
import RegistForm from '../../component/manager/RegistForm';
import Auth from '../../model/common/Auth';
import Validation from '../../model/common/Validation';

import { withStyles } from '@mui/styles';

// override style
const style = {}

@inject('stores')
@observer
class AgendaModify extends React.Component {
    state = {
        data: null
    }

    store = this.props.stores.form;
    registValidation = this.props.stores.validation;

    getData = async () => {
        const agendaIndex = this.store.getData('agendaIndex');
        model.getData(agendaIndex).then((res) => {
            const detlData = model.dataProcess(res.data);
            this.setState({
                data: detlData
            });
        });
    }

    componentDidMount() {
        this.getData();
    }

    setFormData = (key, value) => {
        this.props.formData[key] = value;
    }

    getFormData = (key) => {
        return this.props.formData[key];
    }

    checkValidate = () => {
        let check = false;
        const result = {};

        const { formData } = this.props;
        const workLocationParts = ['location', 'city', 'nearest_station'];

        // check empty
        for (const item of Object.entries(formData)) {
            if (Validation.empty(formData[item[0]])) {
                if(item[0] ==='fw'|| item[0] ==='db'){
                    result[item[0]] = false;
                }else{
                    result[item[0]] = true;
                    check = true;
                }
            } else {
                if(workLocationParts.indexOf(item[0]) > -1){
                    result[item[0]] = formData.location.length !== formData.city.length || formData.location.length !== formData.nearest_station.length;;
                }else{
                    result[item[0]] = false;
                }
            }
        }

        this.registValidation.resetData(result)
        return check;
    }

    submit = () => {
        const { nextPageFnc } = this.props;
        if (this.checkValidate()) {
            this.setState({
                errorView: true,
            })

            return;
        }

        model.submit(this.props.formData).then((res) => {
            alert(res.data.message);
            nextPageFnc('detl') 
        })
    }

    render() {
        if (Auth.checkAdmin()) {
            window.location.href = '/';
            return (<></>);
        }

        const { data } = this.state;
        const { nextPageFnc, onClose } = this.props;
        return (
            <Grid className="content mt_none bg_gray">
                <div className={'btn_close close-button'} onClick={onClose}>&times;</div>
                <Grid>
                    <Typography variant="h6" className="form_name mb_30">案件修正</Typography>
                </Grid>
                {
                    data !== null &&
                    <>
                        <RegistForm data={data} setFormDataFnc={this.setFormData} getFormDataFnc={this.getFormData} />
                    </>
                }

                <center>
                    <Grid className="btn_wrap">
                        <Button className="ybtn" onClick={this.submit}>修正</Button>
                        <Typography variant="span">&nbsp;&nbsp;</Typography>
                        <Button className="ybtn" onClick={() => { nextPageFnc('detl') }}>戻る</Button>
                    </Grid>
                </center>
                <Grid item xs className="mb_30"></Grid>
            </Grid>
        );
    }
}

AgendaModify.defaultProps = {
    formData: {}
};

export default withStyles(style)(AgendaModify);