import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';

import App from './page/App';
import RootStore from './store/Root';

// css
import './asset/css/normalize.css';
import './asset/css/common.css';
import './asset/css/main.css';
import './asset/css/admin.css';
import './asset/css/card.css';
import './asset/css/form.css';
import './asset/css/search.css';
import './asset/css/list.css';

const stores = new RootStore();
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider stores={stores}>
      <App />
    </Provider>
);
