import React from 'react';
import Modal from '../common/CustomModal';
import message from '../../constants/Message'
import moment from 'moment-timezone';
import util from '../../model/common/CustomUtil';
import { Grid, Typography } from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {}

class CountDown extends React.Component {
    timer = null;
    state = {
        countDown: '--:--',
        modalView: false
    }

    countDown = () => {
        const endTime = moment(this.props.endTime).tz('Asia/Tokyo');
        const startTime = moment().tz('Asia/Tokyo');
        const time = moment(endTime.diff(startTime));

        this.setState({
            countDown: time.format('mm:ss'),
            modalView: time.unix() < 1
        });

        if (time.unix() < 1) {
            clearInterval(this.timer);
        }
    }

    closeModal = () => {
        util.delSessionItem('pwReset');
        window.location.href = '/passwordForgot';
    }

    componentDidMount = () => {
        this.timer = setInterval(this.countDown, 1000);
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
    }

    render() {
        const timeOverMsg = message.validation.timeOver.replace('[A]', '認証');
        return (
            <Grid container alignContent="center" justifyContent="center">
                <Typography variant="h1">{this.state.countDown}</Typography>
                <Modal message={timeOverMsg} onClose={this.closeModal} open={this.state.modalView} />
            </Grid>
        )
    }
}

export default withStyles(style)(CountDown);