// 今の職業
const data = [
    {
        key: '正社員',
        value: '10',
    },
    {
        key: '契約社員',
        value: '20',
    },
    {
        key: '派遣社員',
        value: '30',
    },
    {
        key: 'フリーランス',
        value: '40',
    },
    {
        key: 'アルバイト・パート',
        value: '50',
    },
    {
        key: '主婦・主夫',
        value: '60',
    }, {
        key: '無職',
        value: '70',
    },
];

export default data;