import React from 'react';
import Validation from '../../model/common/Validation';
import ErrorMsg from '../../component/common/ErrorMsg';
import Message from '../../constants/Message';
import model from '../../model/common/PasswdReset';
import util from '../../model/common/CustomUtil';

import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';

import {
    Grid,
    Container,
    Button,
    Typography,
} from '@mui/material';
import CountDown from '../../component/common/CountDown';
import BackDrop from '../../component/common/BackDrop';


import { withStyles } from '@mui/styles';
import { inject } from 'mobx-react';

// override style
const style = {}

@inject('stores')
class PasswordForgotStep2 extends React.Component {
    state = {
        errorView: false, 
        backDropView: false,
    }

    pwReset = this.props.stores.passwordForgot;

    setFormData = (e) => {
        if (!Validation.empty(e.target.value)) {
            this.props.formData.checkKey = e.target.value;
        }
    }

    hideErrorBox = () => {
        this.setState({ errorView: false });
    }

    showErrorBox = () => {
        this.setState({ errorView: true });
    }

    nextStep = () => {
        const inputCheckKey  = this.props.formData.checkKey;
        const storeCheckKey = this.pwReset.getData('token');
        const email = this.pwReset.getData('email');

        if (Validation.empty(inputCheckKey)) {
            this.setState({ errorView: true });
        } else {
            if (inputCheckKey !== storeCheckKey) {
                this.setState({ errorView: true });
            } else {
                this.setState({
                    backDropView: true,
                });
                const data = {
                    email : email, 
                    token : inputCheckKey
                }
                model.step2(data).then((res) => {
                    if (res.data.status === "success") {
                        const data = res.data;
                        data.email = email;
                        const enc = util.encrypt(JSON.stringify(data));
                        util.setSessionItem('pwReset', enc)
                        window.location.href = '/passwordForgot3';
                    } else {
                        this.showErrorBox();
                        this.setState({
                            backDropView: false,
                        });
                    }
                });
            }
        }
    }

    render() {
        const endTime = this.pwReset.getData('endTime');
        return (
            <>
                <Grid style={{ position: 'relative' }}>

                    <CommNav />
                    <Grid className="content">
                        <Container>
                            <Grid container direction="row" justifyContent="center" alignItems="center">

                                <Grid item xs={12}>
                                    <center>
                                        <Typography variant="h2" className="content_title">アカウント本人確認</Typography>
                                        <Typography vartiant="p" className="content_desc">
                                            ご登録のメールアドレス宛に、認証コードを送信しました。
                                        </Typography>
                                    </center>
                                </Grid>

                                <Grid className="mb_30">
                                    <Grid className="timecount">
                                        <center>
                                            <Typography vartiant="p">コードの残り有効時間</Typography>
                                            <CountDown endTime={endTime} {...this.props} />
                                        </center>
                                    </Grid>
                                </Grid>

                                {
                                    this.state.errorView &&
                                    <ErrorMsg message={Message.validation.passwordForgot2} />
                                }

                                <Grid item xs={12} className="column-shadow">
                                    <Grid className="form" container direction="row">
                                        <Grid item md={4} className="form_title_cell mb_none">認証コード</Grid>
                                        <Grid item md={8} className="form_input_cell mb_none">
                                            <input className="cellwidth10 post-data" placeholder="" onFocus={this.hideErrorBox} onBlur={this.setFormData} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <center>
                                        <Button className="ybtn" onClick={this.nextStep}>送信</Button>
                                    </center>
                                </Grid>

                            </Grid>
                        </Container>
                    </Grid>
                    <CommFooter />
                </Grid>
                <BackDrop open={this.state.backDropView} />
            </>
        );
    }
}

PasswordForgotStep2.defaultProps = {
    formData: {}
};

export default withStyles(style)(PasswordForgotStep2);