import React from 'react';

import {
    Grid, Typography,
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
        marginBottom: '12px',
        '& .main-frame': {
            cursor: 'pointer',
            padding: '1.5rem 2rem',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #B1B4E3',
            borderRadius: '10px',
        },
    }
}

class DataNotFound extends React.Component {
    render() {
        const { classes, message } = this.props;
        return (
            <Grid container justifyContent={'center'} classes={classes}>
                <Grid container justifyContent={'center'} className={'main-frame'}>
                    <Typography>{message}</Typography>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(DataNotFound);
