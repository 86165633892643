import React from 'react';
import CustomModal from '../../component/common/CustomModal';
import Message from '../../constants/Message';

import { withStyles } from '@mui/styles';

// override style
const style = {}

class NeedLogin extends React.Component {

    moveLogin = () => {
        window.location.href = '/login';
    }

    render() {
        return (
            <>
                <CustomModal message={Message.needLogin} onClose={this.moveLogin} open={true}/>
            </>
        );
    }
}

export default withStyles(style)(NeedLogin);