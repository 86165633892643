import React from 'react';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IMGbMessage from '../../asset/img/Message.svg';
import Yen from '../../asset/img/yen.svg';
import Pin from '../../asset/img/pin.svg';
import Job from '../../asset/img/job.svg';

import CustomUtil from '../../model/common/CustomUtil';
import Message from '../../constants/Message';
import Validation from '../../model/common/Validation'

import {
    Button,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
        margin: '1rem 0',
        '& .main-frame': {
            width: '100%',
            padding: '1rem',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #B1B4E3',
            borderRadius: '30px',
            '& .badge-frame': {
                padding: '5px 0',
                '& .badge-icon': {
                    fontSize: '0.8rem',
                    border: '1px solid #88888888',
                    padding: '0.2rem 0.5rem',
                    color: '#888888',
                    marginRight: '0.5rem',
                    marginTop: '0.5rem'
                },
                '& .new-arrival': {
                    background: '#006DFF',
                    color: '#FFFFFF',
                    border : 'none'
                }
            },
            '& .agenda-number-container': {
                '& p' :{
                    fontSize: '0.9rem'
                }
            },
            '& .title-frame': {
                paddingTop: '0.5rem',
                '& .agenda-title':{fontWeight:'bold',fontSize:'1.2rem'}
            },
            '& .icon-frame': {
                padding: '1rem 0',
                alignItems: 'center',
                '& .items': {
                    paddingRight: '1rem',
                },
                '& .icon-container': {
                    alignItems: 'center',
                    marginBottom: '0.5rem',
                    flexWrap : 'nowrap',
                },
                '& .icons': {
                    paddingRight: '0.5rem',
                    marginTop: '0.5rem',
                },
                '& .pay': {
                    paddingRight: '0.4rem',
                    whiteSpace : 'nowrap',
                    '&:nth-child(3)': {
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize:'1.4rem'
                    }
                }
            },
            '& .contents-detl-frame': {
                padding: '1rem 0',
                '& hr': {
                    marginTop: '10px',
                    border: '1px solid rgba(224, 224, 224, 1)'
                }, 
                '& .contents' : {
                    padding: '1rem 0.5rem',
                    overflowWrap: 'anywhere'
                }
            }
        },
        '& .manager-frame': {
            width: '100%',
            marginTop: '1rem',
            padding: '0.5rem 0.5rem',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #B1B4E3',
            borderRadius: '30px',
            '& .title-frame': {
                paddingTop: '0.5rem'
            },
            '& .contents-detl-frame': {
                padding: '1rem 0',
                '& hr': {
                    marginTop: '10px',
                    border: '1px solid rgba(224, 224, 224, 1)'
                }, 
                '& .contents' : {
                    padding: '1rem 0.5rem',
                    overflowWrap: 'anywhere'
                }
            }, 
            '& .managermsg_wrap' : {
                padding: 0,
                '& .manager_msg': {
                    marginTop: '1rem',
                    minHeight: 'auto',
                    '&::before': {
                        width: '20px',
                        height: '20px',
                        top: '0',
                        left: '45%',
                        transform: 'rotate(45deg)'
                    }
                },
            }
        },
        '& .contents-frame': {
            '& th, td': {
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                overflowWrap: 'anywhere'
            },
            '& th': {
                background: '#EFEFEF',
            }
        },
        '& .button-frame': {
            '& .btn': {
                marginTop: '2rem',
                border: '1px solid #AAAAAA',
            },
            '& .join': {
                background: '#00BAFF',
                color: '#FFFFFF',
                width: '90%',
                marginBottom:'1rem',
                border: 'none',
            },
            '& .favorites': {
                color: '#888888',
                marginLeft: '1%',
                width: '15%',
                '& .star-added': {
                    color: 'blue',
                }
            }
        },
        '& .bg-gray': {
            background: '#999 !important'
        }
    }
}

class AgendaDetlSP extends React.Component {
    renderBadgeIcons = (data) => {
        const badges = CustomUtil.getNewArrivalBadgeIcon(data);
        const hitch = CustomUtil.explodeData(',', data.hitch);

        for(const item of hitch){
            badges.push({
                isNewArrival: false, 
                text: item
            })
        }

        return badges
    }

    renderBeforJoin = () => {
        const { data, handleJoinAgenda } = this.props;
        return (
            <Button className={'btn join'} onClick={() => handleJoinAgenda(data.idx)}>
                    <CheckCircleOutlineIcon />
                    {Message.agendaJoin.beforJoinn}
                </Button>
        )
    }

    renderAfterJoin = () => {
        return (
            <Button className={'btn join bg-gray'}>
                {Message.agendaJoin.afterJoin}
            </Button>
        )
    }

    renderAgendaJoinBtnArea = () => {
        const { data, joinedAgendas } = this.props;
        const joinCheck = joinedAgendas.filter(item => {
            return item === data.idx
        })

        if(data.status !== '10'){
            return (<></>)
        }else{
            return (
                <Grid container justifyContent={'center'} className={'button-frame'}>
                    {
                        Validation.empty(joinCheck) ? this.renderBeforJoin() : this.renderAfterJoin()
                    }
    
                    {/* TODO ::: 2차개발 */}
                    {/* <Button className={'btn favorites'}>
                        <StarBorderIcon />{'お気に入り'}
                    </Button> */}
                </Grid>
            )
        }
    }

    render() {
        const { classes, data, agendaJoinBtn } = this.props;
        const badges = this.renderBadgeIcons(data);
        const pay = parseInt(data.pay).toLocaleString()
        const contents = CustomUtil.getHtmlContents(data.contents);
        return (
            <Grid container justifyContent={'center'} classes={classes}>
                <Grid container justifyContent={'center'}>
                    <Grid item className={'main-frame'}>
                        <Grid container className={'agenda-number-container'}>
                            <Typography>{`案件番号：${data.agenda_number}`}</Typography>
                        </Grid>
                        <Grid container justifyContent={'left'} className={'badge-frame'}>
                            {
                                badges.map((item, key) => {
                                    return <Typography key={key} className={`badge-icon ${item.isNewArrival ? 'new-arrival' : ''}`}>{item.text}</Typography>
                                })
                            }
                        </Grid>

                        <Grid className={'title-frame'}>
                            <Typography className="agenda-title">{data.title}</Typography>
                        </Grid>

                        <Grid container className={'icon-frame'}>
                            <Grid container className={'icon-container'}>
                                <img src={Yen} alt='' className="icons"/>
                                <Typography className={'pay'}>{`〜`}</Typography>
                                <Typography className={'pay'}>{pay}</Typography>
                                <Typography className={'items pay'}>{`万円/月`}</Typography>
                            </Grid>
                            <Grid container className={'icon-container'}>
                                <img src={Pin} alt='' className="icons"/>
                                <Typography className={'items location'}>{data.location}</Typography>
                            </Grid>
                            <Grid container className={'icon-container'}>
                                <img src={Job} alt='' className="icons"/>
                                <Typography className={'items req-type'}>{data.emp_status}</Typography>
                            </Grid>
                        </Grid>

                        <Table className={'contents-frame'}>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th">{"開発環境"}</TableCell>
                                    <TableCell>{data.language}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th">{"募集職種"}</TableCell>
                                    <TableCell>{data.recruitment_type}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Grid className={'contents-detl-frame'}>
                            <Typography>{'業務内容について'}</Typography>
                            <Divider/>
                            <Grid className={'contents'}>{contents}111111111111111111111111111111111111111111111111111111111111111111111111111111111111</Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid justifyContent="flex-start" className={'manager-frame'}>
                    <Grid item className="managerinfo_wrap">
                        <Grid className="managerimg_wrap">
                            <img src={data.managerImage} alt=""/>
                        </Grid>
                        <Typography variant="p">{"案件担当"}</Typography>
                        <Typography variant="h5">{`${data.assign.name_sei}　${data.assign.name_mei}`}</Typography>
                    </Grid>
                    <Grid className="managermsg_wrap">
                        <Grid className="manager_msg">
                            <Typography fontSize={'1rem'} marginBottom={'0.5rem'}>
                                <img src={IMGbMessage} alt=""/>
                                {'担当者からのメッセージ'}
                            </Typography>
                            <Typography fontSize={'0.9rem'}>
                                {data.manager_message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className="managermsg_wrap">
                        <Grid item md={4}></Grid>
                        <Grid item>
                            <Typography color={'red'} fontSize={'0.9rem'}>
                                {'※担当者は変更になる場合がございます。'}
                            </Typography>
                        </Grid>
                    </Grid>

                    { agendaJoinBtn && this.renderAgendaJoinBtnArea() }
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(AgendaDetlSP);
