import React from 'react';
import { Link } from 'react-router-dom';
import util from '../../model/common/CustomUtil';

import {
    Grid,
    Typography,
    Container
} from '@mui/material';
import { withStyles } from '@mui/styles';

// override style
const style = {}

class MainAbout extends React.Component {
    render() {
        const session = util.getSessionItem('session');
        return (
            <>
                <Grid className="about_us mt_80" id="about-target">
                    <Container>
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
                            <Grid item xs={12} className="col-12">
                                <center>
                                    <Typography variant="h3" className="about_title">{'サービス紹介'}</Typography>
                                    <Typography variant="p" className="about_desc">
                                        MINONAVI（ミノナビ）は、ITエンジニア専用の案件・求人情報サイトです。<br/>
                                        ご応募から案件の紹介、専任担当によるサポートまですべて無料でご利用いただけます。<br/>
                                        フリーランスから正社員までご希望の働き方が選べられます。
                                    </Typography>
                                </center>
                            </Grid>

                            <Grid item xs={12} className="col-12">
                                <center>
                                    <Typography variant="h3" className="about_title">{'ご利用の流れ'}</Typography>
                                </center>
                            </Grid>
                            <Grid item md={3} className={'`mt_30 ai_top`'}>
                                <Grid className="about_card">
                                    <Typography variant="h3">{'STEP1'}</Typography>
                                    <Typography variant="p">{'まず、無料会員登録！'}</Typography>
                                </Grid>
                                <Typography className="about_desc" variant="span">応募はとても簡単！ご応募後、1営業日以内に担当者より面談（カウンセリング）の日程調整について、メールもしくはお電話にてご連絡します。</Typography>
                            </Grid>
                            <Grid item md={3} className={'`mt_30 ai_top`'}>
                                <Grid className="about_card">
                                    <Typography variant="h3">{'STEP2'}</Typography>
                                    <Typography variant="p">{'カウンセリング面談'}</Typography>
                                </Grid>
                                <Typography className="about_desc" variant="span">専任担当が、カウンセリング面談にてあなたのご要望やこれまでの経歴・スキルなどをヒアリングし、ご希望に沿った案件探しを開始します。</Typography>
                            </Grid>
                            <Grid item md={3} className={'`mt_30 ai_top`'}>
                                <Grid className="about_card">
                                    <Typography variant="h3">{'STEP3'}</Typography>
                                    <Typography variant="p">{'クライアントと面談'}</Typography>
                                </Grid>
                                <Typography className="about_desc" variant="span">専任担当があなたに代わって、クライアントとの面談日程を調整いたします。もちろん面談にも専任担当が同席し、フォローいたします。</Typography>
                            </Grid>
                            <Grid item md={3} className={'`mt_30 ai_top`'}>
                                <Grid className="about_card">
                                    <Typography variant="h3">{'STEP4'}</Typography>
                                    <Typography variant="p">{'ご成約とサポート'}</Typography>
                                </Grid>
                                <Typography className="about_desc" variant="span">ご成約後も専任担当が現場での不安や不満などの「直接言いづらいこと」をあなたに代わり現場に伝えるなど、しっかりサポートします。</Typography>
                            </Grid>
                        </Grid>
                        <Grid className="btn_wrap center">
                            <Link className="btn" to="/siteinfo">
                                {'詳しくはこちら'}
                            </Link>
                        </Grid>
                    </Container>
                </Grid>

                {
                    session === null &&
                    <Grid className="fixbtn login-ctr before-login">
                        <a href="/regist" className="fbtn">{'無料会員登録'}</a>
                    </Grid>
                }
            </>
        );
    }
}

export default withStyles(style)(MainAbout);