import React from 'react';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import Typography from '@mui/material/Typography';
import CustomUtil from '../../model/common/CustomUtil';
import {
    Grid,
    Container,
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {
    company:{
        textAlign : 'right'
    }
}

@inject('stores')
class Accept extends React.Component {
    render() {
        CustomUtil.scrollTarget();
        return (
            <>
                <Grid style={{ position: 'relative' }}>
                <CommNav />
                    <Grid className="content">
                        <Container>
                            <Typography variant="h2" className="content_title">個人情報の取扱い</Typography>
                            <Grid item xs={12} className="column-shadow2">
                                <Typography variant="p" className="entry_desc">
                                    <Grid style={style.company}>
                                        東京都新宿区西新宿7-17-14<br/>
                                        新宿シティ源共同ビル3F<br/>
                                        （株）MINOSYS 代表取締役：崔 民鎬<br/>
                                    </Grid>
                                    <br/>
                                    当社は、会員登録フォームにご入力いただいた会員登録者様に関するお名前、メールアドレス、住所などの個人情報を下記のように取り扱います。<br/>
                                    <ul className='list_style'>
                                        <li>
                                            はじめに<br/>
                                            この書面は、当社に連絡された会員登録者様の個人情報の保護とお取扱いについて、個人情報保護の定めに従いご説明するものです。<br/>
                                        </li>
                                        <li>
                                            個人情報の利用目的<br/>
                                            ・当社において採用の可否を決定するために利用します。<br/>
                                            ・問合の対応に利用します。<br/>
                                            ・個人情報の開示等の一連の処理対応にのみ利用します。<br/>
                                        </li>
                                        <li>
                                            提供する個人情報<br/>
                                            当社は、皆様の個人情報を外部に提供しません。<br/>
                                        </li>
                                        <li>
                                            個人情報の委託<br/>
                                            当社は、皆様の個人情報を外部に委託しません。<br/>
                                        </li>
                                        <li>
                                            開示、訂正等、利用停止等の申立先<br/>
                                            皆様の個人情報は、開示、訂正・追加・削除、利用停止、利用目的の通知等を下記の窓口で受け付けております。<br/>
                                            その場合、ご本人確認をさせていただき、速やかに対応いたします。なお、法令等対応できない理由があれば遅滞なく通知いたします。<br/>
                                            ①個人情報保護管理者：経営支援部　取締役　元 美羅<br/>
                                            ②相談窓口：経営支援部　取締役　元 美羅　電話: 03-5942-8170<br/>
                                        </li>
                                        <li>
                                            提供の任意性と結果<br/>
                                            個人情報を提供するか否かは皆様の自由ではありますが、提供しない場合は、対応ができない事があることをご承知下さい。<br/>
                                        </li>
                                        <li>
                                            容易に認識できない方法で取得する場合<br/>
                                            当社は、当サイトを利用する方とは、交信を円滑にするために通信情報を利用することがありますが、それを使って個人を特定することはありません。<br/>
                                        </li>
                                        <li>
                                            個人情報の廃棄・消去<br/>
                                            皆様からお預かりした個人情報は、採否決定後、皆様の事前の承諾を得ることなく、安全かつ完全に廃棄または消去いたします。<br/>
                                        </li>
                                    </ul>
                                    {'※当社の個人情報保護方針については、'}<Link to="/Policy" target="_blank" rel="noopener noreferrer">{'こちら'}</Link>{'をご参照ください。'}
                                </Typography>
                            </Grid>
                        </Container>
                    </Grid>
                    <CommFooter />
                </Grid>
            </>
        );
    }
}

export default withStyles(style)(Accept);