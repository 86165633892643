import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";

class MemberEvaluation {
    getData = async (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.getEvaluationData, params, headers)
    }

    setData = (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.addEvaluationData, params, headers)
    }

    deleteData = (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.delEvaluationData, params, headers)
    }

    dataProcess = (obj) => {
        return obj;
    }
}

export default new MemberEvaluation();