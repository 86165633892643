import React from 'react';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import Yen from '../../asset/img/yen.svg';
import Pin from '../../asset/img/pin.svg';
import Job from '../../asset/img/job.svg';
import Jyusoku from '../../asset/img/jyusoku.svg';
import Kanryo from '../../asset/img/kanryo.svg';


import {
    Button,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@mui/material';

import { withStyles } from '@mui/styles';
import CustomUtil from '../../model/common/CustomUtil';
import Message from '../../constants/Message';
import Validation from '../../model/common/Validation';

// override style
const style = {
    root: {
        marginBottom: '12px',
        position : 'relative',
        '& .agenda-status-icon': {
            position: 'absolute',
            right: '4%',
            top : '4%',
            width : '20%'
        },
        '& .main-frame': {
            cursor: 'pointer',
            width: '800px',
            padding: '1.5rem 2rem',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #B1B4E3',
            borderRadius: '30px',
            '& .badge-frame': {
                padding: '5px 0',
                height : '34px',
                overflow : 'hidden',
                '& .badge-icon': {
                    fontSize: '0.8rem',
                    border: '1px solid #88888888',
                    padding: '0.2rem 0.5rem',
                    color: '#888888',
                    marginRight: '0.5rem',
                    marginBottom: '0.25rem',
                },
                '& .new-arrival': {
                    background: '#006DFF',
                    color: '#FFFFFF',
                    border : 'none'
                }
            },
            '& .agenda-number-container': {
                '& p' :{
                    fontSize: '0.8rem'
                }
            },
            '& .title-frame': {
                paddingTop: '0.5rem',
                '& .agenda-title':{fontWeight:'bold',fontSize:'1.2rem'}
            },
            '& .icon-frame': {
                padding: '1rem 0',
                alignItems: 'center',
                '& .items': {
                    marginRight: '12px',
                },
                '& .icons': {
                    paddingRight: '12px'
                },
                '& .pay': {
                    paddingRight: '0.4rem',
                    '&:nth-child(3)': {
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize:'1.4rem'
                    }
                }
            }
        },
        '& .contents-frame': {
            '& th, td': {
                borderTop: '1px solid rgba(224, 224, 224, 1)'
            },
            '& th': {
                background: '#EFEFEF',
                width: '15%'
            }
        },
        '& .button-frame': {
            '& .btn': {
                border: '1px solid #AAAAAA',
                marginTop: '1rem'
            },
            '& .join': {
                background: '#00BAFF',
                color: '#FFFFFF',
                width: '100%',
                border : 'none'
            },
            '& .favorites': {
                color: '#888888',
                marginLeft: '2%',
                width: '27%',
                '& .star-added': {
                    color: 'blue',
                }
            }
        },
        '& .bg-gray': {
            background: '#999 !important'
        }
    }
}

class SearchCardPC extends React.Component {
    renderBadgeIcons = (data) => {
        const badges = CustomUtil.getNewArrivalBadgeIcon(data);
        const hitch = CustomUtil.explodeData(',', data.hitch);

        for(const item of hitch){
            badges.push({
                isNewArrival: false, 
                text: item
            })
        }

        return badges
    }

    renderBottunArea = () => {
        const { data } = this.props;
        if(data.status !== '10'){
            return (<></>)
        }else{
            return (
                <Grid container justifyContent={'center'} className={'button-frame'}>
                    {this.renderJoinButton()}
                    {/* TODO ::: 2차개발 */}
                    {/* <Button className={'btn favorites'}>
                        <StarBorderIcon />{'お気に入り'}
                    </Button> */}
                </Grid>
            )
        }
    }

    renderJoinButton = () => {
        const { joinedAgendaData, data, handleJoinAgenda } = this.props;
        const checkJoined = joinedAgendaData.filter(item => {
            return item === data.idx
        })

        return (
            <>
                { 
                    Validation.empty(checkJoined) ? 
                        <Button className={'btn join'} onClick={() => handleJoinAgenda(data.idx)}>
                            <CheckCircleOutlineIcon />
                            {Message.agendaJoin.beforJoinn}
                        </Button>
                        : 
                        <Button className={'btn join bg-gray'}>
                            {Message.agendaJoin.afterJoin }
                        </Button>
                }
            </>
        )
    }

    render() {
        const { classes, agendaJoinBtn, data, detlPageFnc } = this.props;
        const badges = this.renderBadgeIcons(data);
        const pay = parseInt(data.pay).toLocaleString();
        let stamp = null;
        if(data.status !== '10'){
            stamp = data.status === '20' ? Kanryo : Jyusoku ;
        }
        return (
            <Grid container justifyContent={'center'} classes={classes}>
                <Grid container justifyContent={'center'}>
                    <Grid item className={'main-frame'}>
                        <Grid className={'contents-frame'} onClick={()=> detlPageFnc(data.idx)}>
                            { stamp && <img className={'agenda-status-icon'} width={'250'} src={`${stamp}`} alt={''}/> }
                            
                            <Grid container className={'agenda-number-container'}>
                                <Typography>{`案件番号：${data.agenda_number}`}</Typography>
                            </Grid>
                            <Grid container justifyContent={'left'} className={'badge-frame'}>
                                {
                                    badges.map((item, key) => {
                                        return <Typography key={key} className={`badge-icon ${item.isNewArrival ? 'new-arrival' : ''}`}>{item.text}</Typography>
                                    })
                                }
                            </Grid>

                            <Grid className={'title-frame'}>
                                <Typography className="agenda-title">{data.title}</Typography>
                            </Grid>

                            <Grid container className={'icon-frame'}>
                                <img src={Yen} alt='' className="icons"/>
                                <Typography className={'pay'}>{`〜`}</Typography>
                                <Typography className={'pay'}>{pay}</Typography>
                                <Typography className={'items pay'}>{`万円/月`}</Typography>
                                <img src={Pin} alt='' className="icons"/>
                                <Typography className={'items location'}>{data.location}</Typography>
                                <img src={Job} alt='' className="icons"/>
                                <Typography className={'items req-type'}>{data.emp_status}</Typography>
                            </Grid>

                            <Table className={'contents-frame'}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th">{'開発環境'}</TableCell>
                                        <TableCell>{data.language}</TableCell>
                                        <TableCell component="th">{'作業開始日'}</TableCell>
                                        <TableCell>{`${CustomUtil.getDateTime(data.job_start_date, 'YYYY/MM/DD')} ～`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">{'募集職種'}</TableCell>
                                        <TableCell colSpan={3}>{data.recruitment_type}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>

                        { agendaJoinBtn && this.renderBottunArea() }
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(SearchCardPC);
