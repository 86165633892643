import React from 'react';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { runInAction } from 'mobx';

import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import Message from '../../constants/Message';
import ErrorMsg from '../../component/common/ErrorMsg';
import model from '../../model/common/Auth';
import util from '../../model/common/CustomUtil';

import IMGexternallink from '../../asset/img/external-link.svg';
import TestLogin from '../../component/user/TestLogin';

import {
    Grid,
    Container,
    Typography,
    Button
} from '@mui/material';
import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
        '& input': {
            border: '1px solid #767676',
            lineHeight: '35px',
        }
    }
}

@inject('stores')
class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            errorView: false
        }
        this.login = props.stores.login;
        
        if (this.login.getData('email')) {
            window.location.href = '/';
            this.render = () => (<></>)
        }
    }
    

    showErrorBlock = () => {
        this.setState({
            errorView: true
        });
    }

    hideErrorBlock = () => {
        this.setState({
            errorView: false
        });
    }

    setFormData = (e) => {
        this.props.formData[e.target.name] = e.target.value;
    }

    doLogin = () => {
        this.hideErrorBlock();
        const { formData } = this.props;

        model.logIn(formData).then((res) => {
            if (res.data.status === 'success') {
                const session = util.encrypt(JSON.stringify(res.data.data));
                util.setSessionItem('session', session);

                runInAction(() => {
                    this.login.data = res.data.data;
                });

                if (res.data.data.is_admin === "Y") {
                    window.location.href = '/mg/agendaList';
                } else {
                    const referer = util.updateSessionItemAndGetValue('server', 'referer');
                    window.location.href = referer !== '' ? referer : '/';
                }
            } else {
                this.showErrorBlock();
            }
        })
    }

    render() {
        util.scrollTarget();
        const { classes } = this.props;
        return (
            <>
                <Grid style={{ position: 'relative' }} className={classes.root}>
                    <CommNav />
                    <Grid className="content">
                        <Container>
                            <Grid container direction="row" justifyContent="center" alignItems="center">

                                {
                                    this.state.errorView &&
                                    <ErrorMsg message={Message.validation.passwordForgot} />
                                }

                                <Grid item xs={12}>
                                    <center>
                                        <Typography variant="h2" className="content_title">{'ログイン'}</Typography>
                                    </center>
                                </Grid>

                                <Grid item xs={12} className="column-shadow">
                                    <Grid className="form" container direction="row">
                                        <Grid item md={4} className="form_title_cell">{'ID(メールアドレス)'}</Grid>
                                        <Grid item md={8} className="form_input_cell">
                                            <input className="cellwidth10" type="email" placeholder="メールアドレス(半角英数字)" name="email" onChange={this.setFormData} onFocus={this.hideErrorBox} />
                                        </Grid>

                                        <Grid item md={4} className="form_title_cell mb_none">パスワード</Grid>
                                        <Grid item md={8} className="form_input_cell mb_none">
                                            <input className="cellwidth10" type="password" placeholder="パスワード(半角英数字6~20文字)" name="pw" onChange={this.setFormData} onFocus={this.hideErrorBox} />
                                            <Typography variant="span" className="mt_10"><Link to="/PasswordForgot"><img src={IMGexternallink} alt="" />{'パスワードを忘れた場合'}</Link></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} >
                                    <center>
                                        <Button className="ybtn" onClick={this.doLogin}>{'ログイン'}</Button>
                                    </center>
                                </Grid>

                                <Grid item xs={12} >
                                    <center className="mt_30">
                                        <Typography variant="span"><Link to="/Regist">{'無料で会員登録'}</Link></Typography>
                                    </center>
                                </Grid>
                            </Grid>

                            {
                                process.env.REACT_APP_MODE === 'local' &&
                                <TestLogin />
                            }

                        </Container>
                    </Grid>
                    <CommFooter />
                </Grid>
            </>
        );
    }
}

Login.defaultProps = {
    formData: {}
};


export default withStyles(style)(Login);