import React from 'react';
import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import Auth from '../../model/common/Auth';
import UserListSesrchBox from '../../component/manager/UserListSesrchBox';
import model from '../../model/manager/UserList';
import UserDetl from './UserDetl';
import Validation from '../../model/common/Validation';
import CustomUtil from "../../model/common/CustomUtil";

import { inject, observer } from 'mobx-react';
import { 
    Grid, 
    Pagination, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Typography 
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CustomModal from '../../component/common/CustomModal';
import CrownIcon from '../../asset/img/crown-solid.svg';

// override style
const style = {
    root:{
        '& .admin_badge':{
            padding : '1px 5px',
            marginRight : '5px',
            background : '#ddc75f',
            borderRadius :'5px',
            color : '#fff',
            '& .crown-icon': {
                width : '15px',
            }
        }
        
    }
}

@inject('stores')
@observer
class UserList extends React.Component {
    state = {
        data: null,
        pageNum: 0,
        modalView: false
    }

    store = this.props.stores.form;
    login = this.props.stores.login;
    search = this.props.stores.search;
    resetSearch = true;

    paging = async (event, pageNum) => {
        if (this.state.pageNum === pageNum) {
            return;
        }

        this.getDataList(pageNum)
    }

    getDataList = (pageNum) => {
        const params = this.search.getAllData()
        model.getList(pageNum, params).then(res => {
            res.data.list = model.dataProcess(res.data.list);
            this.setState({
                data: res.data,
                pageNum: pageNum
            });
        });
    }

    componentDidMount = () => {
        if (Validation.empty(this.search.data)) {
            this.search.resetData(this.props.formData)
        }

        this.paging(null, 1);
    }

    showModal = (userIdx) => {
        this.store.setData('userIdx', userIdx);
        this.setState({
            modalView: true
        })
    }

    closeModal = () => {
        this.setState({
            modalView: false
        })
    }

    handleTopBoxSearchButton = () => {
        this.resetSearch = true;
        this.getDataList(1)
    }

    render() {
        if (Auth.checkAdmin()) {
            window.location.href = '/';
            return (<></>);
        }

        const { data, modalView } = this.state;
        const { classes } = this.props;
        const header = ['会員番号', '氏名', '性別', '年齢', '国籍', '実務経験', '得意言語', '最寄駅', 'E-mail', '電話番号', "登録日"];

        CustomUtil.scrollTarget();
        return (
            <>
                {
                    data !== null &&
                    <>
                        <CommNav />

                        <Grid className="content" classes={classes}>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item md={11}>
                                    <Grid item>
                                        <center>
                                            <Typography variant="h2" className="content_title">会員管理</Typography>
                                        </center>
                                    </Grid>

                                    <UserListSesrchBox handleSearch={this.handleTopBoxSearchButton} />

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        header.map((item, index) => <TableCell align={'center'} key={index}>{item}</TableCell>)
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    data.list.map((item, index) => {
                                                        return (
                                                            <TableRow key={index} onClick={() => this.showModal(item.idx)}>
                                                                <TableCell align={'center'}>{item.idx}</TableCell>
                                                                <TableCell align={'center'}>
                                                                    <Grid container>
                                                                        {item.is_admin === 'Y' && <Typography variant='span' className='admin_badge'><img src={CrownIcon} className={'crown-icon'} alt=""/></Typography>}
                                                                        {item.name}
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell align={'center'}>{item.gender}</TableCell>
                                                                <TableCell align={'center'}>{item.age}</TableCell>
                                                                <TableCell align={'center'}>{item.nationality}</TableCell>
                                                                <TableCell align={'center'}>{item.work_history}</TableCell>
                                                                <TableCell align={'center'}>{item.use_language}</TableCell>
                                                                <TableCell align={'center'}>{item.near_station}</TableCell>
                                                                <TableCell align={'center'}>{item.email}</TableCell>
                                                                <TableCell align={'center'}>{item.phone}</TableCell>
                                                                <TableCell align={'center'}>{item.join_at}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>

                                    </TableContainer>

                                    {
                                        data.paging.maxPage > 1 &&
                                        <Grid container direction="row" justifyContent="center" alignItems="center" className="mt_30">
                                            <Pagination page={this.state.pageNum} count={data.paging.maxPage} variant="outlined" shape="rounded" color="primary" onChange={this.paging} />
                                        </Grid>
                                    }

                                    <CustomModal Component={UserDetl} onClose={this.closeModal} open={modalView} maxWidth={'lg'} />
                                </Grid>

                            </Grid>
                        </Grid>

                        <CommFooter />
                    </>
                }
            </>
        );
    }
}

UserList.defaultProps = {
    formData: {
        nationality: "",
        work_history: "",
        language: "",
        name: "",
        email: "",
        phone: "",
        isAdmin: "",
    },
};

export default withStyles(style)(UserList);