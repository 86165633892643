const data = {
    name: '埼玉県',
    key: 'saitama',
    value: 'KAN30',
    area: [
        {
            key: '埼玉県',
            value: 'SA000'
        },
        {
            key: "その他",
            value: "SA999"
        }
    ]
}

export default data;