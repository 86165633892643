import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";

class Main {
    getAgendaList = async (email) => {
        const data = {
            mode : 'index', 
        }

        if(email){
            data.email = email;
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.itemList, params, headers).then(res =>{
            return this.dataProcess(res.data);
        })
    }

    dataProcess = (obj) => {
        for (const [index, value] of Object.entries(obj)) {
            obj[index].emp_status = CustomUtil.getEmpStatus(value.emp_status);
            obj[index].location = CustomUtil.getNeedWorkLocationCity(value.location, value.nearest_station);
            obj[index].recruitment_type = CustomUtil.getRecruitmentType(value.recruitment_type);
            obj[index].language = CustomUtil.getLanguage(value.language);
            obj[index].hitch = CustomUtil.getHitch(value.hitch);
        }
        return obj;
    }
}

export default new Main();