// 学歴情報
const school = [
    {
        key: '高等学校',
        value: 'A010',
    },
    {
        key: '大学',
        value: 'A020',
    },
    {
        key: '大学院生',
        value: 'A030',
    },
    {
        key: '短大',
        value: 'A040',
    },
    {
        key: '専門学校生',
        value: 'A050',
    },
];

const grades =[
    {
        key: '1年',
        value: '1'
    },
    {
        key: '2年',
        value: '2'
    },
    {
        key: '3年',
        value: '3'
    },
    {
        key: '4年',
        value: '4'
    },
]

const generationStatus = [
    {
        key: '卒業',
        value: '10'
    },
    {
        key: '中退',
        value: '20'
    },
    {
        key: '休学',
        value: '30'
    },
];

export {
    school,
    grades,
    generationStatus
};