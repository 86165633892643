import React from 'react';

class TestPage extends React.Component {
  render() {
    return (
      <>
       this is the test
      </>
    );
  }
}

export default TestPage;
