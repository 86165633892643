const data = [
    {
        key: '履歴書',
        value: 'resume'
    },
    {
        key: '職務経歴書',
        value: 'career'
    },
    {
        key: 'ポートフォリオ',
        value: 'portfolio'
    },
    {
        key: 'マネージャー写真',
        value: 'managerPhort'
    },
];

export default data;