import axios from "axios"
import ApiUrl from '../../constants/ApiUrl';
import CustomUtil from "../common/CustomUtil";

class AgendaModify {
    getData = async (agendaIdx) => {
        const data = {
            mode: 'manager', 
            agenda_idx : agendaIdx
        }

        const params = CustomUtil.getAxiosFormData(data);
        const headers = CustomUtil.getAxiosHeaderData();

        return await axios.post(ApiUrl.itemDetl, params, headers);
    }

    submit = (formData) => {
        const params = CustomUtil.getAxiosFormData(formData);
        const headers = CustomUtil.getAxiosHeaderData();

        return axios.post(ApiUrl.itemModify, params, headers)
    }

    dataProcess = (obj) => {
        obj.city = obj.city.split(',');
        obj.emp_status = obj.emp_status.split(',');
        obj.hitch = obj.hitch.split(',');
        obj.language = obj.language.split(',');
        obj.location = obj.location.split(',');
        obj.nearest_station = obj.nearest_station.split(',');
        obj.recruitment_type = obj.recruitment_type.split(',');
        obj.assign = obj.assign ? obj.assign.idx : '';
        return obj;
    }
}

export default new AgendaModify();