import React from 'react';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import CustomUtil from '../../model/common/CustomUtil';
import {
    Grid,
    Typography,
    Container,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import IMGwhitemail from '../../asset/img/white_mail.svg';
import IMGtop_text from '../../asset/img/top_text.svg';
// override style
const style = {}

@inject('stores')
class MainTopSearch extends React.Component {

    handleFreeWord = (e) => {
        const {formData} = this.props;
        formData.keyword = e.target.value;
    }

    searchItem = () => {
        const { formData } = this.props;
        CustomUtil.setSessionItem('search', JSON.stringify(formData))
        window.location.href = '/search';
    }

    moveInquery = () => {
        CustomUtil.setSessionItem('inquery', 20);
        window.location.href = '/inquiry';
    }

    render() {
        return (
            <main>
                <Container>

                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="h1" gutterBottom fontWeight="fontWeightBold">
                                <img className="main_text" src={IMGtop_text} alt=""/>
                            </Typography>
                            <Grid className="servce_link">
                                <Button onClick={this.props.aboutFocus}>
                                    {'サービス紹介'}
                                </Button>
                            </Grid>
                            <Grid className="engineer_link">
                                <Typography variant="span">
                                    <Link to="/" onClick={this.moveInquery}>
                                        <img src={IMGwhitemail} alt=""/>
                                        {'エンジニアをお探しの企業様はこちら'}
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        );
    }
}

MainTopSearch.defaultProps = {
    formData: {
        keyword: '',
        empStatus: [],
        recruitmentType: [],
        language: [],
        pay: [],
        needWorkLocation: []
    }
}

export default withStyles(style)(MainTopSearch);