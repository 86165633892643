// 言語
const data = [
    {
        key: 'Java',
        value: 'LA000',
    },
    {
        key: 'PHP',
        value: 'LA010'
    },
    {
        key: 'Ruby',
        value: 'LA020'
    },
    {
        key: 'Python',
        value: 'LA030'
    },
    {
        key: 'JavaScript',
        value: 'LA040'
    },
    {
        key: 'SQL',
        value: 'LA050'
    },
    {
        key: 'C#',
        value: 'LA060'
    },
    {
        key: 'HTML・CSS',
        value: 'LA070'
    },
    {
        key: 'Swift',
        value: 'LA080'
    },
    {
        key: 'Go',
        value: 'LA090'
    },
    {
        key: 'Kotlin',
        value: 'LA100'
    },
    {
        key: 'C++',
        value: 'LA110'
    },
    {
        key: 'C',
        value: 'LA120'
    },
    {
        key: 'Scala',
        value: 'LA130'
    },
    {
        key: 'Perl',
        value: 'LA140'
    },
    {
        key: 'COBOL',
        value: 'LA150'
    },
    {
        key: 'iOS',
        value: 'LA160'
    },
    {
        key: 'Android',
        value: 'LA170'
    },
    {
        key: 'VB',
        value: 'LA180'
    },
    {
        key: 'VB.NET',
        value: 'LA190'
    },
    {
        key: 'ASP.NET',
        value: 'LA200'
    },
    {
        key: 'C#.NET',
        value: 'LA210'
    },
    {
        key: 'VBA',
        value: 'LA220'
    },
    {
        key: 'TypeScript',
        value: 'LA230'
    },
    {
        key: 'PL/SQL',
        value: 'LA240'
    },
    {
        key: 'Shell',
        value: 'LA250'
    },
    {
        key: 'Objective-C',
        value: 'LA260'
    },
    {
        key: 'ABAP',
        value: 'LA270'
    },
    {
        key: 'RPG',
        value: 'LA280'
    },
    {
        key: 'VC++',
        value: 'LA290'
    },
    {
        key: "その他",
        value: "LA999"
    }
];

export default data;