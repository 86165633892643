import React from 'react';
import { inject, observer } from 'mobx-react';
import model from '../../model/manager/AgendaDetl';
import UaerApplicantStatus from '../../constants/UaerApplicantStatus';

import {
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Paper,
    Button,
    Pagination,
    Grid,
    TextField,
    MenuItem
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
        marginTop:'1rem',
        '& .table-frame': {
            borderRadius: '30px',
            border:'1px solid #B1B4E3',
            boxShadow:'none',
            '& table':{
                '& thead':{},
                '& tbody':{
                    '& tr':{
                        '& td':{
                            borderBottom:'none',
                            '& button':{
                                border : '1px solid #B1B4E3',
                                background :'#ffffff',
                                color:'#B1B4E3',
                            }
                        }
                    },
                    '& tr:hover':{
                        background : '#F8F8FF',
                    }
                }
            }

        }
    }
}

// TODO ::: 화면에 표시된 코드및 한글 치환할것
@inject('stores')
@observer
class ApplicantList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            applicantList: [], 
            nowPage : 1, 
            maxPage : 1
        }
        this.loginUser = props.stores.login;
        this.formData = this.props.stores.form;
    }

    componentDidMount = () => {
        const { nowPage } = this.state;
        this.getApplicantList(nowPage);
    }

    getApplicantList = async (pageNum) => {
        const agendaIdx = this.formData.getData('agendaIndex');
        const applicantList = await model.getApplicantData({agendaIdx, pageNum});
        this.setState({
            applicantList: applicantList.list,
            nowPage: pageNum, 
            maxPage: applicantList.paging.maxPage
        });
    }

    handleChangeUserProcessingStatus = (e, applicantIdx) => {
        const adminIdx = this.loginUser.getData('idx');
        const { nowPage } = this.state;

        const formData = {
            applicantIdx: applicantIdx,
            status: e.target.value,
            adminIdx: adminIdx,
        }

        model.confirmProcess(formData).then(res => {
            this.getApplicantList(nowPage);
        })
    }

    showMemberInfo = (e) => {
        const { agendaIdx } = this.props;
        const url = `/mg/displayUserInfo/${e.target.dataset.value}/${agendaIdx}`;
        window.open(url, '_blank');
    }

    movePage = async(e, pageNum) => {
        this.getApplicantList(pageNum);
    }

    render() {
        const { classes } = this.props;
        const { applicantList, nowPage, maxPage } = this.state;
        const headers = [
            '氏名',
            '性別',
            '年齢',
            '国籍',
            '最寄駅',
            '経歴',
            '応募日',
            '情報',
            '状態'
        ];

        return (
            <Grid container classes={classes}>
                <TableContainer component={Paper} className={'table-frame'}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    headers.map(item => (
                                        <TableCell key={item}>{item}</TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                applicantList.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.gender}</TableCell>
                                            <TableCell>{item.birthday}</TableCell>
                                            <TableCell>{item.nationality}</TableCell>
                                            <TableCell>{item.near_station}</TableCell>
                                            <TableCell>{item.work_history}</TableCell>
                                            <TableCell>{item.join_at}</TableCell>
                                            <TableCell><Button variant='outlined' color={'primary'} data-value={item.idx} onClick={this.showMemberInfo}>{'応募者情報'}</Button></TableCell>
                                            <TableCell>
                                                <TextField
                                                    id="standard-select-currency"
                                                    select
                                                    value={item.confirm}
                                                    onChange={(event) => this.handleChangeUserProcessingStatus(event, item.app_idx)}
                                                    variant="standard"
                                                    >
                                                    {
                                                        UaerApplicantStatus.map((applicant) => {
                                                            return (
                                                                <MenuItem key={applicant.value} value={applicant.value}>
                                                                    {applicant.key}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container direction="row" justifyContent="center" alignItems="center" className="mt_30">
                    <Pagination page={nowPage} count={maxPage} variant="outlined" shape="rounded" color="primary" onChange={this.movePage} />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(ApplicantList);
