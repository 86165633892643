// メッセージ
const data = {
    success: '処理が完了しました。',
    error: '問題が発生しました。',
    validation: {
        dataErr: 'データを入力してください。',
        timeOver: '[A]時間が切れました。',
        needData: '[A]が必要です。',
        passwordForgot: '入力に誤りがあるか、登録されているユーザーではありません。',
        passwordForgot2: '認証キーを入力さてください。',
        passwordForgot3: {
            empty: 'パスワードを入力してください。',
            notMatch: 'パスワードと確認用パスワードが異なります。',
            regExpErr: 'パスワードの形式が異なります。\nパスワードは半角英数字6~20文字です。'
        },
        regist: {
            assign: 'アサインを設定してください',
            fw: 'ご希望のフレームワークを記入してください',
            db: 'ご希望のデータベースを記入してください',
            manager_message: 'メッセージを記入してください',
            keyword: 'キーワードを入力してください',
            contents: '内容を作成してください',
            hitch: 'ご希望の項目をチェックしてください',
            pay: '契約金額を記入してください',
            location: '勤務地を選択してください',
            recruitment_type: 'ご希望の職種をチェックしてください',
            title: 'タイトルを記入してください',
            publish_date: 'ご希望の期間を選択してください',
            emp_status: '雇用形態を選択してください',
            gender: '性別を選択してください',
            policy: '規約に同意してください',
            language: 'ご希望の言語をチェックしてください',
        },
        inquery: {
            name: '氏名を入力してください。',
            nameKana: '氏名ガナを入力してください。',
            email: 'メールアドレスに誤りがある場合、返信をお届けできません。予めご了承ください。',
            comName: '貴社名を入力してください。',
            contents: 'お問い合わせ内容を入力してください。',
            dept: '部署名 / ご役職を入力してください。',
            address: 'ご連絡先（住所）を入力してください。',
            phone: '電話番号を入力してください。',
            policy: '利用規約に同意してください。'
        }
    },
    inquery: {
        name: '氏名を入力してください。',
        nameKana: '氏名ガナを入力してください。',
        email: 'メールアドレス（半角英数字・記号）を入力してください。',
        contents: 'お問い合わせ内容を入力してください。',
        comName: '貴社名を入力してください。',
        dept: '部署名 / ご役職を入力してください。',
        address: 'ご連絡先（住所）を入力してください。',
        phone: '電話番号を入力してください。',
        mailSend: {
            success: 'お問い合わせを送信しました。',
            fail: '送信に失敗しました'
        }
    },
    userRegist: {
        name: '氏名を入力してください。',
        nameKana: '氏名ガナを入力してください。',
        email: 'メールアドレス（半角英数字・記号）を入力してください。',
        emailNotmatch: 'Email形式が間違っています。',
        pw: 'パスワードを入力してください。',
        pw_confirm: 'パスワードを再入力してください。',
        passwdNotmatch: '入力したパスワードと確認用パスワードが異なります。',
        gender: '性別を選択してください',
        birthday: '生年月日を入力してください。',
        phone: '電話番号、半角で入力してください',
        phoneNotmatch: '電話番号の形式が間違っています。',
        last_education_name: '卒業した学校名を入力してください。',
        nationality: '国籍を入力してください。',
        now_address: '住所を入力してください。',
        near_station: '最寄駅を入力してください。',
        need_work_location: '勤務希望地を選択してください。',
        now_work_kind: '今の雇用形態を選択してください。',
        work_history: 'IT経歴年数を選択してください。',
        use_language: '開発言語を選択してください。',
        educationFail: '学校情報を入力してください。'
    },
    info: {
        regist: {
            nameSei: '漢字苗字',
            nameMei: '漢字名',
            nameKanaSei: 'カナ苗字',
            nameKanaMei: 'カナ名',
            email: 'メールアドレス',
            pw: 'パスワード（半角英数字6~20文字）',
            pw_confirm: '確認用パスワード',
            birthday: '生年月日',
            phone: '電話番号',
            nationality: '国籍',
            address: '現在住所',
            need_work_location: '希望勤務地',
            now_work_kind: '今の職業',
            work_history: '職業経歴',
            last_education: '大学',
            last_education_grade: '学年',
            last_education_status: '在学状態',
            last_education_name: '学校名',
            title: 'タイトル',
            publishStartDate: '開始日',
            publishEndDate: '終了日',
            emp_status: '雇用形態',
            recruitment_type: '募集職種',
            recommended: 'おすすめ',
            pay: '契約金額',
            location: '都/道/府/県',
            city: '区/市/町/村',
            nearest_station: '最寄駅',
            assing: 'アサイン',
            db: 'データベース',
            fw: 'フレームワーク',
            manager_message: '案件担当からのコメント欄です。案件に対するコメントを記入してください。',
            keyword: '検索したいキーワードを入力してください 例:JAVA案件',
            assign: '営業担当者選んでください。'
        }
    },
    agendaJoin: {
        afterJoin: '既に応募した案件です。',
        beforJoinn: '今すぐ応募',
        joined: '応募が完了されました。'
    },
    dataNotFound: '[A]データが存在しません。',
    checkData: '[A]が必要なサービスです。\n[B]へ遷移します。',
    confirm: '[A]しますか？',
    agendaDetlBoforeLogin: '案件に応募するためにはログインが必要です。',
    needLogin: 'ログインが必要なサービスです',
}

export default data;