import React from 'react';
import { inject, observer } from "mobx-react";
import WorkHistory from '../../constants/WorkHistory'
import Language from '../../constants/Language'
import MemberKind from '../../constants/MemberKind'
import IMGwhiteclose from '../../asset/img/white_close.svg';
import IMGwhitesearch from '../../asset/img/white_search.svg';
import CustomUtil from '../../model/common/CustomUtil';
import { runInAction } from "mobx";

import {
    InputLabel,
    Grid,
    Button,
    FormControl,
    Select,
    MenuItem,
    Box,
    TextField
} from '@mui/material';

import { withStyles } from '@mui/styles';

// override style
const style = {}

@inject('stores')
@observer
class UserListSesrchBox extends React.Component {

    search = this.props.stores.search;

    componentDidMount = () => {
        CustomUtil.delSessionItem('search');
    }

    renderSelectBox = (item) => {
        var searchData = this.search.getData(item.refKey) || '';
        
        return (
            <Select 
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchData} 
                onChange={this.handleSearchbox} 
                name={item.refKey} 
                autoWidth
            >
                <MenuItem value=""><em>None</em></MenuItem>
                {
                    item.data.map((item2, index2) => (
                        <MenuItem key={item2.value} value={item2.value}><em>{item2.key}</em></MenuItem>
                    ))
                }
            </Select>
        )
    }

    renderInputBox = (item) => {
        var searchData = this.search.getData(item.refKey) || '';
        
        return (
            <TextField 
                key={item.value}
                id="outlined-basic" 
                label={item.name} 
                variant="outlined"
                value={searchData} 
                name={item.refKey}
                onChange={this.handleSearchbox}
            />
        )
    }

    handleSearchbox = (e) => {
        const { data } = this.search;
        runInAction(() => {
            data[e.target.name] = e.target.value;
        })

        CustomUtil.setSessionItem('search', CustomUtil.encrypt(JSON.stringify(data)));
    }

    resetSearchBox = () => {
        const { handleSearch } = this.props;
        CustomUtil.delSessionItem('search');
        this.search.resetData(this.props.formData);
        handleSearch()
    }

    render() {
        const { handleSearch } = this.props;
        const selectList = [
            {
                name: "実務経験",
                data: WorkHistory,
                refKey: "work_history",
            },
            {
                name: "得意言語",
                data: Language,
                refKey: "language",
            },
            {
                name: "会員タイプ",
                data: MemberKind,
                refKey: "isAdmin",
            },
        ];
        const inputList = [
            {
                name: "氏名",
                refKey: "name",
              },
              {
                name: "国籍",
                refKey: "nationality",
              },
              {
                name: "E-mail",
                refKey: "email",
              },
              {
                name: "電話番号",
                refKey: "phone",
              },
        ];

        return (
            <Grid item xs={12} className="search_wrap mb_30">
                {
                    inputList.slice(0, 2).map((item, index) => (
                        <Box 
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                            key={index}
                        >
                            {this.renderInputBox(item)}
                        </Box>
                    ))
                }
                {
                    selectList.map((item, index) => (
                    <Box key={index + 2}>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel id="demo-simple-select-label" >
                                {item.name}
                            </InputLabel>
                                {this.renderSelectBox(item)}
                        </FormControl>
                    </Box>
                    ))
                }
                {
                    inputList.slice(2, 5).map((item, index) => (
                        <Box 
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                            key={index + 5}
                        >
                            {this.renderInputBox(item)}
                        </Box>
                    ))
                }

                <Grid className="search_btn">
                    <Button className="list_btn" onClick={handleSearch}><img src={IMGwhitesearch} alt="" />{'条件検索'}</Button>
                    <Button className="list_btn" onClick={this.resetSearchBox}><img src={IMGwhiteclose} alt="" />{'条件リセット'}</Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(UserListSesrchBox);