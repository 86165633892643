import React from 'react';

import CommFooter from '../../component/common/CommFooter';
import CommNav from '../../component/common/CommNav';
import CustomUtil from '../../model/common/CustomUtil';

import language from '../../constants/Language';
import recruitmentType from '../../constants/RecruitmentType';
import needWorkLocation from '../../constants/NeedWorkLocation';
import { Link } from 'react-router-dom';
import {
    Grid,
    Typography,
    Container,
    Button,
    MenuList, 
    MenuItem, 
} from '@mui/material';
import { withStyles } from '@mui/styles';

// override style
const style = {
    root: {
        '@media(max-width: 900px)': {
            linkArea: {
                minWidth: '100%',
                display: 'block',
            }
        }
    }

}
// TODO :: 검색이랑 조건 맞추기
class Sitemap extends React.Component {
    renderLinkList = (data, size, category) => {
        
        return (
            <Grid container item md={9} className="main_lang_desc">
                {
                    data.map((item, index) => (
                        <Grid item md={size} key={index} className={'linkArea'}>
                            <Button onClick={() => { this.handleSerch(item, category) }}>{item.key}</Button>
                        </Grid>
                    ))
                }
            </Grid>
        );
    }

    handleSerch = (item, category) => {
        
    }

    render() {
        const { classes } = this.props;
        CustomUtil.scrollTarget();
        return (
            <>
                <Grid style={{ position: 'relative' }} className={classes.root}>
                    <CommNav />
                    <Grid className="content">
                        <Container>
                            <Grid item xs={12}>
                                <center>
                                    <Typography className="content_title">{'サイトマップ'}</Typography>
                                </center>
                            </Grid>

                            <Grid item xs={12} className="column-shadow2">
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                    <Grid item md={12} className="sitemap_title"><Typography variant="span">{'サイトマップ'}</Typography></Grid>
                                    <Grid item md={12}>
                                        <MenuList>
                                            <MenuItem>
                                                <Link to="/search">
                                                    {'案件を探す'}
                                                </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/siteinfo">
                                                    {'サービス紹介'}
                                                </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <a href="https://www.minosys.co.jp/companyInfo#ci2" target="_blank" rel="noopener noreferrer">{'運営会社'}</a>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to='/agreement'>
                                                    {'利用規約'}
                                                </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to='/policy'>
                                                    {'個人情報の取扱い'}
                                                </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/faq">
                                                    {'ヘルプ'}
                                                </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/Inquiry">
                                                    {'お問い合わせ'}
                                                </Link>
                                            </MenuItem>
                                        </MenuList>
                                    </Grid>
                                    <Grid item xs={12} className="mt_30">&nbsp;</Grid>
                                    
                                    
                                    <Grid item md={12} className="sitemap_title"><Typography variant="span">{'エリア'}</Typography></Grid>
                                    {this.renderLinkList(needWorkLocation, 4, 'area')}
                                    <Grid item xs={12} className="mt_30">&nbsp;</Grid>

                                    <Grid item md={12} className="sitemap_title"><Typography variant="span">{'職種'}</Typography></Grid>
                                    {this.renderLinkList(recruitmentType, 4, 'recruitmentType')}
                                    <Grid item xs={12} className="mt_30">&nbsp;</Grid>
                                    <Grid item md={12} className="sitemap_title"><Typography variant="span">{'言語'}</Typography></Grid>
                                    {this.renderLinkList(language, 4, 'language')}
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                    <CommFooter />
                </Grid>

            </>
        );
    }
}

export default withStyles(style)(Sitemap);