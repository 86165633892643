// 募集職種
const data = [
    {
        key: 'フロントエンド',
        value: 'RE000'
    },
    {
        key: 'バックエンド',
        value: 'RE010'
    },
    {
        key: 'フルスタック',
        value: 'RE020'
    },
    {
        key: 'ネイティブアプリ',
        value: 'RE030'
    },
    {
        key: 'システム保守 ',
        value: 'RE035'
    },
    {
        key: '汎用機',
        value: 'RE040'
    },
    {
        key: '組込・制御',
        value: 'RE050'
    },
    {
        key: 'コンサル',
        value: 'RE060'
    },
    {
        key: 'PL',
        value: 'RE070'
    },
    {
        key: 'PM',
        value: 'RE080'
    },
    {
        key: 'PMO',
        value: 'RE090'
    },
    {
        key: 'テスター',
        value: 'RE100'
    },
    {
        key: 'セキュリティ',
        value: 'RE110'
    },
    {
        key: 'インフラ',
        value: 'RE120'
    },
    {
        key: 'ネットワーク',
        value: 'RE130'
    },
    {
        key: 'ゲーム開発',
        value: 'RE140'
    },
    {
        key: 'DBA',
        value: 'RE150'
    },
    {
        key: '運用・監視',
        value: 'RE160'
    },
    {
        key: 'ヘルプデスク',
        value: 'RE170'
    },
    {
        key: 'その他',
        value: 'RE999'
    },
];

export default data;